import React, { useState } from 'react';
import { Rating as RatingComponent, Box } from '@mui/material';
import TertiaryButton from '../Button/TertiaryButton';

const Rating = ({
  rateNumber,
  reviewNumber = 0,
  isReviewVisible = true,
  readOnly = true,
  ...props
}) => {
  const [rate, setRate] = useState(rateNumber);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}>
      <RatingComponent
        name="simple-controlled"
        value={rate}
        onChange={(event, newValue) => {
          setRate(newValue);
        }}
        readOnly={readOnly}
        {...props}
      />
      {isReviewVisible && reviewNumber !== 0 && <TertiaryButton>({reviewNumber})</TertiaryButton>}
    </Box>
  );
};

export default Rating;
