import * as yup from 'yup';
import { Trans } from 'react-i18next';

export const preReservationSchema = yup.object().shape({
  firstName: yup.string().required(<Trans i18nKey="validation.firstName.required" />),

  lastName: yup.string().required(<Trans i18nKey="validation.lastName.required" />),

  email: yup
    .string()
    .email(<Trans i18nKey="validation.email.invalid" />)
    .required(<Trans i18nKey="validation.mail.required" />),

  phoneNumber: yup.string().required(<Trans i18nKey="validation.phoneNumber.required" />),

  checkInDate: yup
    .date()
    .required(<Trans i18nKey="validation.checkInDate.required" />)
    .test('future-date', <Trans i18nKey="validation.futureDate.checkInDate" />, (value) => {
      return new Date(value) > new Date();
    }),

  checkOutDate: yup
    .date()
    .required(<Trans i18nKey="validation.checkOutDate.required" />)
    .test('future-date', <Trans i18nKey="validation.futureDate.checkOutDate" />, (value) => {
      return new Date(value) > new Date();
    })
    .min(yup.ref('checkInDate'), <Trans i18nKey="validation.endDate.invalid" />),

  guest: yup.number().min(1, <Trans i18nKey="validation.minValue.guest" values={{ min: 1 }} />)
});
