import React from 'react';
import { Typography, Stack } from '@mui/material';
import Rating from '../Rating/Rating';

const Header = ({ children, isRatingShow = false, ...ratingProps }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 'bold',
          fontSize: '32px'
        }}
        gutterBottom>
        {children}
      </Typography>
      {isRatingShow && <Rating {...ratingProps} />}
    </Stack>
  );
};
export default Header;
