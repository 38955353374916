import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import Hero from '../../Components/Hero/Hero';
import Categories from './Categories';
import Properties from './Properties';
import ErrorPopup from '../../Modules/ErrorPopup';
import { useFormik } from 'formik';
import { searchSchema } from '../../Utils/Schema/SearchValidation';
import useRequest from '../../Hooks/useRequest';
import ENDPOINTS from '../../Constants/Endpoints';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { KEYWORD } from '../../Constants/Property';

function Homepage() {
  const { useGetData } = useRequest();
  const navigate = useNavigate();
  const [selectedPlace, setSelectedPlace] = useState('');
  const [selected, setSelected] = useState(KEYWORD.SEA);

  const { data: properties, refetch } = useGetData(
    'property',
    `${ENDPOINTS.properties}?keyword=${selected}`,
    {
      enabled: Boolean(selected)
    }
  );

  useEffect(() => {
    refetch();
  }, [selected]);

  const formik = useFormik({
    initialValues: {
      location: null,
      checkInDate: null,
      checkOutDate: null,
      guest: 1
    },
    validationSchema: searchSchema,
    onSubmit: async (values) => {
      const data = {
        lat: values.location.lat,
        lan: values.location.lng,
        checkInDate: dayjs(values.checkInDate).format('YYYY-MM-DD'),
        checkOutDate: dayjs(values.checkOutDate).format('YYYY-MM-DD'),
        guest: String(values.guest),
        address: selectedPlace?.formatted_address
      };
      navigate(`/properties?${new URLSearchParams(data)}`);
    }
  });

  return (
    <div>
      <Hero formik={formik} setSelectedPlace={setSelectedPlace} />
      <Container>
        <Box sx={{ flexGrow: 1, m: 2 }}>
          <Categories />
          {/*properties data yap */}
          <Properties properties={properties} setSelected={setSelected} selected={selected} />
        </Box>
      </Container>
      <ErrorPopup formik={formik} />
    </div>
  );
}

export default Homepage;
