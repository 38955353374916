import React from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AvailabilityCalendar from '../../Components/AvailabilityCalendar/AvailabilityCalendar';
import MainButton from '../../Components/Button/MainButton';
import ErrorPopup from '../../Modules/ErrorPopup';
import { AddCircleOutlineRounded, CancelOutlined, DeleteOutlineRounded } from '@mui/icons-material';

const Calendar = ({ formik, reserved, setReserved }) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    formik.setValues({
      startDate: e[0],
      endDate: e[1]
    });
  };

  const isNotSameDay = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return (
      d1.getFullYear() !== d2.getFullYear() ||
      d1.getMonth() !== d2.getMonth() ||
      d1.getDate() !== d2.getDate()
    );
  };

  const removeSelectedDate = () => {
    const updatedReserved = reserved.filter((reservation) => {
      return (
        isNotSameDay(reservation.startDate, formik.values.startDate) ||
        isNotSameDay(reservation.endDate, formik.values.endDate)
      );
    });

    setReserved(updatedReserved);
    formik.resetForm();
  };

  const resetSelectedDates = () => {
    formik.resetForm();
  };

  return (
    <Box gap={3} mb={2}>
      <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} mb={2}>
        <Typography
          sx={{
            color: 'header.main',
            fontWeight: 'bold',
            fontSize: '32px'
          }}
          gutterBottom>
          {t('stepper.step.calendar')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: 'inputPlaceholder.main',
            fontSize: '14px'
          }}>
          {t('availabilityCalendar.description')}
        </Typography>
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row'
        }}
        justifyContent="center"
        alignItems="center"
        spacing={2}>
        <AvailabilityCalendar
          selectedDates={[formik.values.startDate, formik.values.endDate]}
          handleChange={handleChange}
          reserved={reserved}
          variant="events"
        />
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Tooltip title={t('availabilityCalendar.button.add.description')} arrow>
            <span>
              <MainButton
                onClick={formik.handleSubmit}
                color="success"
                startIcon={<AddCircleOutlineRounded />}>
                {t('availabilityCalendar.button.add')}
              </MainButton>
            </span>
          </Tooltip>
          <Tooltip title={t('availabilityCalendar.button.delete.description')} arrow>
            <span>
              <MainButton
                color="error"
                onClick={removeSelectedDate}
                startIcon={<DeleteOutlineRounded />}>
                {t('availabilityCalendar.button.delete')}
              </MainButton>
            </span>
          </Tooltip>
          <Tooltip title={t('availabilityCalendar.button.reset.description')} arrow>
            <span>
              <MainButton color="info" onClick={resetSelectedDates} startIcon={<CancelOutlined />}>
                {t('availabilityCalendar.button.reset')}
              </MainButton>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
      <ErrorPopup formik={formik} />
    </Box>
  );
};

export default Calendar;
