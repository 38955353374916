import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Alert,
  InputAdornment,
  IconButton
} from '@mui/material';
import Header from '../Header/Header';
import Input from '../Input/Input';
import MainButton from '../Button/MainButton';
import TertiaryButton from '../Button/TertiaryButton';
import loginSchema from '../../Utils/Schema/LoginValidation';
import { useFormik } from 'formik';
import { login } from '../../Utils/Auth';
import { useAuth } from '../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import ErrorPopup from '../../Modules/ErrorPopup';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  flexGrow: 1
}));

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const { loginAccount, setIsLoginOpen, setIsModalOpen } = useAuth();
  const { t } = useTranslation();

  const signUpOpen = () => {
    setIsLoginOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: async (values, bag) => {
      try {
        const loginResponse = await login({
          email: values.email,
          password: values.password
        });
        loginAccount(loginResponse);
        closeModal();
      } catch (error) {
        bag.setErrors({ general: error.response.data.message });
      }
    }
  });

  const closeModal = () => {
    setIsModalOpen(false);
    setIsLoginOpen(false); // return to signup page
  };

  return (
    <>
      <Dialog open={true} onClose={closeModal}>
        <DialogTitle>
          <Header>{t('page.login')}</Header>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {formik.errors.general && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.errors.general}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Input
                label={t('login.input.mail')}
                onChange={formik.handleChange('email')}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label={t('login.input.password')}
                onChange={formik.handleChange('password')}
                value={formik.values.password}
                error={formik.touched.password && Boolean(formik.errors.password)}
                inputProps={{ maxLength: 25 }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        onMouseUp={(event) => {
                          event.preventDefault();
                        }}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={<Checkbox />} label={t('login.input.rememberMe')} />
            </Grid>
            <Grid item xs={12}>
              <MainButton type="submit" onClick={formik.handleSubmit}>
                {t('page.login')}
              </MainButton>
            </Grid>
          </Grid>
        </DialogContent>
        <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
          <Item>
            <TertiaryButton>{t('login.button.forgotPassword')}</TertiaryButton>
          </Item>
          <Item>
            {t('login.button.haveAnAccount')}
            <TertiaryButton color="primary" onClick={signUpOpen}>
              {t('signup.button.title')}
            </TertiaryButton>
          </Item>
        </Stack>
      </Dialog>
      <ErrorPopup formik={formik} />
    </>
  );
};

export default Login;
