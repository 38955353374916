import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers';

const BasicDatePicker = ({
  label,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  name,
  required,
  size,
  defaultValue,
  disableFuture = false,
  disablePast = false,
  ...props
}) => {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <DatePicker
      label={label}
      value={value}
      defaultValue={defaultValue}
      onChange={handleDateChange}
      disableFuture={disableFuture}
      disablePast={disablePast}
      inputFormat="DD-MM-YYYY"
      slotProps={{
        textField: {
          variant: 'filled',
          fullWidth: true,
          onBlur,
          error,
          helperText,
          name,
          required,
          size
        }
      }}
      {...props}
    />
  );
};

export default BasicDatePicker;
