import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import Stepper from '../../Components/Stepper/Stepper';
import ErrorPopup from '../../Modules/ErrorPopup';
import { calendarSchema, propertySchema } from '../../Utils/Schema/PropertyValidation';
import useRequest from '../../Hooks/useRequest';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Basics from './Basics';
import Description from './Description';
import Pricing from './Pricing';
import Photos from './Photos';
import Video from './Video';
import Other from './Other';
import Calendar from './Calendar';
import ENDPOINTS from '../../Constants/Endpoints';
import dayjs from 'dayjs';

const CreateProperty = () => {
  const { createData, updateData, useGetData } = useRequest();
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [reserved, setReserved] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [latLng, setLatLng] = useState(null);

  useEffect(() => {
    if (photos) {
      let data = photos.map((photo) => {
        return {
          src: photo.src,
          width: photo.width,
          height: photo.height
        };
      });

      formik.setFieldValue('photos', data);
    }
  }, [photos]);

  const { data } = useGetData('property', `${ENDPOINTS.properties}/${id}`, {
    enabled: Boolean(id)
  });

  const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

  useEffect(() => {
    if (id && data && data?.property) {
      formik.setValues({
        ...data?.property,
        amenties: {
          ...data?.property?.amenties,
          checkInTime: dayjs(data?.property?.amenties?.checkInTime).toDate(),
          checkOutTime: dayjs(data?.property?.amenties?.checkOutTime).toDate()
        }
      });

      setLatLng({
        lat: data?.property?.address?.location?.coordinates[1],
        lng: data?.property?.address?.location?.coordinates[0]
      });

      setReserved(
        data?.property?.reserved.map((reservation) => {
          return {
            startDate: dayjs(reservation.startDate).toDate(),
            endDate: dayjs(reservation.endDate).toDate()
          };
        })
      );

      const images = data?.property?.photos?.map((photo, i) => {
        const width = breakpoints[0];
        const height = (photo.height / photo.width) * width;

        return {
          src: photo?.src,
          id: i + 1,
          width,
          height
          // srcSet: breakpoints.map((breakpoint) => {
          //   const height = Math.round((photo.height / photo.width) * breakpoint);
          //   return {
          //     src: `${photo.src}/${width}x${height}`,
          //     width: breakpoint,
          //     height
          //   };
          // })
        };
      });

      setPhotos(images);

      if (data?.property?.reserved) {
        setReserved(
          data?.property?.reserved.map((reservation) => {
            return {
              startDate: dayjs(reservation.startDate).toDate(),
              endDate: dayjs(reservation.endDate).toDate()
            };
          })
        );
      }
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      title: '',
      category: '',
      keyword: '',
      address: {
        street: '',
        houseNumber: '',
        postalCode: '',
        district: '',
        city: '',
        country: '',
        location: null
      },
      basicFeatures: {
        maxTraveler: '',
        bedroom: '',
        bed: '',
        bathroom: '',
        isBathShared: false
      },
      description: {
        shortDescription: '',
        description: '',
        neighborhood: {
          overview: '',
          gettingAround: ''
        }
      },
      pricing: {
        perDayPrice: '',
        currency: '',
        additionalOptions: {
          cleaningFee: 0,
          additionalGuest: 0,
          securityDeposit: 0,
          weekendPricing: 0
        }
      },
      photos: [],
      //video: '',
      amenties: {
        livingArea: {
          sofaSet: false,
          armChair: false,
          sofaBed: false,
          doubleSofaBed: false,
          diningTable: false,
          tv: false,
          satelliteTV: false,
          coffeeTable: false,
          airConditioning: false
        },
        kitchen: {
          crockery: false,
          cutlery: false,
          glassware: false,
          pots: false,
          pans: false,
          diningTable: false,
          oven: false,
          microwave: false,
          fridge: false,
          freezer: false,
          dishWasher: false,
          toaster: false,
          kettle: false,
          kitchenware: false,
          diningArea: false
        },
        generalFacilities: {
          jacuzzi: false,
          cot: false,
          highChair: false,
          safe: false,
          tumbleDryer: false,
          washingMachine: false,
          towel: false,
          internet: false,
          iron: false,
          WIFI: false,
          hairDyrer: false,
          wardrobe: false,
          makeupTable: false,
          airConditioning: false,
          elevator: false,
          pool: false,
          sauna: false,
          tableTennis: false,
          fireplace: false,
          housekeeping: false,
          heating: false
        },
        outdoorFacilities: {
          communalSwimmingPool: false,
          privateSwimmingPool: false,
          gardenFurniture: false,
          communalGarden: false,
          privateGarden: false,
          parking: false,
          outsideLighting: false,
          barbecue: false,
          balconyORterrace: false,
          childrenPool: false,
          security: false,
          seaView: false,
          sunsetView: false,
          poolView: false,
          cityView: false,
          mountainView: false,
          seafront: false,
          complex: false,
          parasols: false,
          sunbeds: false,
          publicBeach: false,
          privateBeach: false,
          paidParking: false,
          outdoorDiningTable: false
        },
        siteAndAttractions: {
          supermarkets: false,
          historicSites: false,
          museum: false,
          bar: false,
          restaurant: false,
          cafeteria: false,
          nightClub: false,
          shoppingCentre: false,
          localFoodMarket: false,
          fishing: false,
          boatTrip: false,
          cycling: false,
          horseRiding: false,
          golf: false,
          fitnessCenterGYM: false,
          SPA: false,
          turkishBath: false,
          tennisCourt: false,
          basketballCourt: false,
          volleyballCourt: false
        },
        suitabilities: {
          petsAllowed: '',
          smoking: '',
          restrictedMobility: '',
          children: '',
          maleGroups: ''
        },
        carRental: '',
        checkInTime: null,
        checkOutTime: null
      },
      transportation: [],
      entertainment: [],
      foodDrink: [],
      places: [],
      reserved: []
    },
    validationSchema: propertySchema,
    onSubmit: async (values) => {
      if (pathname.includes('create-property')) {
        await createData.mutateAsync(
          {
            endpoint: ENDPOINTS.properties,
            body: {
              ...values,
              address: {
                ...values.address,
                location: {
                  type: 'Point',
                  coordinates: [latLng.lng, latLng.lat]
                }
              },
              basicFeatures: {
                ...values.basicFeatures,
                maxTraveler: parseInt(values.basicFeatures.maxTraveler),
                bedroom: parseInt(values.basicFeatures.bedroom),
                bathroom: parseInt(values.basicFeatures.bathroom)
              },
              pricing: {
                ...values.pricing,
                perDayPrice: parseInt(values.pricing.perDayPrice)
              }
            }
          },
          {
            onSuccess: async (data) => {
              if (data) {
                navigate('/profile', { state: { profile: 1 } });
              }
            }
          }
        );
      } else {
        await updateData.mutateAsync(
          {
            endpoint: `${ENDPOINTS.properties}/${id}`,
            body: values
          },
          {
            onSuccess: async (data) => {
              if (data) {
                navigate('/profile', { state: { profile: 1 } });
              }
            }
          }
        );
      }
    }
  });

  const calendarFormik = useFormik({
    initialValues: {
      startDate: null,
      endDate: null
    },
    validationSchema: calendarSchema,
    onSubmit: async (values) => {
      setReserved([
        ...reserved,
        {
          startDate: dayjs(values.startDate).toDate(),
          endDate: dayjs(values.endDate).toDate()
        }
      ]);

      formik.setFieldValue('reserved', [
        ...reserved,
        {
          startDate: dayjs(values.startDate).toDate(),
          endDate: dayjs(values.endDate).toDate()
        }
      ]);

      calendarFormik.resetForm();
    }
  });

  const steps = [
    {
      label: 'basics',
      body: <Basics formik={formik} latLng={latLng} setLatLng={setLatLng} />,
      optional: false
    },
    {
      label: 'description',
      body: <Description formik={formik} />,
      optional: false
    },
    {
      label: 'pricing',
      body: <Pricing formik={formik} />,
      optional: false
    },
    {
      label: 'photos',
      body: <Photos formik={formik} photos={photos} setPhotos={setPhotos} />,
      optional: false
    },
    {
      label: 'video',
      body: <Video formik={formik} />,
      optional: true
    },
    {
      label: 'other',
      body: <Other formik={formik} />,
      optional: false
    },
    {
      label: 'calendar',
      body: <Calendar formik={calendarFormik} reserved={reserved} setReserved={setReserved} />,
      optional: false
    }
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack gap={3} mb={3}>
          <Stepper steps={steps} formik={formik} />
        </Stack>
      </form>
      <ErrorPopup formik={formik} />
    </>
  );
};

export default CreateProperty;
