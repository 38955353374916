import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Input from '../../Components/Input/Input';

const Video = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <Box gap={3} mb={2}>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 'bold',
          fontSize: '32px'
        }}
        gutterBottom>
        {t('stepper.step.video')}
      </Typography>
      <Input
        label={t('createProperty.input.video')}
        onChange={formik.handleChange('video')}
        value={formik.values.video}
        onBlur={formik.handleBlur('video')}
        error={formik.touched.video && Boolean(formik.errors.video)}
      />
    </Box>
  );
};

export default Video;
