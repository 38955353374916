import * as React from 'react';
import { Box, Typography, Divider, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BasicDatePicker from '../Datepicker/Datepicker';
import MainButton from '../Button/MainButton';
import TertiaryButton from '../Button/TertiaryButton';
import Avatar from '../Avatar/Avatar';
import { currencies } from '../../Constants/SelectOptions';
import DecimalInput from '../DecimalInput/DecimalInput';

const BookingCard = ({ property, host, formik }) => {
  const { t } = useTranslation();

  return (
    <Box
      elevation={2}
      sx={{
        position: 'sticky',
        top: '20px',
        borderRadius: '4px',
        boxShadow: 2
      }}>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          color: 'white',
          px: 2,
          py: '3px',
          borderRadius: '4px 4px 0 0'
        }}>
        <Typography
          sx={{
            fontWeight: 600,
            textAlign: 'start',
            fontSize: '16px',
            textAlignLast: 'end'
          }}>
          {property?.pricing?.perDayPrice}{' '}
          {currencies.find((currency) => currency.value === property?.pricing?.currency)?.symbol} /{' '}
          {t('property.category.perDay')}
        </Typography>
      </Box>
      <Box p={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BasicDatePicker
              label={t('bookingCard.input.checkInDate')}
              name="checkInDate"
              value={formik.values.checkInDate}
              onChange={(date) => formik.setFieldValue('checkInDate', date)}
              onBlur={formik.handleBlur}
              error={formik.touched.checkInDate && Boolean(formik.errors.checkInDate)}
              disablePast
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <BasicDatePicker
              label={t('bookingCard.input.checkOutDate')}
              name="checkOutDate"
              value={formik.values.checkOutDate}
              onChange={(date) => formik.setFieldValue('checkOutDate', date)}
              onBlur={formik.handleBlur}
              error={formik.touched.checkOutDate && Boolean(formik.errors.checkOutDate)}
              disablePast
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <DecimalInput
              label={t('bookingCard.input.guest')}
              onChange={formik.handleChange('guest')}
              value={formik.values.guest}
              onBlur={formik.handleBlur}
              error={formik.touched.guest && Boolean(formik.errors.guest)}
              inputProps={{ min: 1, placeholder: t('bookingCard.input.guest.placeholder') }}
              size="small"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <MainButton type="submit" onClick={formik.handleSubmit}>
              {t('bookingCard.button.book.title')}
            </MainButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box p={2} mb={2}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          justifyContent="center"
          alignItems="center">
          <Stack spacing={{ xs: 1, sm: 1 }} direction="row" useFlexGap flexWrap="wrap">
            <Typography>{t('bookingCard.hostedBy')}</Typography>
            <Typography color="primary">{`${host?.firstName} ${host?.lastName}`}</Typography>
            <Typography
              sx={{
                p: 0
              }}>
              <TertiaryButton color="text">
                {t('bookingCard.button.sendMessageToHost.title')}
              </TertiaryButton>
            </Typography>
          </Stack>
          <Avatar>{`${host?.firstName} ${host?.lastName}`}</Avatar>
        </Stack>
      </Box>
    </Box>
  );
};

export default BookingCard;
