import React from 'react';
import { Grid } from '@mui/material';
import MainButton from '../Button/MainButton';
import BasicDatePicker from '../Datepicker/Datepicker';
import PlacesAutocomplete from '../PlacesAutocomplete/PlacesAutocomplete';
import { useTranslation } from 'react-i18next';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import DecimalInput from '../DecimalInput/DecimalInput';

const SearchBar = ({ formik, selectedPlace, setSelectedPlace }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 2, sm: 1, md: 12 }}
      alignItems="center">
      <Grid item xs={4}>
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
          <Map
            defaultZoom={13}
            gestureHandling="greedy"
            disableDefaultUI={true}
            fullScreenControl={false}
          />
          <PlacesAutocomplete
            name="location"
            label={t('searchbar.input.location')}
            value={formik.values.location}
            onBlur={formik.handleBlur}
            error={formik.touched.location && Boolean(formik.errors.location)}
            required={true}
            formik={formik}
            onPlaceSelect={setSelectedPlace}
            selectedPlace={selectedPlace}
            sx={{
              '& .MuiFilledInput-root': {
                '&:hover': {
                  backgroundColor: 'inputBG.main'
                },
                '&.Mui-focused': {
                  backgroundColor: 'inputBG.main'
                }
              }
            }}
          />
        </APIProvider>
      </Grid>
      <Grid item xs={2}>
        <BasicDatePicker
          required
          label={t('searchbar.input.checkInDate')}
          name="checkInDate"
          value={formik.values.checkInDate}
          onChange={(date) => formik.setFieldValue('checkInDate', date)}
          onBlur={formik.handleBlur}
          error={formik.touched.checkInDate && Boolean(formik.errors.checkInDate)}
          disablePast
          sx={{
            '& .MuiFilledInput-root': {
              '&:hover': {
                backgroundColor: 'inputBG.main'
              },
              '&.Mui-focused': {
                backgroundColor: 'inputBG.main'
              }
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <BasicDatePicker
          required
          label={t('searchbar.input.checkOutDate')}
          name="checkOutDate"
          value={formik.values.checkOutDate}
          onChange={(date) => formik.setFieldValue('checkOutDate', date)}
          onBlur={formik.handleBlur}
          error={formik.touched.checkOutDate && Boolean(formik.errors.checkOutDate)}
          disablePast
          sx={{
            '& .MuiFilledInput-root': {
              '&:hover': {
                backgroundColor: 'inputBG.main'
              },
              '&.Mui-focused': {
                backgroundColor: 'inputBG.main'
              }
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <DecimalInput
          label={t('searchbar.input.guest')}
          onChange={formik.handleChange('guest')}
          value={formik.values.guest}
          onBlur={formik.handleBlur}
          error={formik.touched.guest && Boolean(formik.errors.guest)}
          sx={{
            '& .MuiFilledInput-root': {
              '&:hover': {
                backgroundColor: 'inputBG.main'
              },
              '&.Mui-focused': {
                backgroundColor: 'inputBG.main'
              }
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <MainButton
          onClick={formik.handleSubmit}
          type="submit"
          color="ocean"
          sx={{
            padding: '14px'
          }}>
          {t('searchbar.button.title')}
        </MainButton>
      </Grid>
    </Grid>
  );
};

export default SearchBar;
