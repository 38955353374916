import * as yup from 'yup';
import { Trans } from 'react-i18next';

export const updateAccountSchema = yup.object().shape({
  firstName: yup.string().required(<Trans i18nKey="validation.firstName.required" />),

  lastName: yup.string().required(<Trans i18nKey="validation.lastName.required" />),

  email: yup
    .string()
    .email(<Trans i18nKey="validation.email.invalid" />)
    .required(<Trans i18nKey="validation.mail.required" />),

  phoneNumber: yup.string().required(<Trans i18nKey="validation.phoneNumber.required" />)
});
