import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Homepage, PreReservation, Properties, PropertyDetail } from './Pages';
import Layout from './Layout/Layout';
import { routes } from './Routes/Routes';
import Control from './Routes/Control';
import Loading from './Components/Loading/Loading';

import './App.css';
import { useAuth } from './Contexts/AuthContext';
import useRequest from './Hooks/useRequest';

const App = () => {
  const { loading } = useAuth();
  const { isLoading } = useRequest();

  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/properties/:id" element={<PropertyDetail />} />
          <Route path="/pre-reservation" element={<PreReservation />} />
          {routes.map((route, index) => (
            <Route
              key={index}
              index
              path={route.path}
              element={<Control permissions={route.permissions}>{route.element}</Control>}
            />
          ))}
          {/* <Route path="*" element={<NotFound />} /> */} {/* not found page */}
        </Routes>
      </Layout>
      <Loading loading={loading || isLoading} />

      {/* apiden donen hatalar pop upda gosterilebilir */}
      {/* <PopUpOkey
        open={Boolean(gContext.serviceError)}
        text={Boolean(gContext.serviceError) && t({ id: `service.error.${gContext.serviceError}` })}
        title={t({ id: 'common.warning' })}
        buttonText={t({ id: 'common.okey' })}
        handleOkeyClick={() => gContext.setServiceError(false)}
      /> */}
    </>
  );
};

export default App;
