import React from 'react';
import { MenuItem, TextField } from '@mui/material';

const Select = ({ label, options, value, onChange, ...props }) => {
  return (
    <TextField
      variant="filled"
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      select
      defaultValue=""
      {...props}>
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
export default Select;
