export const CATEGORY = {
  APART: 'Apart',
  VILLA: 'Villa',
  FLAT: 'Flat',
  HOTEL: 'Hotel'
};

export const BED_TYPES = {
  No: '0 bed',
  Double: 'Double bed',
  Single: 'Single bed'
};

export const PETS = {
  PETS_ALLOWED: 'Pets Allowed',
  NO_PETS_ALLOWED: 'No Pets Allowed'
};

export const SMOKING = {
  SMOKING: 'Smoking',
  NO_SMOKING: 'Not Smoking'
};

export const SUITABILITY = {
  SUITABLE: 'Suitable',
  NOT_SUITABLE: 'Not Suitable'
};

export const MALE_GROUPS = {
  ACCEPTED: 'Accepted',
  NOT_ACCEPTED: 'Not Accepted'
};

export const CAR_RENTAL = {
  NOT_NEEDED: 'Not Needed',
  OPTIONAL: 'Optional',
  ESSENTIAL: 'Essential'
};

export const CURRENCY = {
  TRY: 'TRY',
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP'
};

export const KEYWORD = {
  SEA: 'Sea',
  BEACH: 'Beach',
  POOL: 'Pool',
  VIEW: 'View',
  LAKE: 'Lake',
  ISLAND: 'Island',
  BREAKFAST: 'Breakfast',
  HISTORIC: 'Historic'
};

export const center = { lat: 39, lng: 35 }; // Turkey

export const categories = (t) => {
  return [
    {
      name: CATEGORY.APART,
      title: t('property.category.apart'),
      image:
        'https://images.unsplash.com/photo-1481479763098-d8fda86b4bf9?auto=format&fit=crop&q=80&w=2940&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      width: 670,
      height: 446
    },
    {
      name: CATEGORY.FLAT,
      title: t('property.category.flat'),
      image:
        'https://images.unsplash.com/photo-1459535653751-d571815e906b?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      width: 670,
      height: 447
    },
    {
      name: CATEGORY.HOTEL,
      title: t('property.category.hotel'),
      image:
        'https://images.unsplash.com/photo-1549294413-26f195200c16?auto=format&fit=crop&q=80&w=2400&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      width: 622,
      height: 778
    },
    {
      name: CATEGORY.VILLA,
      title: t('property.category.villa'),
      image:
        'https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?q=80&w=2950&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      width: 670,
      height: 444
    }
  ];
};
