import { PERMISSIONS } from '../Constants/Permissions';
import {
  Reservations,
  Booking,
  ReservationDetail,
  CreateProperty,
  Profile,
  PropertyDetail
} from '../Pages';

export const routes = [
  {
    path: '/reservations',
    element: <Reservations />,
    permissions: [PERMISSIONS.RESERVATIONS]
  },
  {
    path: '/booking',
    element: <Booking />,
    permissions: [PERMISSIONS.BOOKING]
  },
  {
    path: '/reservations/:id',
    element: <ReservationDetail />,
    permissions: [PERMISSIONS.RESERVATION_DETAIL]
  },
  {
    path: '/create-property',
    element: <CreateProperty />,
    permissions: [PERMISSIONS.CREATE_PROPERTY]
  },
  {
    path: '/update-property/:id',
    element: <CreateProperty />,
    permissions: [PERMISSIONS.UPDATE_PROPERTY]
  },
  {
    path: '/profile',
    element: <Profile />,
    permissions: [PERMISSIONS.PROFILE]
  },
  {
    path: '/host/properties/:id',
    element: <PropertyDetail />,
    permissions: [PERMISSIONS.PROPERTY_DETAIL_HOST]
  }
];
