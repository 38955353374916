import React from 'react';
import {
  Box,
  Grid,
  Container,
  Typography,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaymentCard from '../../Components/PaymentCard/PaymentCard';
import TertiaryButton from '../../Components/Button/TertiaryButton';
import ChatContainer from '../../Components/Chat/ChatContainer';

const Booking = () => {
  const { t } = useTranslation();

  const property = {
    title: 'Siesta Garden',
    imageUrl:
      'https://images.unsplash.com/photo-1477314099024-9f209cdc507a?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    location: 'Bodrum Yalıkavak',
    price: '50$',
    host: 'Beyza Erkan',
    checkIn: '14:00',
    checkOut: '12:00'
  };

  return (
    <Stack gap={3} mb={3} direction="row">
      <Container sx={{ minWidth: 'auto', mt: 4 }}>
        <Grid container spacing={{ sx: 1, md: 10 }}>
          <Grid item xs={12} md={7}>
            <Box mb={2}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 24,
                  mt: 6
                }}>
                {t('booking.title', { host: property.host })}
              </Typography>
            </Box>
            <Box>
              <List
                dense={true}
                sx={{ listStyle: 'disc', pl: 4 }}
                subheader={<Typography>{t('booking.helpfulTips')}</Typography>}>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText
                    primary={t('booking.helpfulTips.firstQuestion', {
                      location: property.location
                    })}
                  />
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText primary={t('booking.helpfulTips.secondQuestion')} />
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText primary={t('booking.helpfulTips.thirdQuestion')} />
                </ListItem>
              </List>
            </Box>
            <Box>
              <ChatContainer
                messageInfo={{
                  property: 'Siesta Garden',
                  reservation: {},
                  host: 'Beyza Erkan',
                  messages: [
                    { text: 'hey', time: Date(), type: 'incoming' },
                    { text: 'how are you?', time: Date(), type: 'incoming' },
                    { text: 'whats up', time: Date(), type: 'outgoing' }
                  ]
                }}
              />
            </Box>
            <Box mb={4}>
              <Divider />
              <Box m={2}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 20
                  }}>
                  {t('booking.houseRules.title')}
                </Typography>
                <Typography>
                  {t('booking.houseRules.description', { host: property.host })}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2} mt={2}>
                  <Typography>{t('booking.houseRules.cancellationPolicy')}</Typography>
                  <TertiaryButton color="primary">
                    {t('booking.houseRules.cancellationPolicy.flexible')}
                  </TertiaryButton>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>{t('booking.houseRules.rulesPolicy')}</Typography>
                  <TertiaryButton color="primary">
                    {t('booking.houseRules.rulesPolicy.read')}
                  </TertiaryButton>
                </Stack>
              </Box>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <PaymentCard property={property} />
          </Grid>
        </Grid>
      </Container>
    </Stack>
  );
};

export default Booking;
