import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileUpload from '../../Components/FileUpload/FileUpload';

const Photos = ({ photos, setPhotos }) => {
  const { t } = useTranslation();

  return (
    <Box gap={3} mb={2}>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 'bold',
          fontSize: '32px'
        }}
        gutterBottom>
        {t('stepper.step.photos')}
      </Typography>
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <FileUpload photos={photos} setPhotos={setPhotos}>
          {t('common.uploadPhoto')}
        </FileUpload>
      </Stack>
    </Box>
  );
};

export default Photos;
