import React from 'react';
import Input from '../Input/Input';
import { InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const SearchInput = ({ setSearchQuery }) => {
  const { t } = useTranslation();

  return (
    <Input
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
      hiddenLabel
      placeholder={t('searchInput.placeholder')}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
    />
  );
};

export default SearchInput;
