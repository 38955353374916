import React from 'react';
import { TimeField } from '@mui/x-date-pickers';

const Timepicker = ({ required, label, name, id, format = 'HH:mm', ...props }) => {
  return (
    <TimeField
      variant="filled"
      margin="normal"
      required={required}
      fullWidth
      autoComplete="off"
      aria-required={false}
      label={label}
      name={name}
      id={id}
      format={format}
      {...props}
    />
  );
};

export default Timepicker;
