import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AvailabilityCalendar from '../../Components/AvailabilityCalendar/AvailabilityCalendar';
import dayjs from 'dayjs';
import { addDays } from 'date-fns';

const Availability = ({ property }) => {
  const { t } = useTranslation();
  const [reserved, setReserved] = useState([]);

  useEffect(() => {
    if (property && property.reserved) {
      setReserved(
        property.reserved.map((reservation) => {
          return {
            startDate: dayjs(reservation.startDate).toDate(),
            endDate: dayjs(reservation.endDate).toDate()
          };
        })
      );
    }
  }, [property]);

  return (
    <Box id="availability" gap={3} mb={2}>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 600,
          textAlign: 'start',
          fontSize: '16px',
          mb: 2
        }}
        variant="subtitle1">
        {t('propertyDetail.availability.title')}
      </Typography>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <AvailabilityCalendar variant="events" rangeMode={false} reserved={reserved} />
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} />
      </Stack>
    </Box>
  );
};

export default Availability;
