import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const Message = ({ message }) => {
  const bubbleColor = message.type === 'incoming' ? 'inputBG' : 'primary';

  return (
    <Box
      sx={{
        marginBottom: 2,
        alignSelf: message.type === 'incoming' ? 'flex-start' : 'flex-end'
      }}>
      <Paper
        sx={{
          padding: 1.5,
          borderRadius: '5px',
          boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
          maxWidth: '100%',
          backgroundColor: `${bubbleColor}.main`,
          color: `${bubbleColor}.contrastText`
        }}>
        <Typography variant="body1">{message.text}</Typography>
      </Paper>
    </Box>
  );
};

export default Message;
