import React, { useEffect, useState } from 'react';
import { Box, Grid, Container, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { searchSchema } from '../../Utils/Schema/SearchValidation';
import { filterSchema } from '../../Utils/Schema/PropertyValidation';
import SearchBar from '../../Components/SearchBar/SearchBar';
import PropertyCard from '../../Components/PropertyCard/PropertyCard';
import FilterPanel from '../../Components/FilterPanel/FilterPanel';
import Pagination from '../../Components/Pagination/Pagination';
import ErrorPopup from '../../Modules/ErrorPopup';
import useRequest from '../../Hooks/useRequest';
import ENDPOINTS from '../../Constants/Endpoints';
import { useSearchParams } from 'react-router-dom';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const Properties = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPlace, setSelectedPlace] = useState('');
  const [isParamsLoaded, setIsParamsLoaded] = useState(false);
  const [listData, setListData] = useState([]);
  const [latLng, setLatLng] = useState(null);

  const { t } = useTranslation();

  const { useGetData, createData } = useRequest();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const hasParams = Array.from(searchParams.entries()).length > 0;

    if (hasParams) {
      formik.setValues({
        location: {
          lat: parseFloat(searchParams.get('lat')),
          lng: parseFloat(searchParams.get('lan'))
        },
        checkInDate: searchParams.get('checkInDate')
          ? new Date(searchParams.get('checkInDate'))
          : null,
        checkOutDate: searchParams.get('checkOutDate')
          ? new Date(searchParams.get('checkOutDate'))
          : null,
        guest: parseInt(searchParams.get('guest')) || 1
      });
      filterFormik.setFieldValue('category', searchParams.get('category') || '');
      setSelectedPlace(searchParams.get('address') || '');
      setIsParamsLoaded(true);
      if (searchParams.get('lat') && searchParams.get('lan')) {
        setLatLng({
          lat: parseFloat(searchParams.get('lat')),
          lng: parseFloat(searchParams.get('lan'))
        });
      }
    } else {
      setIsParamsLoaded(false);
    }
  }, [searchParams]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      location: null,
      checkInDate: null,
      checkOutDate: null,
      guest: 1
    },
    validationSchema: searchSchema,
    onSubmit: async (values) => {
      const data = {
        lat: String(values.location.lat),
        lan: String(values.location.lng),
        checkInDate: dayjs(values.checkInDate).format('YYYY-MM-DD') || null,
        checkOutDate: dayjs(values.checkOutDate).format('YYYY-MM-DD') || null,
        guest: String(values.guest),
        address: selectedPlace.formatted_address || selectedPlace,
        ...(filterFormik.values.category && {
          category: filterFormik.values.category
        })
      };

      setLatLng({
        lat: values.location.lat,
        lng: values.location.lng
      });
      setSearchParams(data);
      refetch();
      setCurrentPage(1);
    }
  });

  const filterFormik = useFormik({
    initialValues: {
      category: '',
      keyword: '',
      minPrice: 0,
      maxPrice: 0, // TODO: apiden sehre gore maxPrice cekilecek
      street: '',
      district: '',
      city: '',
      country: '',
      maxTraveler: '',
      bedroom: '',
      bed: '',
      bathroom: '',
      isBathShared: false,
      livingArea: {
        sofaSet: false,
        armChair: false,
        sofaBed: false,
        doubleSofaBed: false,
        diningTable: false,
        tv: false,
        satelliteTV: false,
        coffeeTable: false,
        airConditioning: false
      },
      kitchen: {
        crockery: false,
        cutlery: false,
        glassware: false,
        pots: false,
        pans: false,
        diningTable: false,
        oven: false,
        microwave: false,
        fridge: false,
        freezer: false,
        dishWasher: false,
        toaster: false,
        kettle: false,
        kitchenware: false,
        diningArea: false
      },
      generalFacilities: {
        jacuzzi: false,
        cot: false,
        highChair: false,
        safe: false,
        tumbleDryer: false,
        washingMachine: false,
        towel: false,
        internet: false,
        iron: false,
        WIFI: false,
        hairDyrer: false,
        wardrobe: false,
        makeupTable: false,
        airConditioning: false,
        elevator: false,
        pool: false,
        sauna: false,
        tableTennis: false,
        fireplace: false,
        housekeeping: false,
        heating: false
      },
      outdoorFacilities: {
        communalSwimmingPool: false,
        privateSwimmingPool: false,
        gardenFurniture: false,
        communalGarden: false,
        privateGarden: false,
        parking: false,
        outsideLighting: false,
        barbecue: false,
        balconyORterrace: false,
        childrenPool: false,
        security: false,
        seaView: false,
        sunsetView: false,
        poolView: false,
        cityView: false,
        mountainView: false,
        seafront: false,
        complex: false,
        parasols: false,
        sunbeds: false,
        publicBeach: false,
        privateBeach: false,
        paidParking: false,
        outdoorDiningTable: false
      },
      siteAndAttractions: {
        supermarkets: false,
        historicSites: false,
        museum: false,
        bar: false,
        restaurant: false,
        cafeteria: false,
        nightClub: false,
        shoppingCentre: false,
        localFoodMarket: false,
        fishing: false,
        boatTrip: false,
        cycling: false,
        horseRiding: false,
        golf: false,
        fitnessCenterGYM: false,
        SPA: false,
        turkishBath: false,
        tennisCourt: false,
        basketballCourt: false,
        volleyballCourt: false
      },
      suitabilities: {
        petsAllowed: '',
        smoking: '',
        restrictedMobility: '',
        children: '',
        maleGroups: ''
      },
      carRental: '',
      checkInTime: null,
      checkOutTime: null
    },
    validationSchema: filterSchema,
    onSubmit: async () => {
      await createData.mutateAsync(
        {
          endpoint: ENDPOINTS.advancedFilter,
          body: {
            ...filterFormik.values,
            checkInAt: formik.values.checkInDate,
            checkOutAt: formik.values.checkOutDate,
            lat: latLng.lat,
            lan: latLng.lng,
            guest: formik.values.guest
          }
        },
        {
          onSuccess: async (data) => {
            if (data) {
              setListData(data);
              setCurrentPage(1);
            }
          }
        }
      );
    }
  });

  const { data, refetch } = useGetData(
    isParamsLoaded && 'properties',
    ENDPOINTS.properties,
    { enabled: isParamsLoaded },
    {
      lat: formik.values.location?.lat || '',
      lan: formik.values.location?.lng || '',
      checkInDate: formik.values.checkInDate,
      checkOutDate: formik.values.checkOutDate,
      guest: formik.values.guest,
      category: filterFormik.values.category
    }
  );

  useEffect(() => {
    if (data) {
      setListData(data);
    }
  }, [data]);

  return (
    <>
      <Stack mb={3}>
        <Box
          sx={{
            position: 'sticky',
            top: '0px',
            borderRadius: '4px',
            backgroundColor: scrollTop > 80 ? 'header.main' : 'transparent',
            zIndex: 999
          }}>
          <Container
            sx={{
              p: 3
            }}>
            <SearchBar
              formik={formik}
              selectedPlace={selectedPlace}
              setSelectedPlace={setSelectedPlace}
            />
          </Container>
        </Box>
        <Box
          sx={{
            py: 2,
            backgroundColor: 'header.main',
            border: '0.2px solid',
            borderColor: 'primary',
            mb: 3
          }}>
          <FilterPanel formik={filterFormik} />
        </Box>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} px={3} mb={3}>
          <Grid item md={latLng ? 7 : 12} sm={12} xs={12} sx={{ maxHeight: 650, overflow: 'auto' }}>
            {listData?.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: '100px'
                }}>
                <Typography variant="h6">{t('properties.notFound')}</Typography>
              </Box>
            ) : (
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 12, md: 12 }}>
                {listData?.length > 0 &&
                  listData.map((property, index) => (
                    <Grid item xs={6} sm={6} md={latLng ? 6 : 4} key={index}>
                      <PropertyCard property={property} />
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>
          {latLng && (
            <Grid item md={5} sm={12} xs={12}>
              <Box position="relative" sx={{ width: '100%', height: 650, border: '24px' }}>
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
                  <Map
                    center={latLng}
                    defaultZoom={8}
                    gestureHandling="greedy"
                    disableDefaultUI={true}
                  />
                </APIProvider>
              </Box>
            </Grid>
          )}
        </Grid>
        {listData && listData.length === 0 ? null : (
          <Pagination items={listData} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )}
      </Stack>
      <ErrorPopup formik={formik} />
    </>
  );
};

export default Properties;
