import React, { useRef, useState } from 'react';
import {
  Box,
  Grid,
  Container,
  Typography,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaymentCard from '../../Components/PaymentCard/PaymentCard';
import TertiaryButton from '../../Components/Button/TertiaryButton';
import Input from '../../Components/Input/Input';
import { useFormik } from 'formik';
import MainButton from '../../Components/Button/MainButton';
import BasicDatePicker from '../../Components/Datepicker/Datepicker';
import dayjs from 'dayjs';
import { preReservationSchema } from '../../Utils/Schema/BookingValidation';
import ErrorPopup from '../../Modules/ErrorPopup';
import ChatContainer from '../../Components/Chat/ChatContainer';
import { Send } from '@mui/icons-material';
import DecimalInput from '../../Components/DecimalInput/DecimalInput';

const PreReservation = () => {
  const { t } = useTranslation();

  const property = {
    title: 'Siesta Garden',
    imageUrl:
      'https://images.unsplash.com/photo-1477314099024-9f209cdc507a?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    location: 'Bodrum Yalıkavak',
    price: '50$',
    host: 'Beyza Erkan',
    checkIn: '14:00',
    checkOut: '12:00'
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      checkInDate: null,
      checkOutDate: null,
      guest: 1
    },
    validationSchema: preReservationSchema
  });

  const [text, setText] = useState('');
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const clickHandler = () => {
    if (text.trim() === '') return;
    // mesaj gonder

    setText('');
  };

  const pressHandler = (event) => {
    if (event.key === 'Enter') {
      return clickHandler();
    }
  };

  return (
    <Stack gap={3} mb={3} direction="row">
      <Container sx={{ minWidth: 'auto', mt: 4 }}>
        <Grid container spacing={{ sx: 1, md: 10 }}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 24,
                      mt: 6
                    }}>
                    {t('preReservation.contact.title')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Input
                  label={t('profile.account.input.firstName')}
                  onChange={formik.handleChange('firstName')}
                  value={formik.values.firstName}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Input
                  label={t('profile.account.input.lastName')}
                  onChange={formik.handleChange('lastName')}
                  value={formik.values.lastName}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Input
                  label={t('profile.account.input.mail')}
                  onChange={formik.handleChange('email')}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Input
                  label={t('profile.account.input.phoneNumber')}
                  onChange={formik.handleChange('phoneNumber')}
                  value={formik.values.phoneNumber}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                />
              </Grid>
              <Grid item xs={4}>
                <BasicDatePicker
                  label={t('searchbar.input.checkInDate')}
                  name="checkInDate"
                  value={formik.values.checkInDate}
                  onChange={(date) => formik.setFieldValue('checkInDate', date)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.checkInDate && Boolean(formik.errors.checkInDate)}
                  disablePast
                />
              </Grid>
              <Grid item xs={4}>
                <BasicDatePicker
                  label={t('searchbar.input.checkOutDate')}
                  name="checkOutDate"
                  value={formik.values.checkOutDate}
                  onChange={(date) => formik.setFieldValue('checkOutDate', date)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.checkOutDate && Boolean(formik.errors.checkOutDate)}
                  disablePast
                />
              </Grid>
              <Grid item xs={4}>
                <DecimalInput
                  label={t('searchbar.input.guest')}
                  onChange={formik.handleChange('guest')}
                  value={formik.values.guest}
                  onBlur={formik.handleBlur}
                  error={formik.touched.guest && Boolean(formik.errors.guest)}
                />
              </Grid>
            </Grid>
            <Box mb={2}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 24,
                  mt: 6
                }}>
                {t('booking.title', { host: property.host })}
              </Typography>
            </Box>
            <Box>
              <List
                dense={true}
                sx={{ listStyle: 'disc', pl: 4 }}
                subheader={<Typography>{t('booking.helpfulTips')}</Typography>}>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText
                    primary={t('booking.helpfulTips.firstQuestion', {
                      location: property.location
                    })}
                  />
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText primary={t('booking.helpfulTips.secondQuestion')} />
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText primary={t('booking.helpfulTips.thirdQuestion')} />
                </ListItem>
              </List>
            </Box>
            <Box>
              <Stack
                sx={{
                  pb: 2,
                  px: 2,
                  background: 'white',
                  zIndex: 10
                }}
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center">
                <Input
                  hiddenLabel
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onKeyDown={pressHandler}
                />
                <IconButton aria-label="delete" size="small" onClick={clickHandler}>
                  {/* hem mesajı gönder hem de mesajlar kısmına geç */}
                  <Send color="primary" />
                </IconButton>
              </Stack>
            </Box>
            <Box mb={4}>
              <Divider />
              <Box m={2}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 20
                  }}>
                  {t('booking.houseRules.title')}
                </Typography>
                <Typography>
                  {t('booking.houseRules.description', { host: property.host })}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2} mt={2}>
                  <Typography>{t('booking.houseRules.cancellationPolicy')}</Typography>
                  <TertiaryButton color="primary">
                    {t('booking.houseRules.cancellationPolicy.flexible')}
                  </TertiaryButton>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>{t('booking.houseRules.rulesPolicy')}</Typography>
                  <TertiaryButton color="primary">
                    {t('booking.houseRules.rulesPolicy.read')}
                  </TertiaryButton>
                </Stack>
              </Box>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <PaymentCard
              property={{
                ...property,
                ...formik.values
              }}
              handleSubmit={formik.handleSubmit}
            />
          </Grid>
        </Grid>
      </Container>
      <ErrorPopup formik={formik} />
    </Stack>
  );
};

export default PreReservation;
