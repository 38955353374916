import * as yup from 'yup';
import { Trans } from 'react-i18next';

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email(<Trans i18nKey="validation.email.invalid" />)
    .required(<Trans i18nKey="validation.mail.required" />),

  password: yup
    .string()
    .min(
      8,
      <Trans
        i18nKey="validation.password.min"
        values={{
          min: 8
        }}
      />
    )
    .max(
      25,
      <Trans
        i18nKey="validation.password.max"
        values={{
          max: 25
        }}
      />
    )
    .required(<Trans i18nKey="validation.password.required" />)
});

export default loginSchema;
