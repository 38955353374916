import React from 'react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from '../../Components/Select/Select';
import { currencies } from '../../Constants/SelectOptions';
import DecimalInput from '../../Components/DecimalInput/DecimalInput';

const Pricing = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <Box gap={3} mb={2}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 1, md: 12 }}
        alignItems="center">
        <Grid item xs={12}>
          <Typography
            sx={{
              color: 'header.main',
              fontWeight: 'bold',
              fontSize: '32px'
            }}
            gutterBottom>
            {t('stepper.step.pricing')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <DecimalInput
            label={t('createProperty.input.perDayPrice')}
            value={formik.values.pricing.perDayPrice}
            onChange={formik.handleChange('pricing.perDayPrice')}
            onBlur={formik.handleBlur('pricing.perDayPrice')}
            InputProps={{
              startAdornment: formik.values.pricing.currency ? (
                <InputAdornment position="start">
                  {
                    currencies.find((currency) => currency.value === formik.values.pricing.currency)
                      .symbol
                  }
                </InputAdornment>
              ) : null,
              inputProps: {
                step: 10,
                min: 0
              }
            }}
            error={
              formik.touched.pricing?.perDayPrice && Boolean(formik.errors.pricing?.perDayPrice)
            }
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label={t('createProperty.input.currency')}
            value={formik.values.pricing.currency}
            onChange={formik.handleChange('pricing.currency')}
            error={formik.touched.pricing?.currency && Boolean(formik.errors.pricing?.currency)}
            options={currencies}
            onBlur={formik.handleBlur('pricing.currency')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: 'header.main',
              fontWeight: 600,
              textAlign: 'start',
              fontSize: '16px'
            }}
            variant="subtitle1">
            {t('createProperty.additionalOptions.title')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <DecimalInput
            label={t('createProperty.input.cleaningFee')}
            value={formik.values.pricing.additionalOptions.cleaningFee}
            onChange={formik.handleChange('pricing.additionalOptions.cleaningFee')}
            onBlur={formik.handleBlur('pricing.additionalOptions.cleaningFee')}
            InputProps={{
              startAdornment: formik.values.pricing.currency ? (
                <InputAdornment position="start">
                  {
                    currencies.find((currency) => currency.value === formik.values.pricing.currency)
                      .symbol
                  }
                </InputAdornment>
              ) : null
              // inputProps: {
              //   step: 10,
              //   min: 0
              // }
            }}
            error={
              formik.touched.pricing?.additionalOptions?.cleaningFee &&
              Boolean(formik.errors.pricing?.additionalOptions?.cleaningFee)
            }
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DecimalInput
            label={t('createProperty.input.additionalGuest')}
            value={formik.values.pricing.additionalOptions.additionalGuest}
            onChange={formik.handleChange('pricing.additionalOptions.additionalGuest')}
            onBlur={formik.handleBlur('pricing.additionalOptions.additionalGuest')}
            InputProps={{
              startAdornment: formik.values.pricing.currency ? (
                <InputAdornment position="start">
                  {
                    currencies.find((currency) => currency.value === formik.values.pricing.currency)
                      .symbol
                  }
                </InputAdornment>
              ) : null
              // inputProps: {
              //   step: 10,
              //   min: 0
              // }
            }}
            error={
              formik.touched.pricing?.additionalOptions?.additionalGuest &&
              Boolean(formik.errors.pricing?.additionalOptions?.additionalGuest)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DecimalInput
            label={t('createProperty.input.securityDeposit')}
            value={formik.values.pricing.additionalOptions.securityDeposit}
            onChange={formik.handleChange('pricing.additionalOptions.securityDeposit')}
            onBlur={formik.handleBlur('pricing.additionalOptions.securityDeposit')}
            InputProps={{
              startAdornment: formik.values.pricing.currency ? (
                <InputAdornment position="start">
                  {
                    currencies.find((currency) => currency.value === formik.values.pricing.currency)
                      .symbol
                  }
                </InputAdornment>
              ) : null
              // inputProps: {
              //   step: 10,
              //   min: 0
              // }
            }}
            error={
              formik.touched.pricing?.additionalOptions?.securityDeposit &&
              Boolean(formik.errors.pricing?.additionalOptions?.securityDeposit)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DecimalInput
            label={t('createProperty.input.weekendPricing')}
            value={formik.values.pricing.additionalOptions.weekendPricing}
            onChange={formik.handleChange('pricing.additionalOptions.weekendPricing')}
            onBlur={formik.handleBlur('pricing.additionalOptions.weekendPricing')}
            InputProps={{
              startAdornment: formik.values.pricing.currency ? (
                <InputAdornment position="start">
                  {
                    currencies.find((currency) => currency.value === formik.values.pricing.currency)
                      .symbol
                  }
                </InputAdornment>
              ) : null
              // inputProps: {
              //   step: 10,
              //   min: 0
              // }
            }}
            error={
              formik.touched.pricing?.additionalOptions?.weekendPricing &&
              Boolean(formik.errors.pricing?.additionalOptions?.weekendPricing)
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pricing;
