import React from 'react';
import { Typography, Stack } from '@mui/material';
import { LocationOnOutlined } from '@mui/icons-material';

const HeaderWithLocation = ({ location, header, host, style, locationStyle, headerStyle }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        mt={2}
        sx={style}>
        <LocationOnOutlined color="text" />
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 200,
            color: 'text.main',
            ...locationStyle
          }}>
          {location}
        </Typography>
      </Stack>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 600,
          textAlign: 'start',
          fontSize: '16px',
          ...headerStyle
        }}
        variant="subtitle1">
        {header}
      </Typography>
      {host && (
        <Typography
          sx={{
            fontWeight: 200,
            textAlign: 'start',
            fontSize: '15px'
          }}>
          {host}
        </Typography>
      )}
    </>
  );
};
export default HeaderWithLocation;
