import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

const Input = ({
  type,
  value,
  onChange,
  error,
  helperText,
  label,
  length,
  debounceTime = 0,
  ...props
}) => {
  const [counter, setCounter] = useState(length);
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    if (!length) return;
    const characters = length - value.length;
    setCounter(characters);
  }, [value]);

  useEffect(() => {
    if (value) {
      setInnerValue(value);
    } else {
      setInnerValue('');
    }
  }, [value]);

  const debounceHandleOnChange = useDebouncedCallback((e) => {
    if (onChange) onChange(e);
  }, debounceTime);

  const handleOnChange = useCallback(
    (event) => {
      event.persist();
      const newValue = event.currentTarget.value;

      if (length && newValue.length > length) return;

      setInnerValue(newValue);
      debounceHandleOnChange(event);
    },
    [length]
  );

  return (
    <TextField
      label={label}
      variant="filled"
      value={innerValue}
      type={type === 'date' ? 'text' : type}
      onChange={handleOnChange}
      error={error}
      helperText={helperText}
      fullWidth
      inputProps={{
        maxLength: length
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              color: counter === 0 ? 'red' : 'primary.main'
            }}>
            {length ? counter : null}
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
};

export default Input;
