import React, { useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Avatar from '../../Components/Avatar/Avatar';
import MainButton from '../../Components/Button/MainButton';
import Input from '../../Components/Input/Input';
import ErrorPopup from '../../Modules/ErrorPopup';
import { updateAccountSchema } from '../../Utils/Schema/ProfileValidation';
import { useAuth } from '../../Contexts/AuthContext';
import useRequest from '../../Hooks/useRequest';
import ENDPOINTS from '../../Constants/Endpoints';
import { getMe } from '../../Utils/Auth';

const Account = () => {
  const { t } = useTranslation();
  const { user, setUser } = useAuth();
  const { updateData } = useRequest();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    },
    validationSchema: updateAccountSchema,
    onSubmit: async (values) => {
      await updateData.mutate(
        {
          endpoint: `${process.env.REACT_APP_USER_SERVICE_PATH}${ENDPOINTS.users}/${user._id}`,
          body: values
        },
        {
          onSuccess: async (data) => {
            if (data) {
              const { user: me } = await getMe();
              setUser(me);
            }
          }
        }
      );
    }
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phoneNumber: user?.phoneNumber
      });
    }
  }, [user]);

  return (
    <>
      <Stack gap={3} p={2}>
        <Stack direction="row" spacing={2}>
          <Avatar sx={{ width: 100, height: 100 }}>
            {user?.firstName.concat(' ', user?.lastName)}
          </Avatar>
          {/* <TertiaryButton>{t('profile.account.button.changePhoto')}</TertiaryButton> */}
        </Stack>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Input
              label={t('profile.account.input.firstName')}
              onChange={formik.handleChange('firstName')}
              value={formik.values.firstName}
              onBlur={formik.handleBlur}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              label={t('profile.account.input.lastName')}
              onChange={formik.handleChange('lastName')}
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              label={t('profile.account.input.mail')}
              onChange={formik.handleChange('email')}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              label={t('profile.account.input.phoneNumber')}
              onChange={formik.handleChange('phoneNumber')}
              value={formik.values.phoneNumber}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            />
          </Grid>
          <Grid item md={11} xs={0} />
          <Grid item md={1} xs={12}>
            <MainButton type="submit" onClick={formik.handleSubmit}>
              {t('profile.account.button.title')}
            </MainButton>
          </Grid>
        </Grid>
      </Stack>
      <ErrorPopup formik={formik} />
    </>
  );
};

export default Account;
