import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Container, Typography, Stack, Divider, Link, Tooltip } from '@mui/material';
import Header from '../../Components/Header/Header';
import BookingCard from '../../Components/BookingCard/BookingCard';
import ErrorPopup from '../../Modules/ErrorPopup';
import { useFormik } from 'formik';
import {
  Apartment,
  BusinessOutlined,
  HotelOutlined,
  HolidayVillage,
  BedOutlined,
  BathroomOutlined,
  Group,
  CheckBox
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { reservationSchema } from '../../Utils/Schema/SearchValidation';

import PropertyDetailSection from './PropertyDetailSection';
import Location from './Location';
import GoodToKnow from './GoodToKnow';
import Availability from './Availability';
import Reviews from './Reviews';
import ReservationDetails from './ReservationDetails';
import MainButton from '../../Components/Button/MainButton';
import QuestionPopup from '../../Components/QuestionPopup/QuestionPopup';
import ENDPOINTS from '../../Constants/Endpoints';
import useRequest from '../../Hooks/useRequest';
import ImageList from '../../Components/ImageList/ImageList';
import { useAuth } from '../../Contexts/AuthContext';
import { ROLES } from '../../Constants/Permissions';
import { categories, CATEGORY } from '../../Constants/Property';
import { bedTypes, currencies } from '../../Constants/SelectOptions';

const PropertyDetail = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  const questionPopupRef = useRef();
  const { useGetData, deleteData } = useRequest();

  const { data } = useGetData('property', `${ENDPOINTS.properties}/${id}`);

  const isOwner = user
    ? (user.role === ROLES.HOST || user.role === ROLES.ADMIN) && data?.host?._id === user?._id
    : false;

  const formik = useFormik({
    initialValues: {
      checkInDate: null,
      checkOutDate: null,
      guest: 1
    },
    validationSchema: reservationSchema,
    onSubmit: async (values) => {}
  });

  const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (data) {
      const images = data?.property?.photos?.map((photo, i) => {
        const width = breakpoints[0];
        const height = (photo.height / photo.width) * width;

        return {
          src: photo?.src,
          id: i + 1,
          width,
          height
          // srcSet: breakpoints.map((breakpoint) => {
          //   const height = Math.round((photo.height / photo.width) * breakpoint);
          //   return {
          //     src: `${photo.src}/${width}x${height}`,
          //     width: breakpoint,
          //     height
          //   };
          // })
        };
      });

      setPhotos(images);
    }
  }, [data]);

  const handleDelete = async () => {
    await deleteData.mutateAsync(
      {
        endpoint: `${ENDPOINTS.properties}/${id}`
      },
      {
        onSuccess: async (data) => {
          if (data) {
            navigate('/profile', { state: { profile: 1 } });
          }
        }
      }
    );
  };

  return (
    <>
      <Stack gap={3} mb={3} direction="row">
        <Container sx={{ minWidth: 'auto', mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={isOwner ? 12 : 8.5}>
              <Box mb={2}>
                <ImageList
                  photos={
                    photos?.length > 0
                      ? photos
                      : [
                          {
                            src: categories(t)?.find(
                              (category) => category.name === data?.property?.category
                            )?.image,
                            id: 1,
                            width: breakpoints[0],
                            height:
                              (categories(t)?.find(
                                (category) => category.name === data?.property?.category
                              )?.height /
                                categories(t)?.find(
                                  (category) => category.name === data?.property?.category
                                )?.width) *
                              breakpoints[0]
                          }
                        ]
                  }
                  spacing={10}
                  targetRowHeight={200}
                  layout="columns"
                  columns={3}
                />
                {photos?.length === 0 && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'inputPlaceholder.main',
                      fontSize: '14px'
                    }}>
                    {t('property.noPhoto')}
                  </Typography>
                )}
                <Header /*reviewNumber={property.comments}*/ isRatingShow readOnly>
                  {data?.property?.title}
                </Header>
                <Stack direction="row" spacing={1} justifyItems="center" alignItems="center" mb={1}>
                  <Stack direction="row" spacing={2} justifyItems="center" alignItems="center">
                    {data?.property?.category === CATEGORY.APART ? (
                      <Tooltip
                        title={t(`property.category.${CATEGORY.APART.toLowerCase()}`)}
                        placement="bottom">
                        <Apartment color="text" fontSize="small" />
                      </Tooltip>
                    ) : data?.property?.category === CATEGORY.FLAT ? (
                      <Tooltip
                        title={t(`property.category.${CATEGORY.FLAT.toLowerCase()}`)}
                        placement="bottom">
                        <BusinessOutlined color="text" fontSize="small" />
                      </Tooltip>
                    ) : data?.property?.category === CATEGORY.HOTEL ? (
                      <Tooltip
                        title={t(`property.category.${CATEGORY.HOTEL.toLowerCase()}`)}
                        placement="bottom">
                        <HotelOutlined color="text" fontSize="small" />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={t(`property.category.${CATEGORY.VILLA.toLowerCase()}`)}
                        placement="bottom">
                        <HolidayVillage color="text" fontSize="small" />
                      </Tooltip>
                    )}
                  </Stack>
                  <Typography>
                    {data?.property?.address?.street}, {data?.property?.address?.houseNumber},
                    {data?.property?.address?.postalCode}, {data?.property?.address?.city},{' '}
                    {data?.property?.address?.country}
                  </Typography>
                </Stack>
                <Divider />
              </Box>
              <Box mb={4}>
                {isOwner && data?.host?._id === user?._id && (
                  <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                    <MainButton
                      sx={{
                        width: 'auto'
                      }}
                      color="error"
                      onClick={() => questionPopupRef?.current?.openDialog()}>
                      {t('common.delete')}
                    </MainButton>
                    <MainButton
                      sx={{
                        width: 'auto'
                      }}
                      color="success"
                      onClick={() => navigate(`/update-property/${id}`)}>
                      {t('common.update')}
                    </MainButton>
                  </Stack>
                )}
                <Typography>{data?.property?.description?.shortDescription}</Typography>
                <Grid container spacing={2} sx={{ overflow: 'hidden' }}>
                  <Grid item xs={7}>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: 'header.main',
                            fontWeight: 600,
                            textAlign: 'start',
                            fontSize: '16px'
                          }}
                          variant="subtitle1">
                          {t('createProperty.additionalOptions.title')}
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={6}>
                        <Typography fontSize={14} fontWeight="bold">
                          {t('createProperty.input.cleaningFee')}:
                        </Typography>
                      </Grid>
                      <Grid item md={8} xs={6}>
                        <Typography variant="subtitle2">
                          {data?.property?.pricing?.additionalOptions?.cleaningFee}{' '}
                          {
                            currencies.find(
                              (currency) => currency.value === data?.property?.pricing?.currency
                            )?.symbol
                          }
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={6}>
                        <Typography fontSize={14} fontWeight="bold">
                          {t('createProperty.input.additionalGuest')}:
                        </Typography>
                      </Grid>
                      <Grid item md={8} xs={6}>
                        <Typography variant="subtitle2">
                          {data?.property?.pricing?.additionalOptions?.additionalGuest}{' '}
                          {
                            currencies.find(
                              (currency) => currency.value === data?.property?.pricing?.currency
                            )?.symbol
                          }
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={6}>
                        <Typography fontSize={14} fontWeight="bold">
                          {t('createProperty.input.securityDeposit')}:
                        </Typography>
                      </Grid>
                      <Grid item md={8} xs={6}>
                        <Typography variant="subtitle2">
                          {data?.property?.pricing?.additionalOptions?.securityDeposit}{' '}
                          {
                            currencies.find(
                              (currency) => currency.value === data?.property?.pricing?.currency
                            )?.symbol
                          }
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={6}>
                        <Typography fontSize={14} fontWeight="bold">
                          {t('createProperty.input.weekendPricing')}:
                        </Typography>
                      </Grid>
                      <Grid item md={8} xs={6}>
                        <Typography variant="subtitle2">
                          {data?.property?.pricing?.additionalOptions?.weekendPricing}{' '}
                          {
                            currencies.find(
                              (currency) => currency.value === data?.property?.pricing?.currency
                            )?.symbol
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Stack direction="column" alignItems="center" mb={2}>
                      <Stack direction="row" alignItems="center">
                        <BedOutlined fontSize="large" /> {data?.property?.basicFeatures?.bedroom}
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <BathroomOutlined fontSize="large" />{' '}
                        {data?.property?.basicFeatures?.bathroom}
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Group fontSize="large" /> {data?.property?.basicFeatures?.maxTraveler}
                      </Stack>
                      {data?.property?.basicFeatures?.isBathShared && (
                        <Stack direction="row" alignItems="center">
                          <Typography>{t('createProperty.input.isBathShared')}</Typography>
                          <Typography variant="subtitle2">
                            <CheckBox />
                          </Typography>
                        </Stack>
                      )}
                      <Stack direction="row" alignItems="center">
                        <Typography>{t('createProperty.input.bed')}: </Typography>
                        <Typography variant="subtitle2">
                          {
                            bedTypes.find(
                              (type) => type.value === data?.property?.basicFeatures?.bed
                            )?.label
                          }
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  justifyContent="space-between"
                  alignItems="center"
                  p={2}>
                  <Link href="#property-detail" underline="hover">
                    {t('propertyDetail.detail.title')}
                  </Link>
                  <Link href="#location" underline="hover">
                    {t('propertyDetail.location.title')}
                  </Link>
                  <Link href="#good-to-know" underline="hover">
                    {t('propertyDetail.goodToKnow.title')}
                  </Link>
                  <Link href="#availability" underline="hover">
                    {t('propertyDetail.availability.title')}
                  </Link>
                  <Link href="#reviews" underline="hover">
                    {t('propertyDetail.reviews.title')}
                  </Link>
                </Stack>
                <Divider />
              </Box>
              <Stack gap={3}>
                <PropertyDetailSection property={data?.property} />
                <Location property={data?.property} />
                <GoodToKnow property={data?.property} />
                <Availability property={data?.property} />
                <Reviews property={data?.property} />
              </Stack>
              {isOwner && (
                <Stack gap={3}>
                  <ReservationDetails property={data?.property} />
                </Stack>
              )}
            </Grid>
            {!isOwner && (
              <Grid item xs={12} md={3.5}>
                <BookingCard property={data?.property} host={data?.host} formik={formik} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Stack>
      <ErrorPopup formik={formik} />
      <QuestionPopup ref={questionPopupRef} yesClick={handleDelete} />
    </>
  );
};

export default PropertyDetail;
