import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';

const DecimalInput = ({ placeholder, InputProps, allowNegative = false, ...props }) => {
  const materialUITextFieldProps = {
    id: 'filled-multiline-flexible',
    variant: 'filled'
  };
  return (
    <NumericFormat
      InputProps={{
        placeholder,
        ...InputProps
      }}
      fullWidth
      allowNegative={allowNegative}
      customInput={TextField}
      {...materialUITextFieldProps}
      {...props}
    />
  );
};

export default DecimalInput;
