import { useState, useEffect, createContext, useContext } from 'react';
import instance, { getMe, logout } from '../Utils/Auth';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem('x-access-token');

  useEffect(() => {
    setLoading(true);

    instance.interceptors.request.use((config) => {
      setLoading(true);
      return config;
    });

    instance.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        if (error.response) {
          setLoading(false);
        } else if (error.request) {
          console.log('Request Error:', error.request);
        } else {
          console.log('Error:', error.message);
        }
      }
    );
  }, [instance]);

  useEffect(() => {
    if (token) {
      (async () => {
        try {
          const { user } = await getMe();
          setLoggedIn(true);
          setUser(user);
        } catch (e) {
          setLoggedIn(false);
          setUser(null);
          localStorage.removeItem('x-access-token'); // Hatalı tokeni kaldır
        } finally {
          setLoading(false); // Yükleme durumunu güncelle
        }
      })();
    } else {
      setLoading(false); // Token yoksa yükleme durumu false
    }
  }, []);

  const loginAccount = (data) => {
    setLoggedIn(true);
    setUser(data.user);
    localStorage.setItem('x-access-token', data.token.value);
  };

  const logoutAccount = async (callback) => {
    await logout();

    localStorage.removeItem('x-access-token');
    setLoggedIn(false);
    setUser(null);

    callback();
  };

  const values = {
    user,
    setUser,
    loggedIn,
    loginAccount,
    logoutAccount,
    isLoginOpen,
    setIsLoginOpen,
    loading,
    isModalOpen,
    setIsModalOpen
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
