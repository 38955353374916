import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide
} from '@mui/material';
import { PhotoAlbum } from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Counter from 'yet-another-react-lightbox/plugins/counter';

import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';
import { Close, Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import QuestionPopup from '../QuestionPopup/QuestionPopup';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable';
import clsx from 'clsx';
import WarningPopup from '../WarningPopup/WarningPopup';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PhotoFrame = memo(
  forwardRef(function PhotoFrame(props, ref) {
    const { layoutOptions, imageProps, overlay, active, insertPosition, attributes, listeners } =
      props;
    const { alt, style, ...restImageProps } = imageProps;

    return (
      <div
        ref={ref}
        style={{
          width: overlay ? `calc(100% - ${2 * layoutOptions.padding}px)` : style.width,
          padding: style.padding,
          marginBottom: style.marginBottom
        }}
        className={clsx('photo-frame', {
          overlay: overlay,
          active: active,
          insertBefore: insertPosition === 'before',
          insertAfter: insertPosition === 'after'
        })}
        {...attributes}
        {...listeners}>
        <img
          alt={alt}
          style={{
            ...style,
            width: '100%',
            height: 'auto',
            padding: 0,
            marginBottom: 0
          }}
          {...restImageProps}
        />
      </div>
    );
  })
);

function SortablePhotoFrame(props) {
  const { photo, activeIndex } = props;
  const { attributes, listeners, isDragging, index, over, setNodeRef } = useSortable({
    id: photo.id
  });

  return (
    <PhotoFrame
      ref={setNodeRef}
      active={isDragging}
      insertPosition={
        activeIndex !== undefined && over?.id === photo.id && !isDragging
          ? index > activeIndex
            ? 'after'
            : 'before'
          : undefined
      }
      aria-label="sortable image"
      attributes={attributes}
      listeners={listeners}
      {...props}
    />
  );
}
const ImageList = forwardRef(
  (
    {
      photos,
      setPhotos,
      spacing,
      targetRowHeight,
      layout,
      columns,
      isDeleting = false,
      isSortable = false
    },
    ref
  ) => {
    const { t } = useTranslation();
    const questionPopupRef = useRef();

    const [index, setIndex] = useState(-1);
    const [showAllModal, setShowAllModal] = useState(false);
    const [warningPopup, setWarningPopup] = useState(false);
    const renderedPhotos = useRef({});
    const [activeId, setActiveId] = useState();
    const activeIndex = activeId ? photos.findIndex((photo) => photo.id === activeId) : undefined;
    const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

    useImperativeHandle(
      ref,
      () => ({
        uploadPhoto: (e) => {
          const photo = new Image();
          photo.src = URL.createObjectURL(e.target.files[0]);
          const reader = new FileReader();

          if (e.target.files[0].size * 0.000001 > 20) {
            setWarningPopup(true);
            return;
          }

          photo.onload = () => {
            const width = breakpoints[0];
            const height = (photo.height / photo.width) * width;

            reader.readAsDataURL(e.target.files[0]);
            reader.onload = async (event) => {
              const binary = event.target.result;

              setPhotos((oldFiles) => [
                {
                  id: photo.key || photo.src,
                  src: binary,
                  width,
                  height
                  // srcSet: breakpoints.map((breakpoint) => {
                  //   const height = Math.round((photo.height / photo.width) * breakpoint);
                  //   return {
                  //     src: binary,
                  //     width: breakpoint,
                  //     height
                  //   };
                  // })
                },
                ...oldFiles
              ]);
            };
          };
        }
      }),
      []
    );

    const sensors = useSensors(
      useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
      useSensor(TouchSensor, {
        activationConstraint: { delay: 50, tolerance: 10 }
      }),
      useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    const handleDragStart = useCallback(({ active }) => setActiveId(active.id), []);

    const handleDragEnd = useCallback((event) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        setPhotos((items) => {
          const oldIndex = items.findIndex((item) => item.id === active.id);
          const newIndex = items.findIndex((item) => item.id === over.id);

          return arrayMove(items, oldIndex, newIndex);
        });
      }

      setActiveId(undefined);
    }, []);

    const renderPhoto = (props) => {
      renderedPhotos.current[props.photo.id] = props;
      return <SortablePhotoFrame activeIndex={activeIndex} {...props} />;
    };

    const remainingPhotosCount = photos?.length - 4;

    const handleShowAllClick = () => {
      setShowAllModal(true);
    };

    const handleCloseAllModal = () => {
      setShowAllModal(false);
    };

    const yesClick = async () => {
      const filtered = photos.filter((image, i) => i !== index);
      setPhotos(filtered);
      setIndex(-1); // Lightbox'ı kapat
      setShowAllModal(false); // Show all modalı kapat
      questionPopupRef?.current?.closeDialog();
    };

    return (
      <Box mb={2}>
        <PhotoAlbum
          photos={photos?.slice(0, 4)}
          spacing={spacing}
          targetRowHeight={targetRowHeight}
          layout={layout}
          columns={columns}
          onClick={({ index }) => setIndex(index)}
        />

        <Lightbox
          slides={photos}
          open={index >= 0}
          index={index}
          on={{ view: ({ index: currentIndex }) => setIndex(currentIndex) }}
          close={() => setIndex(-1)}
          plugins={[Fullscreen, Slideshow, Zoom, Counter]}
          counter={{ container: { style: { top: 'unset', bottom: 0 } } }}
          toolbar={
            isDeleting
              ? {
                  buttons: [
                    <Button
                      key="my-button"
                      type="button"
                      className="yarl__button"
                      onClick={() => questionPopupRef?.current?.openDialog()}>
                      <Delete />
                    </Button>,
                    'close'
                  ]
                }
              : null
          }
        />

        <Button variant="text" color="primary" onClick={handleShowAllClick}>
          {photos?.length > 0
            ? remainingPhotosCount > 0
              ? `+ ${remainingPhotosCount} ${t('imageList.morePhoto')}`
              : t('imageList.showGallery')
            : null}
        </Button>

        <Dialog
          fullScreen
          open={showAllModal}
          onClose={handleCloseAllModal}
          TransitionComponent={Transition}>
          <DialogActions>
            <Button autoFocus onClick={handleCloseAllModal}>
              <Close />
            </Button>
          </DialogActions>
          <DialogContent>
            {isSortable ? (
              <>
                <DialogContentText ml={4}>{t('imageList.sortable.description')}</DialogContentText>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}>
                  <SortableContext items={photos}>
                    <div style={{ margin: 30 }}>
                      <PhotoAlbum
                        photos={photos}
                        spacing={spacing}
                        targetRowHeight={targetRowHeight}
                        layout="columns"
                        columns={columns}
                        onClick={({ index }) => setIndex(index)}
                        renderPhoto={renderPhoto}
                      />
                    </div>
                  </SortableContext>
                  <DragOverlay>
                    {activeId && <PhotoFrame overlay {...renderedPhotos.current[activeId]} />}
                  </DragOverlay>
                </DndContext>
              </>
            ) : (
              <Container>
                <PhotoAlbum
                  photos={photos}
                  spacing={spacing}
                  targetRowHeight={targetRowHeight}
                  layout={layout}
                  columns={columns}
                  onClick={({ index }) => setIndex(index)}
                />
              </Container>
            )}
          </DialogContent>
        </Dialog>
        <QuestionPopup ref={questionPopupRef} yesClick={yesClick} />
        <WarningPopup
          warningDesc={t('imageList.invalidSize')}
          open={warningPopup}
          setOpen={setWarningPopup}
        />
      </Box>
    );
  }
);

export default ImageList;
