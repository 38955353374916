import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  ExpandMore,
  Tune,
  Star,
  LocalOffer,
  NewReleases,
  Reviews,
  Close
} from '@mui/icons-material';
import MainButton from '../Button/MainButton';
import SecondaryButton from '../Button/SecondaryButton';
import Filter from '../Filter/Filter';
import Input from '../Input/Input';
import Select from '../Select/Select';
import Timepicker from '../Timepicker/Timepicker';
import {
  bedTypes,
  categories,
  petsAllowed,
  smoking,
  restrictedMobility,
  children,
  maleGroups,
  carRental
} from '../../Constants/SelectOptions';
import { useTranslation } from 'react-i18next';
import DecimalInput from '../DecimalInput/DecimalInput';
import { KEYWORD } from '../../Constants/Property';

const FilterPanel = ({ formik }) => {
  const { t } = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSliderChange = (event, newValue) => {
    formik.setValues({
      ...formik.values,
      minPrice: newValue[0],
      maxPrice: newValue[1]
    });
  };

  const handleBlur = () => {
    formik.setTouched({ minPrice: true, maxPrice: true });
  };

  const amenties = {
    livingArea: {
      sofaSet: '',
      armChair: '',
      sofaBed: '',
      doubleSofaBed: '',
      diningTable: '',
      tv: '',
      satelliteTV: '',
      coffeeTable: '',
      airConditioning: ''
    },
    kitchen: {
      crockery: '',
      cutlery: '',
      glassware: '',
      pots: '',
      pans: '',
      diningTable: '',
      oven: '',
      microwave: '',
      fridge: '',
      freezer: '',
      dishWasher: '',
      toaster: '',
      kettle: '',
      kitchenware: '',
      diningArea: ''
    },
    generalFacilities: {
      jacuzzi: '',
      cot: '',
      highChair: '',
      safe: '',
      tumbleDryer: '',
      washingMachine: '',
      towel: '',
      internet: '',
      iron: '',
      WIFI: '',
      hairDyrer: '',
      wardrobe: '',
      makeupTable: '',
      airConditioning: '',
      elevator: '',
      pool: '',
      sauna: '',
      tableTennis: '',
      fireplace: '',
      housekeeping: '',
      heating: ''
    },
    outdoorFacilities: {
      communalSwimmingPool: '',
      privateSwimmingPool: '',
      gardenFurniture: '',
      communalGarden: '',
      privateGarden: '',
      parking: '',
      outsideLighting: '',
      barbecue: '',
      balconyORterrace: '',
      childrenPool: '',
      security: '',
      seaView: '',
      sunsetView: '',
      poolView: '',
      cityView: '',
      mountainView: '',
      seafront: '',
      complex: '',
      parasols: '',
      sunbeds: '',
      publicBeach: '',
      privateBeach: '',
      paidParking: '',
      outdoorDiningTable: ''
    },
    siteAndAttractions: {
      supermarkets: '',
      historicSites: '',
      museum: '',
      bar: '',
      restaurant: '',
      cafeteria: '',
      nightClub: '',
      shoppingCentre: '',
      localFoodMarket: '',
      fishing: '',
      boatTrip: '',
      cycling: '',
      horseRiding: '',
      golf: '',
      fitnessCenterGYM: '',
      SPA: '',
      turkishBath: '',
      tennisCourt: '',
      basketballCourt: '',
      volleyballCourt: ''
    },
    suitabilities: {
      petsAllowed: '',
      smoking: '',
      restrictedMobility: '',
      children: '',
      maleGroups: ''
    },
    carRental: ''
  };

  const selectBoxOptions = {
    petsAllowed,
    smoking,
    restrictedMobility,
    children,
    maleGroups
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ px: '48px' }}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <SecondaryButton color="sand" startIcon={<LocalOffer />}>
            {t('filterPanel.button.reasonablePrice')}
          </SecondaryButton>
          <SecondaryButton color="sand" startIcon={<NewReleases />}>
            {t('filterPanel.button.newests')}
          </SecondaryButton>
          <SecondaryButton color="sand" startIcon={<Star />}>
            {t('filterPanel.button.maxRate')}
          </SecondaryButton>
          <SecondaryButton color="sand" startIcon={<Reviews />}>
            {t('filterPanel.button.maxReview')}
          </SecondaryButton>
        </Stack>
        <Stack>
          <MainButton onClick={handleDrawerOpen} color="header" startIcon={<Tune />}>
            {t('filterPanel.button.title')}
          </MainButton>
        </Stack>
      </Stack>
      <Drawer
        anchor={isSmallScreen ? 'bottom' : 'right'}
        height="50%"
        open={drawerOpen}
        onClose={handleDrawerClose}>
        <Stack
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}>
          <Button autoFocus onClick={handleDrawerClose}>
            <Close />
          </Button>
        </Stack>
        <Box
          sx={{
            width: isSmallScreen ? '100%' : '350px',

            padding: '20px',
            justifyContent: 'center'
          }}>
          <Filter
            filter={{
              title: t('filter.categories')
            }}>
            <Select
              label={t('filter.category')}
              value={formik.values.category}
              onChange={formik.handleChange('category')}
              error={formik.touched.category && Boolean(formik.errors.category)}
              options={categories}
              onBlur={formik.handleBlur}
              size="small"
              sx={{
                mt: 2
              }}
            />
          </Filter>
          <Filter
            filter={{
              title: t('filter.keyword')
            }}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">{t('filter.keyword.input')}</FormLabel>
              <RadioGroup
                row
                name="radio-buttons-group"
                value={formik.values.keyword}
                onChange={formik.handleChange('keyword')}>
                <FormControlLabel
                  value={KEYWORD.SEA}
                  control={<Radio />}
                  label={t('createProperty.filter.sea')}
                />
                <FormControlLabel
                  value={KEYWORD.BEACH}
                  control={<Radio />}
                  label={t('createProperty.filter.beach')}
                />
                <FormControlLabel
                  value={KEYWORD.POOL}
                  control={<Radio />}
                  label={t('createProperty.filter.pool')}
                />
                <FormControlLabel
                  value={KEYWORD.VIEW}
                  control={<Radio />}
                  label={t('createProperty.filter.view')}
                />
                <FormControlLabel
                  value={KEYWORD.LAKE}
                  control={<Radio />}
                  label={t('createProperty.filter.lake')}
                />
                <FormControlLabel
                  value={KEYWORD.ISLAND}
                  control={<Radio />}
                  label={t('createProperty.filter.island')}
                />
                <FormControlLabel
                  value={KEYWORD.BREAKFAST}
                  control={<Radio />}
                  label={t('createProperty.filter.breakfast')}
                />
                <FormControlLabel
                  value={KEYWORD.HISTORIC}
                  control={<Radio />}
                  label={t('createProperty.filter.historic')}
                />
              </RadioGroup>
            </FormControl>
          </Filter>
          <Filter
            filter={{
              title: t('filter.price')
            }}>
            <Slider
              value={[formik.values.minPrice, formik.values.maxPrice]}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={(value) => value}
            />
            <Stack direction="row" spacing={2}>
              <DecimalInput
                label={t('filter.minPrice')}
                value={formik.values.minPrice}
                onChange={formik.handleChange('minPrice')}
                onBlur={handleBlur}
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                  // inputProps: {
                  //   step: 1,
                  //   min: 0,
                  //   max: formik.values.maxPrice - 1
                  // }
                }}
              />
              <DecimalInput
                label={t('filter.maxPrice')}
                value={formik.values.maxPrice}
                onChange={formik.handleChange('maxPrice')}
                onBlur={handleBlur}
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
            </Stack>
          </Filter>
          <Filter
            filter={{
              title: t('filter.checkTimes')
            }}>
            <Stack direction="row" spacing={2} mt={2}>
              <Timepicker
                label={t('filter.checkInTime')}
                id="checkInTime"
                name="checkInTime"
                value={formik.values.checkInTime}
                onChange={(value) => formik.setFieldValue('checkInTime', value)}
                error={formik.touched.checkInTime && Boolean(formik.errors.checkInTime)}
                onBlur={formik.handleBlur}
                size="small"
              />
              <Timepicker
                label={t('filter.checkOutTime')}
                id="checkOutTime"
                name="checkOutTime"
                value={formik.values.checkOutTime}
                onChange={(value) => formik.setFieldValue('checkOutTime', value)}
                error={formik.touched.checkOutTime && Boolean(formik.errors.checkOutTime)}
                onBlur={formik.handleBlur}
                size="small"
              />
            </Stack>
          </Filter>
          <Filter
            filter={{
              title: t('filter.address')
            }}>
            <Stack spacing={2} mt={2}>
              <Input
                label={t('filter.address.country')}
                value={formik.values.country}
                onChange={formik.handleChange('country')}
                error={formik.touched.country && Boolean(formik.errors.country)}
                onBlur={formik.handleBlur}
                size="small"
              />
              <Input
                label={t('filter.address.city')}
                value={formik.values.city}
                onChange={formik.handleChange('city')}
                error={formik.touched.city && Boolean(formik.errors.city)}
                onBlur={formik.handleBlur}
                size="small"
              />
              <Input
                label={t('filter.address.district')}
                value={formik.values.district}
                onChange={formik.handleChange('district')}
                error={formik.touched.district && Boolean(formik.errors.district)}
                onBlur={formik.handleBlur}
                size="small"
              />
              <Input
                label={t('filter.address.street')}
                value={formik.values.street}
                onChange={formik.handleChange('street')}
                error={formik.touched.street && Boolean(formik.errors.street)}
                onBlur={formik.handleBlur}
                size="small"
              />
            </Stack>
          </Filter>
          <Filter
            filter={{
              title: t('filter.basicFeatures')
            }}>
            <Stack spacing={2} mt={2}>
              <DecimalInput
                label={t('filter.basicFeatures.maxTraveler')}
                value={formik.values.maxTraveler}
                onChange={formik.handleChange('maxTraveler')}
                error={formik.touched.street && Boolean(formik.errors.maxTraveler)}
                onBlur={formik.handleBlur}
                size="small"
              />
              <DecimalInput
                label={t('filter.basicFeatures.bedroom')}
                value={formik.values.bedroom}
                onChange={formik.handleChange('bedroom')}
                error={formik.touched.bedroom && Boolean(formik.errors.bedroom)}
                onBlur={formik.handleBlur}
                size="small"
              />
              <Select
                label={t('filter.basicFeatures.bed')}
                value={formik.values.bed}
                onChange={formik.handleChange('bed')}
                error={formik.touched.bed && Boolean(formik.errors.bed)}
                options={bedTypes}
                onBlur={formik.handleBlur}
                size="small"
              />
              <DecimalInput
                label={t('filter.basicFeatures.bathroom')}
                value={formik.values.bathroom}
                onChange={formik.handleChange('bathroom')}
                error={formik.touched.bathroom && Boolean(formik.errors.bathroom)}
                onBlur={formik.handleBlur}
                size="small"
              />
              <FormControlLabel
                label={t('filter.basicFeatures.isBathShared')}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={formik.values.isBathShared}
                    onChange={formik.handleChange('isBathShared')}
                  />
                }
              />
            </Stack>
          </Filter>
          <Filter
            filter={{
              title: t('filter.amenties')
            }}>
            <Box mt={2}>
              <Accordion expanded={expanded === 'livingArea'} onChange={handleChange('livingArea')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="livingArea-content"
                  id="livingArea-header">
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {t('filter.amenties.livingArea')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                    {Object.keys(amenties.livingArea).map((option, index) => (
                      <FormControlLabel
                        key={index}
                        label={t(`filter.amenties.livingArea.${option}`)}
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={formik.values.livingArea.option}
                            onChange={formik.handleChange(`livingArea.${option}`)}
                          />
                        }
                      />
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'kitchen'} onChange={handleChange('kitchen')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="kitchen-content"
                  id="kitchen-header">
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {t('filter.amenties.kitchen')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                    {Object.keys(amenties.kitchen).map((option, index) => (
                      <FormControlLabel
                        key={index}
                        label={t(`filter.amenties.kitchen.${option}`)}
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={formik.values.kitchen.option}
                            onChange={formik.handleChange(`kitchen.${option}`)}
                          />
                        }
                      />
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'generalFacilities'}
                onChange={handleChange('generalFacilities')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="generalFacilities-content"
                  id="generalFacilities-header">
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {t('filter.amenties.generalFacilities')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                    {Object.keys(amenties.generalFacilities).map((option, index) => (
                      <FormControlLabel
                        key={index}
                        label={t(`filter.amenties.generalFacilities.${option}`)}
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={formik.values.generalFacilities.option}
                            onChange={formik.handleChange(`generalFacilities.${option}`)}
                          />
                        }
                      />
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'outdoorFacilities'}
                onChange={handleChange('outdoorFacilities')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="outdoorFacilities-content"
                  id="outdoorFacilities-header">
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {t('filter.amenties.outdoorFacilities')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                    {Object.keys(amenties.outdoorFacilities).map((option, index) => (
                      <FormControlLabel
                        key={index}
                        label={t(`filter.amenties.outdoorFacilities.${option}`)}
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={formik.values.outdoorFacilities.option}
                            onChange={formik.handleChange(`outdoorFacilities.${option}`)}
                          />
                        }
                      />
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'siteAndAttractions'}
                onChange={handleChange('siteAndAttractions')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="siteAndAttractions-content"
                  id="siteAndAttractions-header">
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {t('filter.amenties.siteAndAttractions')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                    {Object.keys(amenties.siteAndAttractions).map((option, index) => (
                      <FormControlLabel
                        key={index}
                        label={t(`filter.amenties.siteAndAttractions.${option}`)}
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={formik.values.siteAndAttractions.option}
                            onChange={formik.handleChange(`siteAndAttractions.${option}`)}
                          />
                        }
                      />
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'suitabilities'}
                onChange={handleChange('suitabilities')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="suitabilities-content"
                  id="suitabilities-header">
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {t('filter.amenties.suitabilities')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2}>
                    {Object.keys(amenties.suitabilities).map((option, index) => (
                      <Select
                        key={index}
                        label={t(`filter.amenties.suitabilities.${option}`)}
                        onChange={formik.handleChange(`suitabilities.${option}`)}
                        error={formik.touched.option && Boolean(formik.errors.option)}
                        options={selectBoxOptions[option]}
                        onBlur={formik.handleBlur}
                        size="small"
                      />
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'carRental'} onChange={handleChange('carRental')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="carRental-content"
                  id="carRental-header">
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {t('filter.amenties.carRental')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Select
                    label={t('filter.amenties.carRental')}
                    onChange={formik.handleChange('carRental')}
                    error={formik.touched.carRental && Boolean(formik.errors.carRental)}
                    options={carRental}
                    onBlur={formik.handleBlur}
                    size="small"
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Filter>
          <MainButton
            onClick={() => {
              formik.handleSubmit();
              handleDrawerClose();
            }}>
            <Tune /> {t('filter.button.title')}
          </MainButton>
        </Box>
      </Drawer>
    </Box>
  );
};

export default FilterPanel;
