import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Typography,
  Stepper as StepperComponent,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  StepButton,
  Stack
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import SecondaryButton from '../../Components/Button/SecondaryButton';
import { useLocation } from 'react-router-dom';

const Stepper = ({ steps, formik }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [completed, setCompleted] = useState({});

  const { pathname } = useLocation();

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)'
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1
    }
  }));

  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.primary.main
    }),
    '& .QontoStepIcon-completedIcon': {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 18
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor'
    }
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const isStepOptional = (step) => {
    return step.optional === true;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleComplete = async () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box m={3}>
      <StepperComponent
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<QontoConnector />}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(step)) {
            labelProps.optional = (
              <Typography variant="caption">{t('stepper.optional')}</Typography>
            );
          }
          if (isStepSkipped(step)) {
            stepProps.completed = false;
          }
          return (
            <Step key={index} {...stepProps} completed={completed[index]}>
              <StepButton onClick={handleStep(index)}>
                <StepLabel StepIconComponent={QontoStepIcon} {...labelProps}>
                  {t(`stepper.step.${step.label}`)}
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </StepperComponent>
      {activeStep === steps.length ? (
        <Box>{t('stepper.step.finish')}</Box>
      ) : (
        <>
          <Box sx={{ width: '100%', p: 2 }}>{steps[activeStep].body}</Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mx: 2 }}>
            <SecondaryButton
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}>
              {t('stepper.button.back')}
            </SecondaryButton>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <SecondaryButton color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                {t('stepper.button.skip')}
              </SecondaryButton>
            )}
            {activeStep === steps.length - 1 ? (
              <SecondaryButton onClick={formik.handleSubmit}>
                {pathname.includes('create-property')
                  ? t('stepper.button.finish')
                  : t('common.update')}
              </SecondaryButton>
            ) : (
              <Stack direction="row" spacing={2}>
                <SecondaryButton onClick={handleNext}>{t('stepper.button.next')}</SecondaryButton>
                <SecondaryButton onClick={handleComplete}>
                  {t('stepper.button.complete')}
                </SecondaryButton>
              </Stack>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Stepper;
