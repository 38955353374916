import React from 'react';
import { Box, Grid } from '@mui/material';
import CategoryCard from '../../Components/CategoryCard/CategoryCard';
import { useTranslation } from 'react-i18next';
import { categories } from '../../Constants/Property';

const Categories = () => {
  const { t } = useTranslation();

  return (
    <Box my={5}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }}>
        {categories(t).map((category, index) => (
          <Grid item xs={2} sm={3} md={3} key={index}>
            <CategoryCard key={index} category={category} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Categories;
