import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  InputAdornment,
  IconButton
} from '@mui/material';
import Header from '../Header/Header';
import Input from '../Input/Input';
import MainButton from '../Button/MainButton';
import TertiaryButton from '../Button/TertiaryButton';
import BasicDatePicker from '../Datepicker/Datepicker';
import registerSchema from '../../Utils/Schema/RegisterValidation';
import { useFormik } from 'formik';
import { register } from '../../Utils/Auth';
import { useTranslation } from 'react-i18next';
import ErrorPopup from '../../Modules/ErrorPopup';
import StateAlert from '../StateAlert/StateAlert';
import { Check, Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../Contexts/AuthContext';

const Signup = () => {
  const { t } = useTranslation();
  const { setIsLoginOpen, setIsModalOpen } = useAuth();

  const [successModal, setSuccessModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);

  const loginOpen = () => {
    setIsLoginOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      trIdentityNo: '',
      birthDay: null,
      password: '',
      passwordAgain: '',
      isHost: false
    },
    validationSchema: registerSchema,
    onSubmit: async (values, bag) => {
      try {
        await register({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          trIdentityNo: values.trIdentityNo,
          birthDay: values.birthDay,
          password: values.password,
          passwordAgain: values.passwordAgain,
          isHost: values.isHost
        }).then((res) => {
          if (res) {
            bag.resetForm();
            setSuccessModal(true);
            setIsLoginOpen(true);
            closeModal();
          }
        });
      } catch (error) {
        bag.setErrors({ general: error.response.data.data });
      }
    }
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Dialog open={true} onClose={closeModal}>
        <DialogTitle>
          <Header>{t('page.signup')}</Header>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {formik.errors.general && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.errors.general}</Alert>
              </Grid>
            )}
            <Grid item xs={6}>
              <Input
                label={t('signup.input.firstName')}
                onChange={formik.handleChange('firstName')}
                value={formik.values.firstName}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label={t('signup.input.lastName')}
                onChange={formik.handleChange('lastName')}
                value={formik.values.lastName}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label={t('signup.input.mail')}
                onChange={formik.handleChange('email')}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label={t('signup.input.phoneNumber')}
                onChange={formik.handleChange('phoneNumber')}
                value={formik.values.phoneNumber}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label={t('signup.input.trIdentityNo')}
                onChange={formik.handleChange('trIdentityNo')}
                value={formik.values.trIdentityNo}
                error={formik.touched.trIdentityNo && Boolean(formik.errors.trIdentityNo)}
              />
            </Grid>
            <Grid item xs={6}>
              <BasicDatePicker
                label={t('signup.input.birthday')}
                name="birthDay"
                value={formik.values.birthDay}
                onChange={(date) => formik.setFieldValue('birthDay', date)}
                onBlur={formik.handleBlur}
                error={formik.touched.birthDay && Boolean(formik.errors.birthDay)}
                disableFuture
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label={t('signup.input.password')}
                onChange={formik.handleChange('password')}
                value={formik.values.password}
                error={formik.touched.password && Boolean(formik.errors.password)}
                inputProps={{ maxLength: 25 }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        onMouseUp={(event) => {
                          event.preventDefault();
                        }}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label={t('signup.input.passwordAgain')}
                onChange={formik.handleChange('passwordAgain')}
                value={formik.values.passwordAgain}
                error={formik.touched.passwordAgain && Boolean(formik.errors.passwordAgain)}
                inputProps={{ maxLength: 25 }}
                type={showPasswordAgain ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPasswordAgain((show) => !show)}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        onMouseUp={(event) => {
                          event.preventDefault();
                        }}
                        edge="end">
                        {showPasswordAgain ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.isHost}
                    name="toggle"
                    onChange={formik.handleChange('isHost')}
                  />
                }
                label={[
                  t('signup.input.wantedToHost'),
                  <TertiaryButton key="">{t('signup.button.detail')}</TertiaryButton>
                ]}
              />
              <FormControlLabel
                control={<Checkbox />}
                label={[
                  t('signup.input.agree'),
                  <TertiaryButton key="">{t('signup.button.policy')}</TertiaryButton>
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <MainButton type="submit" onClick={formik.handleSubmit}>
                {t('signup.button.title')}
              </MainButton>
            </Grid>
            <Grid item xs={12}>
              {t('signup.button.haveAnAccount')}
              <TertiaryButton color="primary" onClick={loginOpen}>
                {t('login.button.title')}
              </TertiaryButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ErrorPopup formik={formik} />
      <StateAlert
        open={successModal}
        autoHideDuration={3000}
        handleClose={() => setSuccessModal(false)}
        severity="success"
        alertTitle={t('stateAlert.title.success')}
        alertText={t('stateAlert.text.registerSuccess')}
        buttonText={t('common.close')}
        icon={<Check fontSize="inherit" />}
      />
    </>
  );
};

export default Signup;
