import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add, ExpandMore, Remove } from '@mui/icons-material';
import Select from '../../Components/Select/Select';
import {
  petsAllowed,
  smoking,
  restrictedMobility,
  children,
  maleGroups,
  carRental
} from '../../Constants/SelectOptions';
import Timepicker from '../../Components/Timepicker/Timepicker';
import Input from '../../Components/Input/Input';
import TertiaryButton from '../../Components/Button/TertiaryButton';
import dayjs from 'dayjs';

const Other = ({ formik }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const amenties = {
    livingArea: {
      sofaSet: '',
      armChair: '',
      sofaBed: '',
      doubleSofaBed: '',
      diningTable: '',
      tv: '',
      satelliteTV: '',
      coffeeTable: '',
      airConditioning: ''
    },
    kitchen: {
      crockery: '',
      cutlery: '',
      glassware: '',
      pots: '',
      pans: '',
      diningTable: '',
      oven: '',
      microwave: '',
      fridge: '',
      freezer: '',
      dishWasher: '',
      toaster: '',
      kettle: '',
      kitchenware: '',
      diningArea: ''
    },
    generalFacilities: {
      jacuzzi: '',
      cot: '',
      highChair: '',
      safe: '',
      tumbleDryer: '',
      washingMachine: '',
      towel: '',
      internet: '',
      iron: '',
      WIFI: '',
      hairDyrer: '',
      wardrobe: '',
      makeupTable: '',
      airConditioning: '',
      elevator: '',
      pool: '',
      sauna: '',
      tableTennis: '',
      fireplace: '',
      housekeeping: '',
      heating: ''
    },
    outdoorFacilities: {
      communalSwimmingPool: '',
      privateSwimmingPool: '',
      gardenFurniture: '',
      communalGarden: '',
      privateGarden: '',
      parking: '',
      outsideLighting: '',
      barbecue: '',
      balconyORterrace: '',
      childrenPool: '',
      security: '',
      seaView: '',
      sunsetView: '',
      poolView: '',
      cityView: '',
      mountainView: '',
      seafront: '',
      complex: '',
      parasols: '',
      sunbeds: '',
      publicBeach: '',
      privateBeach: '',
      paidParking: '',
      outdoorDiningTable: ''
    },
    siteAndAttractions: {
      supermarkets: '',
      historicSites: '',
      museum: '',
      bar: '',
      restaurant: '',
      cafeteria: '',
      nightClub: '',
      shoppingCentre: '',
      localFoodMarket: '',
      fishing: '',
      boatTrip: '',
      cycling: '',
      horseRiding: '',
      golf: '',
      fitnessCenterGYM: '',
      SPA: '',
      turkishBath: '',
      tennisCourt: '',
      basketballCourt: '',
      volleyballCourt: ''
    },
    suitabilities: {
      petsAllowed: '',
      smoking: '',
      restrictedMobility: '',
      children: '',
      maleGroups: ''
    },
    carRental: ''
  };

  const selectBoxOptions = {
    petsAllowed,
    smoking,
    restrictedMobility,
    children,
    maleGroups
  };

  const [transportation, setTransportationText] = useState('');
  const [entertainment, setEntertainmentText] = useState('');
  const [foodDrink, setFoodDrinkText] = useState('');
  const [places, setPlacesText] = useState('');

  const handleNearby = (nearby) => {
    let item = '';
    switch (nearby) {
      case 'transportation':
        item = transportation;
        setTransportationText('');
        break;
      case 'entertainment':
        item = entertainment;
        setEntertainmentText('');
        break;
      case 'foodDrink':
        item = foodDrink;
        setFoodDrinkText('');
        break;
      case 'places':
        item = places;
        setPlacesText('');
        break;
      default:
        break;
    }

    if (item === '' && !formik.values[nearby].includes(item)) {
      return;
    }

    formik.setFieldValue(nearby, [...formik.values[nearby], item]);
  };

  const handleDeleteNearby = (nearby, itemToRemove) => {
    const newArray = formik.values[nearby].filter((item, i) => i !== itemToRemove);

    formik.setFieldValue(nearby, newArray);
  };

  return (
    <Box gap={3} mb={2}>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 'bold',
          fontSize: '32px'
        }}
        gutterBottom>
        {t('stepper.step.other')}
      </Typography>
      <Stack direction="row" spacing={2} my={2}>
        <Timepicker
          label={t('createProperty.input.checkInTime')}
          id="checkInTime"
          name="checkInTime"
          value={formik.values.amenties?.checkInTime}
          onChange={(value) => formik.setFieldValue('amenties.checkInTime', value)}
          error={
            formik.touched.amenties?.checkInTime && Boolean(formik.errors.amenties?.checkInTime)
          }
          onBlur={formik.handleBlur('amenties.checkInTime')}
          required
        />
        <Timepicker
          label={t('createProperty.input.checkOutTime')}
          id="checkOutTime"
          name="checkOutTime"
          value={formik.values.amenties?.checkOutTime}
          onChange={(value) => formik.setFieldValue('amenties.checkOutTime', value)}
          error={
            formik.touched.amenties?.checkOutTime && Boolean(formik.errors.amenties?.checkOutTime)
          }
          onBlur={formik.handleBlur('amenties.checkOutTime')}
          required
        />
      </Stack>
      <Stack mb={2}>
        <Typography
          sx={{
            color: 'header.main',
            fontWeight: 600,
            textAlign: 'start',
            fontSize: '16px'
          }}
          variant="subtitle1">
          {t('createProperty.nearby.title')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: 'inputPlaceholder.main',
            fontSize: '14px'
          }}>
          {t('createProperty.nearby.description')}
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <TextField
            variant="filled"
            fullWidth
            label={t('createProperty.input.transportation')}
            onChange={(e) => setTransportationText(e.target.value)}
            value={transportation}
            InputProps={{
              endAdornment: (
                <TertiaryButton onClick={() => handleNearby('transportation')}>
                  <Add />
                </TertiaryButton>
              )
            }}
          />
          <List dense={true}>
            {formik?.values?.transportation.map((transportation, index) => (
              <ListItem
                key={index}
                sx={{ display: 'list-item', wordBreak: 'break-word' }}
                secondaryAction={
                  <TertiaryButton onClick={() => handleDeleteNearby('transportation', index)}>
                    <Remove />
                  </TertiaryButton>
                }>
                <ListItemText
                  primary={transportation}
                  sx={{
                    wordBreak: 'break-word'
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            variant="filled"
            fullWidth
            label={t('createProperty.input.entertainment')}
            onChange={(e) => setEntertainmentText(e.target.value)}
            value={entertainment}
            InputProps={{
              endAdornment: (
                <TertiaryButton onClick={() => handleNearby('entertainment')}>
                  <Add />
                </TertiaryButton>
              )
            }}
          />
          <List dense={true}>
            {formik?.values?.entertainment.map((entertainment, index) => (
              <ListItem
                key={index}
                sx={{ display: 'list-item', wordBreak: 'break-word' }}
                secondaryAction={
                  <TertiaryButton onClick={() => handleDeleteNearby('entertainment', index)}>
                    <Remove />
                  </TertiaryButton>
                }>
                <ListItemText
                  primary={entertainment}
                  sx={{
                    wordBreak: 'break-word'
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            variant="filled"
            fullWidth
            label={t('createProperty.input.foodDrink')}
            onChange={(e) => setFoodDrinkText(e.target.value)}
            value={foodDrink}
            InputProps={{
              endAdornment: (
                <TertiaryButton onClick={() => handleNearby('foodDrink')}>
                  <Add />
                </TertiaryButton>
              )
            }}
          />
          <List dense={true}>
            {formik?.values?.foodDrink.map((foodDrink, index) => (
              <ListItem
                key={index}
                sx={{ display: 'list-item', wordBreak: 'break-word' }}
                secondaryAction={
                  <TertiaryButton onClick={() => handleDeleteNearby('foodDrink', index)}>
                    <Remove />
                  </TertiaryButton>
                }>
                <ListItemText
                  primary={foodDrink}
                  sx={{
                    wordBreak: 'break-word'
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            variant="filled"
            fullWidth
            label={t('createProperty.input.places')}
            onChange={(e) => setPlacesText(e.target.value)}
            value={places}
            InputProps={{
              endAdornment: (
                <TertiaryButton onClick={() => handleNearby('places')}>
                  <Add />
                </TertiaryButton>
              )
            }}
          />
          <List dense={true}>
            {formik?.values?.places.map((place, index) => (
              <ListItem
                key={index}
                sx={{ display: 'list-item', wordBreak: 'break-word' }}
                secondaryAction={
                  <TertiaryButton onClick={() => handleDeleteNearby('places', index)}>
                    <Remove />
                  </TertiaryButton>
                }>
                <ListItemText
                  primary={place}
                  sx={{
                    wordBreak: 'break-word'
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 600,
          textAlign: 'start',
          fontSize: '16px',
          mb: 2
        }}
        variant="subtitle1">
        {t('createProperty.amenties.title')}
      </Typography>
      <Accordion expanded={expanded === 'livingArea'} onChange={handleChange('livingArea')}>
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="livingArea-content"
          id="livingArea-header">
          <Typography sx={{ width: '33%', flexShrink: 0, color: 'primary.main' }}>
            {t('createProperty.livingArea')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {Object.keys(amenties.livingArea).map((option, index) => (
              <FormControlLabel
                key={index}
                label={t(`createProperty.input.livingArea.${option}`)}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={formik.values.amenties.livingArea?.[option]}
                    onChange={formik.handleChange(`amenties.livingArea.${option}`)}
                  />
                }
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'kitchen'} onChange={handleChange('kitchen')}>
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="kitchen-content"
          id="kitchen-header">
          <Typography sx={{ width: '33%', flexShrink: 0, color: 'primary.main' }}>
            {t('createProperty.kitchen')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {Object.keys(amenties.kitchen).map((option, index) => (
              <FormControlLabel
                key={index}
                label={t(`createProperty.input.kitchen.${option}`)}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={formik.values.amenties.kitchen?.[option]}
                    onChange={formik.handleChange(`amenties.kitchen.${option}`)}
                  />
                }
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'generalFacilities'}
        onChange={handleChange('generalFacilities')}>
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="generalFacilities-content"
          id="generalFacilities-header">
          <Typography sx={{ width: '33%', flexShrink: 0, color: 'primary.main' }}>
            {t('createProperty.generalFacilities')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {Object.keys(amenties.generalFacilities).map((option, index) => (
              <FormControlLabel
                key={index}
                label={t(`createProperty.input.generalFacilities.${option}`)}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={formik.values.amenties.generalFacilities?.[option]}
                    onChange={formik.handleChange(`amenties.generalFacilities.${option}`)}
                  />
                }
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'outdoorFacilities'}
        onChange={handleChange('outdoorFacilities')}>
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="outdoorFacilities-content"
          id="outdoorFacilities-header">
          <Typography sx={{ width: '33%', flexShrink: 0, color: 'primary.main' }}>
            {t('createProperty.outdoorFacilities')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {Object.keys(amenties.outdoorFacilities).map((option, index) => (
              <FormControlLabel
                key={index}
                label={t(`createProperty.input.outdoorFacilities.${option}`)}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={formik.values.amenties.outdoorFacilities?.[option]}
                    onChange={formik.handleChange(`amenties.outdoorFacilities.${option}`)}
                  />
                }
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'siteAndAttractions'}
        onChange={handleChange('siteAndAttractions')}>
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="siteAndAttractions-content"
          id="siteAndAttractions-header">
          <Typography sx={{ width: '33%', flexShrink: 0, color: 'primary.main' }}>
            {t('createProperty.siteAndAttractions')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {Object.keys(amenties.siteAndAttractions).map((option, index) => (
              <FormControlLabel
                key={index}
                label={t(`createProperty.input.siteAndAttractions.${option}`)}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={formik.values.amenties.siteAndAttractions?.[option]}
                    onChange={formik.handleChange(`amenties.siteAndAttractions.${option}`)}
                  />
                }
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'suitabilities'} onChange={handleChange('suitabilities')}>
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="suitabilities-content"
          id="suitabilities-header">
          <Typography sx={{ width: '33%', flexShrink: 0, color: 'primary.main' }}>
            {t('createProperty.suitabilities')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            {Object.keys(amenties.suitabilities).map((option, index) => (
              <Select
                key={index}
                value={formik.values.amenties.suitabilities?.[option]}
                label={t(`createProperty.input.suitabilities.${option}`)}
                onChange={formik.handleChange(`amenties.suitabilities.${option}`)}
                error={
                  formik.touched.amenties?.suitabilities?.option &&
                  Boolean(formik.errors.amenties?.suitabilities[option])
                }
                options={selectBoxOptions[option]}
                onBlur={formik.handleBlur(`amenties.suitabilities.${option}`)}
                size="small"
                required
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'carRental'} onChange={handleChange('carRental')}>
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="carRental-content"
          id="carRental-header">
          <Typography sx={{ width: '33%', flexShrink: 0, color: 'primary.main' }}>
            {t('createProperty.carRental')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            label={t('createProperty.input.carRental')}
            value={formik.values.amenties.carRental}
            onChange={formik.handleChange('amenties.carRental')}
            onBlur={formik.handleBlur('amenties.carRental')}
            error={formik.touched.amenties?.carRental && Boolean(formik.errors.amenties?.carRental)}
            options={carRental}
            size="small"
            required
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Other;
