import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation, keepPreviousData } from '@tanstack/react-query';
import axios from 'axios';
import StateAlert from '../Components/StateAlert/StateAlert';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ApiErrorPopup from '../Modules/ApiErrorPopup';
import { routes } from '../Routes/Routes';

export const RequestContext = createContext(null);

const RequestProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('x-access-token'));
  const [errorHeader, setErrorHeader] = useState('');
  const [errorBody, setErrorBody] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [alertText, setAlertText] = useState('');

  const { t } = useTranslation();

  const handleToken = (tokenData) => {
    if (tokenData) {
      localStorage.setItem('x-access-token', tokenData);
      setToken(tokenData);
    }
  };

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: process.env.REACT_APP_BOOKING_SERVICE_PATH,
        headers: {
          'Content-Type': 'application/json',
          // canlıda kaldırılmalı
          Accept: '*/*',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': token
        }
      }),
    [token]
  );

  useEffect(() => {
    axiosInstance.interceptors.request.use((config) => {
      setIsLoading(true);
      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        setIsLoading(false);
        return response?.data?.data ?? response?.data ?? response;
      },
      (error) => {
        if (error.response) {
          setIsLoading(false);
        } else if (error.request) {
          console.log('Request Error:', error.request);
        } else {
          console.log('Error:', error.message);
        }

        if (error?.response?.data) {
          setErrorBody(error?.response?.data?.code || error?.response?.data?.description);
        }
      }
    );
  }, [axiosInstance]);

  useEffect(() => {
    // token yoksa ve yetki ile girilecek sayfalardan birine gidilmeye çalışılıyorsa anasayfaya yönlendir
    if (!token) {
      const isMatchingRoute = routes.some((route) => {
        const pathRegex = new RegExp(`^${route.path.replace(/:\w+/g, '\\w+')}$`);
        return pathRegex.test(location.pathname);
      });

      if (isMatchingRoute) {
        location.href = '/';
      }
    } else {
      handleToken(token);
    }
  }, []);

  const filterNestedParams = (params) => {
    const filteredParams = {};
    for (const key in params) {
      const value = params[key];
      if (typeof value === 'object') {
        const nestedParams = filterNestedParams(value);
        if (Object.keys(nestedParams).length > 0) {
          filteredParams[key] = nestedParams;
        }
      } else if (value !== '' && value !== null && value !== undefined && value !== false) {
        filteredParams[key] = value;
      }
    }
    return filteredParams;
  };

  const useGetDataWithPagination = (
    queryKey,
    endpoint,
    options = {},
    queryParams = {},
    page = 0
  ) => {
    const filteredQueryParams = filterNestedParams(queryParams);

    const { isPending, isFetching, isSuccess, isError, error, data, isPlaceholderData, refetch } =
      useQuery({
        queryKey: [queryKey, page],
        queryFn: async () => {
          const response = await axiosInstance.get(endpoint, {
            params: { page, ...filteredQueryParams }
          });

          return response?.data?.data ?? response?.data ?? response;
        },
        placeholderData: keepPreviousData
      });

    useEffect(() => {
      setIsLoading(options.enabled ? isFetching : false);
    }, [isFetching]);

    useEffect(() => {
      if (isSuccess) {
        setIsLoading(false);
      }
    }, [isSuccess]);

    return {
      isError,
      data,
      error,
      isPlaceholderData,
      refetch
    };
  };

  const useGetData = (queryKey, endpoint, options = {}, queryParams = {}) => {
    const filteredQueryParams = filterNestedParams(queryParams);

    const { isPending, isFetching, isSuccess, isError, data, error, refetch } = useQuery({
      queryKey: [queryKey],
      queryFn: async () => {
        const response = await axiosInstance.get(endpoint, {
          params: filteredQueryParams
        });

        return response?.data?.data ?? response?.data ?? response;
      },
      ...options
    });

    useEffect(() => {
      setIsLoading(options.enabled ? isFetching : false);
    }, [isFetching]);

    useEffect(() => {
      if (isSuccess) {
        setIsLoading(false);
      }
    }, [isSuccess]);

    return {
      isError,
      data,
      error,
      refetch
    };
  };

  const createData = useMutation({
    mutationFn: ({ endpoint, body }) => {
      return axiosInstance.post(endpoint, body);
    },
    onSuccess: (data) => {
      if (data) {
        setIsLoading(false);
        setSuccessModal(true);
        setAlertText(t('stateAlert.text.savedSuccess'));
      }
    },
    onError: (error) => {
      setErrorBody(error.response.data.data);
      setErrorHeader(error.response.data.message);
    },
    onMutate: () => {
      setIsLoading(true);
    }
  });

  const updateData = useMutation({
    mutationFn: ({ endpoint, body }) => {
      return axiosInstance.put(endpoint, body);
    },
    onSuccess: (data) => {
      if (data) {
        setIsLoading(false);
        setSuccessModal(true);
        setAlertText(t('stateAlert.text.updateSuccess'));
      }
    },
    onError: (error) => {
      setErrorBody(error.response.data.data);
      setErrorHeader(error.response.data.message);
    },
    onMutate: () => {
      setIsLoading(true);
    }
  });

  const deleteData = useMutation({
    mutationFn: ({ endpoint }) => {
      return axiosInstance.delete(endpoint);
    },
    onSuccess: (data) => {
      if (data) {
        setIsLoading(false);
        setSuccessModal(true);
        setAlertText(t('stateAlert.text.deleteSuccess'));
      }
    },
    onError: (error) => {
      setErrorBody(error.response.data.data);
      setErrorHeader(error.response.data.message);
    },
    onMutate: () => {
      setIsLoading(true);
    }
  });

  const values = {
    token,
    handleToken,
    useGetDataWithPagination,
    useGetData,
    createData,
    updateData,
    deleteData,
    errorHeader,
    errorBody,
    isLoading
  };

  return (
    <RequestContext.Provider value={values}>
      {children}
      <StateAlert
        open={successModal}
        autoHideDuration={1000}
        handleClose={() => setSuccessModal(false)}
        severity="success"
        alertTitle={t('stateAlert.title.success')}
        alertText={alertText}
        buttonText={t('common.close')}
        icon={<Check fontSize="inherit" />}
      />
      <ApiErrorPopup />
    </RequestContext.Provider>
  );
};

export default RequestProvider;
