import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Review from '../../Components/Review/Review';

const reviews = [
  {
    user: 'Beyza Erkan',
    title: 'Excellent hotel, good quiet location.',
    text: 'Very good hotel, in a safe quiet location, helpful staff, especially Yunnus in reception, was a credit to the establishment, clean room, brilliant breakfast, will definitely stay.',
    rate: 4,
    property: 'Siesta Garden',
    commentedDate: Date(),
    dateRange: '27.11.2016 - 04.12.2016'
  },
  {
    user: 'Alihan Saraç',
    title: 'Excellent hotel, good quiet location.',
    text: 'Very good hotel, in a safe quiet location, helpful staff, especially Yunnus in reception, was a credit to the establishment, clean room, brilliant breakfast, will definitely stay there again when next in the area.',
    rate: 4,
    property: 'Apart X',
    commentedDate: Date(),
    dateRange: '03.07.2018 - 10.07.2018'
  }
];

const Reviews = () => {
  const { t } = useTranslation();

  return (
    <Box id="reviews" gap={3} mb={2}>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 600,
          textAlign: 'start',
          fontSize: '16px',
          mb: 2
        }}
        variant="subtitle1">
        {t('propertyDetail.reviews.title')}
      </Typography>
      <Box sx={{ width: '100%' }}>
        {reviews.map((r, index) => (
          <Review key={index} review={r} showPropertyName={false} />
        ))}
      </Box>
    </Box>
  );
};

export default Reviews;
