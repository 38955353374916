import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const Loading = ({ loading }) => {
  return loading ? (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
      <CircularProgress color="primary" />
    </Backdrop>
  ) : (
    <></>
  );
};

export default Loading;
