import * as React from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import MainButton from '../Button/MainButton';
import { useTranslation } from 'react-i18next';
import HeaderWithLocation from '../HeaderWithLocation/HeaderWithLocation';
import dayjs from 'dayjs';

const PaymentCard = ({ property, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ maxWidth: '100%' }}>
      <CardMedia
        component="img"
        alt={property.title}
        image={property.imageUrl}
        sx={{
          objectFit: 'cover',
          width: '100%',
          height: '300px'
        }}
      />
      <CardContent>
        <Box
          sx={{
            px: 3
          }}>
          <HeaderWithLocation
            location={property.location}
            header={property.title}
            style={{
              m: 0
            }}
          />
          <Typography
            component="div"
            sx={{
              fontWeight: 500,
              m: 1,
              fontSize: '14px'
            }}>
            Apart{' '}
            <Box
              fontWeight="fontWeightMedium"
              display="inline"
              sx={{
                fontWeight: 100
              }}>
              for {property?.guest} guest
            </Box>{' '}
            {property?.checkInDate ? dayjs(property?.checkInDate).format('DD/MM/YYYY') : ''} to{' '}
            {property?.checkOutDate ? dayjs(property?.checkOutDate).format('DD/MM/YYYY') : ''}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            mx: 4,
            my: 2,
            fontWeight: 600,
            fontSize: '14px',
            color: '#555555'
          }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography>{property.price} x 7 nights</Typography>
            <Typography>$406</Typography>
          </Stack>
          {property?.cleaningFee > 0 && (
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography>{t('createProperty.input.cleaningFee')}</Typography>
              <Typography>$20</Typography>
            </Stack>
          )}
          {property?.additionalGuest > 0 && (
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography>{t('createProperty.input.additionalGuest')}</Typography>
              <Typography>$15</Typography>
            </Stack>
          )}
          {property?.securityDeposit > 0 && (
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography>{t('createProperty.input.securityDeposit')}</Typography>
              <Typography>$288</Typography>
            </Stack>
          )}
        </Box>
        <Divider />
        <Box
          sx={{
            mt: 2,
            mx: 4,
            fontWeight: 600,
            fontSize: '14px',
            color: '#555555'
          }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography>{t('paymentCard.total')}</Typography>
            <Typography>$15</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography fontWeight={800}>{t('paymentCard.amountPaying')}</Typography>
            <Typography fontWeight={800}>$288</Typography>
          </Stack>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          p: 3
        }}>
        <MainButton onClick={handleSubmit}>{t('paymentCard.button.title')}</MainButton>
      </CardActions>
    </Card>
  );
};

export default PaymentCard;
