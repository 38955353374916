import React from 'react';
import './AvailabilityCalendar.css';
import { InfiniteCalendar, getFreeTimeOfDate } from '@demark-pro/react-booking-calendar';
import { endOfDay, format, startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { tr, enUS } from 'date-fns/locale';

const AvailabilityCalendar = ({
  variant = 'booking',
  rangeMode = true,
  selectedDates,
  handleChange,
  reserved
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const titles = {
    footerStart: t('availabilityCalendar.footerStart'),
    footerEnd: t('availabilityCalendar.footerEnd'),
    footerReserved: t('availabilityCalendar.footerReserved')
  };

  const hostVariant = (date, reserved, isSelectedStart, isSelectedEnd, isReserved) => {
    if (isSelectedStart || isSelectedEnd) {
      return titles[isSelectedStart ? 'footerStart' : 'footerEnd'];
    }
    if (isReserved) {
      return titles.footerReserved;
    }

    const freeTime = getFreeTimeOfDate(date, reserved);
    const timeFormat = 'HH:mm';
    const startToFormat = format(freeTime.startDate, timeFormat);
    const endToFormat = format(freeTime.endDate, timeFormat);

    if (format(startOfDay(date), timeFormat) !== startToFormat) {
      return startToFormat;
    }

    if (format(endOfDay(date), timeFormat) !== endToFormat) {
      return endToFormat;
    }

    return;
  };

  return (
    <InfiniteCalendar
      classNamePrefix="infinite-calendar"
      style={{
        width: '100%',
        height: '400px',
        boxShadow: '0 4px 12px -4px #9d9d9559, 0 0 2px #9d9d954d'
      }}
      selected={selectedDates}
      onChange={handleChange}
      reserved={reserved}
      dateFnsOptions={{ weekStartsOn: 1, locale: i18n.language === 'tr' ? tr : enUS }}
      variant={variant}
      range={rangeMode}
      components={{
        DayCellContent: ({
          state: { isToday, isSelectedStart, isSelected, isSelectedEnd, isReserved, isPast },
          innerProps,
          date
        }) => {
          return (
            <div
              style={{
                zIndex: 3,
                textDecoration: isReserved ? 'line-through' : 'none',
                color:
                  isSelectedStart || isSelectedEnd || isSelected
                    ? '#ffffff'
                    : isToday
                      ? '#474f85'
                      : isReserved || isPast
                        ? '#cccccc'
                        : '#424242'
              }}
              {...innerProps}>
              {date.getDate()}
            </div>
          );
        },
        DayCellFooter: ({
          date,
          reserved,
          state: { isSelectedStart, isSelectedEnd, isReserved, isPast, isSelected, isToday }
        }) => {
          return (
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                lineHeight: '12px',
                textTransform: 'capitalize',
                zIndex: 5,
                bottom: 3,
                fontSize: 8,
                color:
                  isSelectedStart || isSelectedEnd || isSelected
                    ? '#ffffff'
                    : isToday
                      ? '#474f85'
                      : isReserved || isPast
                        ? '#cccccc'
                        : '#424242'
              }}>
              {hostVariant(date, reserved, isSelectedStart, isSelectedEnd, isReserved)}
            </div>
          );
        }
      }}
    />
  );
};

export default AvailabilityCalendar;
