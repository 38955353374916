import React, { useRef } from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HeaderWithLocation from '../HeaderWithLocation/HeaderWithLocation';
import Rating from '../Rating/Rating';
import TertiaryButton from '../Button/TertiaryButton';
import { useNavigate } from 'react-router-dom';
import { RESERVATION_STATUS } from '../../Constants/Reservation';
import QuestionPopup from '../../Components/QuestionPopup/QuestionPopup';
import useRequest from '../../Hooks/useRequest';
import ENDPOINTS from '../../Constants/Endpoints';
import { useQueryClient } from '@tanstack/react-query';
import { categories } from '../../Constants/Property';

const HostPropertyCard = ({ property }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const questionPopupRef = useRef();
  const { deleteData } = useRequest();

  const yesClick = async () => {
    await deleteData.mutateAsync(
      {
        endpoint: `${ENDPOINTS.properties}/${property?._id}`
      },
      {
        onSuccess: (data) => {
          if (data) {
            queryClient.invalidateQueries('properties');
            queryClient.refetchQueries('properties');
          }
        }
      }
    );
    questionPopupRef?.current?.closeDialog();
  };

  return (
    <>
      <Box mb={2}>
        <Grid container justifyContent="center" alignItems="center" textAlign="center" mb={2}>
          <Grid item md={3} sm={6} xs={12}>
            <Box mr={2}>
              {property.photos.length === 0 ? (
                <img
                  src={
                    categories(t)?.find((category) => category.name === property?.category)?.image
                  }
                  alt={property?.title}
                  style={{
                    objectFit: 'cover',
                    float: 'left',
                    width: '100%',
                    height: '150px'
                  }}
                />
              ) : (
                <img
                  src={property?.photos[0]?.src}
                  alt={property?.title}
                  style={{
                    objectFit: 'cover',
                    float: 'left',
                    width: '100%',
                    height: '150px'
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <Stack
              spacing={{ xs: 1, sm: 1, md: 5 }}
              justifyContent="center"
              alignItems="flex-start">
              <Box
                sx={{
                  cursor: 'pointer',
                  m: 2
                }}
                onClick={() => navigate(`/host/properties/${property?._id}`)}>
                <HeaderWithLocation location={property?.address?.city} header={property?.title} />
              </Box>
              <Box>
                <Rating value={4} readOnly />
              </Box>
            </Stack>
          </Grid>
          <Grid item md={1.5} sm={6} xs={12}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: '#787878'
              }}>
              {t('hostPropertyCard.reservation.pending')}
            </Typography>
            <TertiaryButton
              color="warning"
              onClick={() =>
                navigate(`/host/properties/${property?._id}`, {
                  state: { tab: RESERVATION_STATUS.PENDING }
                })
              }>
              {property.pending}
            </TertiaryButton>
          </Grid>
          <Grid item md={1.5} sm={6} xs={12}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: '#787878'
              }}>
              {t('hostPropertyCard.reservation.upcoming')}
            </Typography>
            <TertiaryButton
              color="info"
              onClick={() =>
                navigate(`/host/properties/${property?._id}`, {
                  state: { tab: RESERVATION_STATUS.UPCOMING }
                })
              }>
              {property.upcoming}
            </TertiaryButton>
          </Grid>
          <Grid item md={1.5} sm={6} xs={12}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: '#787878'
              }}>
              {t('hostPropertyCard.reservation.active')}
            </Typography>
            <TertiaryButton
              color="success"
              onClick={() =>
                navigate(`/host/properties/${property?._id}`, {
                  state: { tab: RESERVATION_STATUS.ACTIVE }
                })
              }>
              {property.active}
            </TertiaryButton>
          </Grid>
          <Grid item md={1.5} sm={6} xs={12}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: '#787878'
              }}>
              {t('hostPropertyCard.reservation.completed')}
            </Typography>
            <TertiaryButton
              color="error"
              onClick={() =>
                navigate(`/host/properties/${property?._id}`, {
                  state: { tab: RESERVATION_STATUS.COMPLETED }
                })
              }>
              {property.completed}
            </TertiaryButton>
          </Grid>
          <Grid item md={1} sm={12} xs={12}>
            <Stack
              direction={{ xs: 'row', sm: 'column' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              alignItems="flex-end">
              <TertiaryButton
                onClick={() =>
                  navigate(`/update-property/${property?._id}`, {
                    state: { tab: RESERVATION_STATUS.COMPLETED }
                  })
                }>
                {t('common.update')}
              </TertiaryButton>
              <TertiaryButton color="error" onClick={() => questionPopupRef?.current?.openDialog()}>
                {t('common.delete')}
              </TertiaryButton>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      <QuestionPopup ref={questionPopupRef} yesClick={yesClick} />
    </>
  );
};

export default HostPropertyCard;
