import React from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HeaderWithLocation from '../HeaderWithLocation/HeaderWithLocation';
import Rating from '../Rating/Rating';
import { COMPLETED_RESERVATION_STATUS, RESERVATION_STATUS } from '../../Constants/Reservation';
import TertiaryButton from '../Button/TertiaryButton';
import { useNavigate } from 'react-router-dom';

const ReservationCard = ({ reservation, status }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let color;
  switch (reservation.status) {
    case COMPLETED_RESERVATION_STATUS.CANCELLED:
      color = 'error';
      break;
    case COMPLETED_RESERVATION_STATUS.DECLINED:
      color = 'warning';
      break;
    case COMPLETED_RESERVATION_STATUS.DONE:
      color = 'success';
      break;
    default:
      color = 'primary';
  }

  return (
    <Box mb={2}>
      <Grid container spacing={{ xs: 1, md: 3 }} alignItems="center" mb={2}>
        <Grid item md={2.4} sm={6} xs={12}>
          <Box>
            {/* daha sonra eklenecek */}
            {/* {property.photos.length === 0 ? (
              <img
                src={categories(t)?.find((category) => category.name === property?.category)?.image}
                alt={property?.title}
                style={{
                  objectFit: 'cover',
                  borderRadius: '5px',
                  float: 'left',
                  width: '100%',
                  height: '350px',
                  cursor: 'pointer'
                }}
              />
            ) : (
              <img
                src={property?.photos[0]?.src}
                alt={property?.title}
                style={{
                  objectFit: 'cover',
                  borderRadius: '5px',
                  float: 'left',
                  width: '100%',
                  height: '350px',
                  cursor: 'pointer'
                }}
              />
            )} */}
            <img
              src={reservation.imageUrl}
              alt=""
              style={{
                objectFit: 'cover',
                float: 'left',
                width: '100%',
                height: '150px'
              }}
            />
          </Box>
        </Grid>
        <Grid item md={2.5} sm={6} xs={12}>
          <Stack spacing={{ xs: 1, sm: 1, md: 5 }} justifyContent="center" alignItems="flex-start">
            <Box
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => navigate(`/reservations/${reservation.id}`)}>
              <HeaderWithLocation location="Bodrum" header="Siesta Garden Apart" />
            </Box>
            <Box>
              <Rating value={4} readOnly />
            </Box>
          </Stack>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Typography variant="h6" fontWeight={600} color="#787878">
            {reservation.checkInDate} - {reservation.checkOutDate}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 600
            }}>
            7 {t('reservationCard.days')}
          </Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography component="div" fontSize={20} fontWeight={400}>
            {t('reservationCard.total')}:{' '}
            <Box
              sx={{
                display: 'inline',
                fontWeight: 'bold',
                fontSize: 24,
                color: 'header.main'
              }}
              display="inline">
              ${reservation.total}
            </Box>
          </Typography>
        </Grid>
        <Grid item md={1} sm={2} xs={12}>
          {status === RESERVATION_STATUS.COMPLETED ? (
            <Typography
              sx={{
                color: `${color}.main`,
                textAlign: { xs: 'left', sm: 'end', md: 'end' }
              }}>
              {t(`reservations.completed.${reservation.status.toLowerCase()}`)}
            </Typography>
          ) : (
            <Stack
              direction={{ xs: 'row', sm: 'column' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              alignItems="flex-end">
              <TertiaryButton>{t('reservationCard.button.messages')}</TertiaryButton>
              <TertiaryButton color="inputPlaceholder">
                {t('reservationCard.button.cancel')}
              </TertiaryButton>
            </Stack>
          )}
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export default ReservationCard;
