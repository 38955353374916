import React from 'react';
import { Box, Container, Typography } from '@mui/material';

import SearchBar from '../SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';

const Hero = ({ formik, ...props }) => {
  const { t } = useTranslation();

  return (
    <Container
      maxWidth="false"
      disableGutters
      sx={{
        p: {
          xs: 2,
          sm: 5,
          md: 2
        },
        backgroundImage:
          'linear-gradient(0deg,rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(https://images.unsplash.com/photo-1452784444945-3f422708fe5e?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '70vh',
        borderRadius: {
          xs: '0px 0px 27px 27px',
          md: '0px 0px 54px 54px'
        }
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
        <Typography
          sx={{
            color: '#fff',
            marginTop: '196.5px',
            textAlign: 'center',
            fontWeight: '600',
            fontSize: {
              xs: '38px',
              md: '48px'
            },
            lineHeight: '62px',
            mb: 4
          }}>
          {t('homepage.motto')}
        </Typography>
        <SearchBar formik={formik} setSelectedPlace={props.setSelectedPlace} />
      </Box>
    </Container>
  );
};

export default Hero;
