import React from 'react';
import { Stack, Typography, Box, Tooltip } from '@mui/material';
import {
  HotelOutlined,
  Apartment,
  HolidayVillage,
  BathroomOutlined,
  BedOutlined,
  BusinessOutlined
} from '@mui/icons-material';
import { categories, CATEGORY } from '../../Constants/Property';
import HeaderWithLocation from '../HeaderWithLocation/HeaderWithLocation';
import Rating from '../Rating/Rating';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { currencies } from '../../Constants/SelectOptions';
import { useAuth } from '../../Contexts/AuthContext';
import { ROLES } from '../../Constants/Permissions';

const PropertyCard = ({ property }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        onClick={() =>
          user?.role === ROLES.ADMIN || user?.role === ROLES.HOST
            ? navigate(`/host/properties/${property?._id}`)
            : navigate(`/properties/${property?._id}`)
        }>
        <img
          src={
            property.photos.length === 0
              ? categories(t)?.find((category) => category.name === property?.category)?.image
              : property?.photos[0]?.src
          }
          alt={property?.title}
          style={{
            objectFit: 'cover',
            borderRadius: '5px',
            float: 'left',
            width: '100%',
            height: '350px',
            cursor: 'pointer'
          }}
        />
      </Box>
      <Box>
        <HeaderWithLocation location={property?.address?.city} header={property?.title} />
        <Stack direction="row" spacing={2} justifyItems="center" alignItems="center">
          {property?.category === CATEGORY.APART ? (
            <Tooltip
              title={t(`property.category.${CATEGORY.APART.toLowerCase()}`)}
              placement="bottom">
              <Apartment color="text" fontSize="small" />
            </Tooltip>
          ) : property?.category === CATEGORY.FLAT ? (
            <Tooltip
              title={t(`property.category.${CATEGORY.FLAT.toLowerCase()}`)}
              placement="bottom">
              <BusinessOutlined color="text" fontSize="small" />
            </Tooltip>
          ) : property?.category === CATEGORY.HOTEL ? (
            <Tooltip
              title={t(`property.category.${CATEGORY.HOTEL.toLowerCase()}`)}
              placement="bottom">
              <HotelOutlined color="text" fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip
              title={t(`property.category.${CATEGORY.VILLA.toLowerCase()}`)}
              placement="bottom">
              <HolidayVillage color="text" fontSize="small" />
            </Tooltip>
          )}
          <BedOutlined color="text" fontSize="small" /> {property?.basicFeatures?.bedroom}
          <BathroomOutlined color="text" fontSize="small" /> {property?.basicFeatures?.bathroom}
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
          <Rating />
          <Typography component="div">
            <Box
              sx={{
                display: 'inline',
                fontWeight: 600
              }}>
              {property?.pricing?.perDayPrice}
              {
                currencies.find((currency) => currency.value === property?.pricing?.currency)
                  ?.symbol
              }
            </Box>
            /{t('property.category.perDay')}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
export default PropertyCard;
