import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Input from '../../Components/Input/Input';

const Description = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <Box gap={3} mb={2}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 1, md: 12 }}
        alignItems="center">
        <Grid item xs={12}>
          <Typography
            sx={{
              color: 'header.main',
              fontWeight: 'bold',
              fontSize: '32px'
            }}
            gutterBottom>
            {t('stepper.step.description')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Input
            label={t('createProperty.input.description')}
            onChange={formik.handleChange('description.description')}
            value={formik.values.description.description}
            onBlur={formik.handleBlur('description.description')}
            error={
              formik.touched.description?.description &&
              Boolean(formik.errors.description?.description)
            }
            multiline
            rows={4}
            length={2000}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label={t('createProperty.input.shortDescription')}
            onChange={formik.handleChange('description.shortDescription')}
            value={formik.values.description.shortDescription}
            onBlur={formik.handleBlur('description.shortDescription')}
            error={
              formik.touched.description?.shortDescription &&
              Boolean(formik.errors.description?.shortDescription)
            }
            multiline
            rows={4}
            length={1000}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: 'header.main',
              fontWeight: 600,
              textAlign: 'start',
              fontSize: '16px'
            }}
            variant="subtitle1">
            {t('createProperty.neighborhood.title')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Input
            label={t('createProperty.input.overview')}
            onChange={formik.handleChange('description.neighborhood.overview')}
            value={formik.values.description.neighborhood.overview}
            onBlur={formik.handleBlur('description.neighborhood.overview')}
            error={
              formik.touched.description?.neighborhood?.overview &&
              Boolean(formik.errors.description?.neighborhood?.overview)
            }
            multiline
            rows={4}
            length={1000}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label={t('createProperty.input.gettingAround')}
            onChange={formik.handleChange('description.neighborhood.gettingAround')}
            value={formik.values.description.neighborhood.gettingAround}
            onBlur={formik.handleBlur('description.neighborhood.gettingAround')}
            error={
              formik.touched.description?.neighborhood?.gettingAround &&
              Boolean(formik.errors.description?.neighborhood?.gettingAround)
            }
            multiline
            rows={4}
            length={1000}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Description;
