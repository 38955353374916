import * as yup from 'yup';
import { Trans } from 'react-i18next';

const MINIMUM_AGE = 18;

const registerSchema = yup.object().shape({
  firstName: yup.string().required(<Trans i18nKey="validation.firstName.required" />),

  lastName: yup.string().required(<Trans i18nKey="validation.lastName.required" />),

  email: yup
    .string()
    .email(<Trans i18nKey="validation.email.invalid" />)
    .required(<Trans i18nKey="validation.mail.required" />),

  phoneNumber: yup.string().required(<Trans i18nKey="validation.phoneNumber.required" />),

  trIdentityNo: yup.string().required(<Trans i18nKey="validation.trIdentityNo.required" />),

  birthDay: yup
    .date()
    .required(<Trans i18nKey="validation.birthday.required" />)
    .test('future-date', <Trans i18nKey="validation.futureDate.birthday" />, (value) => {
      return new Date(value) <= new Date();
    })
    .test('valid-age', <Trans i18nKey="validation.validAge" />, (value) => {
      const birthDate = new Date(value);
      const today = new Date();

      if (isNaN(birthDate)) {
        return false;
      }

      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age >= MINIMUM_AGE;
    }),

  password: yup
    .string()
    .min(8, <Trans i18nKey="validation.minDigit.password" values={{ min: 8 }} />)
    .max(25, <Trans i18nKey="validation.maxDigit.password" values={{ max: 25 }} />)
    .required(<Trans i18nKey="validation.password.required" />),

  passwordAgain: yup
    .string()
    .min(8, <Trans i18nKey="validation.minDigit.password" values={{ min: 8 }} />)
    .max(25, <Trans i18nKey="validation.maxDigit.password" values={{ max: 25 }} />)
    .oneOf([yup.ref('password')], <Trans i18nKey="validation.passwordsDontMatch" />)
    .required(<Trans i18nKey="validation.passwordAgain.required" />)
});

export default registerSchema;
