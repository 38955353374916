import React from 'react';
import { Typography, Grid, Stack } from '@mui/material';
import Avatar from '../Avatar/Avatar';
import Rating from '../Rating/Rating';
import dayjs from 'dayjs';

const Review = ({ review, showPropertyName = true }) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      columns={{ xs: 4, sm: 8, md: 12 }}
      mb={2}>
      <Grid item xs={12} md={2}>
        <Stack direction="column">
          {showPropertyName && (
            <Typography
              sx={{ color: 'header.main', fontWeight: 600, textAlign: 'start', fontSize: '16px' }}>
              {review.property}
            </Typography>
          )}
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar sx={{ width: 30, height: 30 }}>{review.user}</Avatar>
            <Typography sx={{ fontWeight: 700, fontSize: 16, color: '#787878' }}>
              {review.user}
            </Typography>
          </Stack>
          <Typography sx={{ fontWeight: 400, fontSize: 14 }}>
            {dayjs(review.commentedDate).format('DD.MM.YYYY HH:mm')}
          </Typography>
          <Typography sx={{ fontWeight: 400, fontSize: 14 }}>{review.dateRange}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={10}>
        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
          <Rating value={review.rate} />
          <Typography
            sx={{ fontWeight: 700, fontSize: 14, textAlign: 'justify', textJustify: 'inter-word' }}>
            {' '}
            - {review.title}
          </Typography>
        </Stack>
        <Typography
          sx={{ fontWeight: 400, fontSize: 16, textAlign: 'justify', textJustify: 'inter-word' }}>
          {review.text}
        </Typography>
      </Grid>
      <Grid item xs={0} md={2} />
    </Grid>
  );
};

export default Review;
