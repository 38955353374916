import React from 'react';
import { ListItem, Divider, ListItemText, ListItemAvatar, Typography } from '@mui/material';
import Avatar from '../Avatar/Avatar';

const MessageInfo = ({ message, onClick }) => {
  return (
    <>
      <ListItem
        sx={{
          alignItems: 'flex-start',
          cursor: 'pointer'
        }}
        alignItems="flex-start"
        onClick={onClick}>
        <ListItemAvatar>
          <Avatar>{message?.host}</Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{
            color: 'header.main',
            '& .MuiListItemText-primary': {
              fontWeight: 600
            }
          }}
          primary={message.property}
          secondary={
            <>
              <Typography
                sx={{ display: 'inline', color: 'primary.main' }}
                component="span"
                variant="body2">
                {message.host}
              </Typography>{' '}
              - {message?.messages[message?.messages?.length - 1].text}
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default MessageInfo;
