import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
import { USER_PERMISSIONS } from '../Constants/Permissions';
import Loading from '../Components/Loading/Loading';

const Control = ({ children, permissions }) => {
  const { user, loggedIn, loading } = useAuth();

  const location = useLocation();

  if (loading) {
    return <Loading loading={true} />;
  }

  if (!loggedIn || !user) {
    return <Navigate to="/" state={{ path: location.pathname }} replace />;
  }

  const userPermission = USER_PERMISSIONS.find((p) => user.role === p.role)?.permissions;
  const isAllowed = permissions.some((allowed) => userPermission.includes(allowed));

  if (!userPermission || !isAllowed) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default Control;
