export const RESERVATION_STATUS = {
  PENDING: 'Pending',
  UPCOMING: 'Upcoming',
  COMPLETED: 'Completed',
  ACTIVE: 'Active'
};

export const COMPLETED_RESERVATION_STATUS = {
  DONE: 'Done',
  DECLINED: 'Declined',
  CANCELLED: 'Cancelled'
};
