import React, { useRef } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MainButton from '../Button/MainButton';
import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import ImageList from '../ImageList/ImageList';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap'
});

const FileUpload = ({ photos, setPhotos, children }) => {
  const imageListRef = useRef();

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <MainButton
          onChange={imageListRef?.current?.uploadPhoto}
          component="label"
          startIcon={<CloudUploadIcon />}
          sx={{
            width: 'auto'
          }}>
          {children}
          <VisuallyHiddenInput type="file" accept="image/*" />
        </MainButton>
      </Grid>
      <Grid item xs={10}>
        <Container>
          <ImageList
            photos={photos}
            setPhotos={setPhotos}
            spacing={10}
            targetRowHeight={200}
            layout="columns"
            columns={3}
            isDeleting={true}
            isSortable={true}
            ref={imageListRef}
          />
        </Container>
      </Grid>
    </Grid>
  );
};

export default FileUpload;
