import * as yup from 'yup';
import { Trans } from 'react-i18next';

export const propertySchema = yup.object().shape({
  title: yup.string().required(<Trans i18nKey="validation.title.required" />),
  category: yup.string().required(<Trans i18nKey="validation.category.required" />),
  keyword: yup.string().required(<Trans i18nKey="validation.keyword.required" />),
  address: yup
    .object({
      street: yup.string().required(<Trans i18nKey="validation.address.street.required" />),
      houseNumber: yup
        .string()
        .required(<Trans i18nKey="validation.address.houseNumber.required" />),
      postalCode: yup.string().required(<Trans i18nKey="validation.address.postalCode.required" />),
      district: yup.string().required(<Trans i18nKey="validation.address.district.required" />),
      city: yup.string().required(<Trans i18nKey="validation.address.city.required" />),
      country: yup.string().required(<Trans i18nKey="validation.address.country.required" />),
      location: yup
        .object()
        .required(<Trans i18nKey="validation.address.location.required" />)
        .shape({
          coordinates: yup.array().length(2)
        })
    })
    .required(<Trans i18nKey="validation.basicFeatures.required" />),
  basicFeatures: yup
    .object({
      maxTraveler: yup
        .number()
        .required(<Trans i18nKey="validation.basicFeatures.maxTraveler.required" />),
      bedroom: yup.number().required(<Trans i18nKey="validation.basicFeatures.bedroom.required" />),
      bed: yup.string().required(<Trans i18nKey="validation.basicFeatures.bed.required" />),
      bathroom: yup
        .number()
        .required(<Trans i18nKey="validation.basicFeatures.bathroom.required" />),
      isBathShared: yup
        .boolean()
        .required(<Trans i18nKey="validation.basicFeatures.isBathShared.required" />)
    })
    .required(<Trans i18nKey="validation.basicFeatures.required" />),
  description: yup
    .object({
      shortDescription: yup
        .string()
        .required(<Trans i18nKey="validation.description.shortDescription.required" />)
        .max(
          1000,
          <Trans
            i18nKey="validation.description.shortDescription.maxLength"
            values={{ max: 1000 }}
          />
        ),
      description: yup
        .string()
        .required(<Trans i18nKey="validation.description.description.required" />)
        .max(
          2000,
          <Trans i18nKey="validation.description.description.maxLength" values={{ max: 2000 }} />
        ),
      neighborhood: yup.object({
        overview: yup
          .string()
          .max(
            1000,
            <Trans
              i18nKey="validation.description.neighborhood.overview.maxLength"
              values={{ max: 1000 }}
            />
          ),
        gettingAround: yup
          .string()
          .max(
            1000,
            <Trans
              i18nKey="validation.description.neighborhood.gettingAround.maxLength"
              values={{ max: 1000 }}
            />
          )
      })
    })
    .required(<Trans i18nKey="validation.description.required" />),
  pricing: yup
    .object({
      perDayPrice: yup
        .number()
        .required(<Trans i18nKey="validation.pricing.perDayPrice.required" />),
      currency: yup.string().required(<Trans i18nKey="validation.pricing.currency.required" />),
      additionalOptions: yup
        .object({
          cleaningFee: yup
            .string()
            .required(
              <Trans i18nKey="validation.pricing.additionalOptions.cleaningFee.required" />
            ),
          additionalGuest: yup
            .string()
            .required(
              <Trans i18nKey="validation.pricing.additionalOptions.additionalGuest.required" />
            ),
          securityDeposit: yup
            .string()
            .required(
              <Trans i18nKey="validation.pricing.additionalOptions.securityDeposit.required" />
            ),
          weekendPricing: yup
            .string()
            .required(
              <Trans i18nKey="validation.pricing.additionalOptions.weekendPricing.required" />
            )
        })
        .required(
          <Trans i18nKey="validation.pricing.additionalOptions.additionalOptions.required" />
        )
    })
    .required(<Trans i18nKey="validation.pricing.required" />),
  photos: yup.array(), // TO DO: simdilik zorunlu degil
  video: yup.string().url(<Trans i18nKey="validation.video.matchUrl" />),
  amenties: yup.object({
    livingArea: yup.object({
      sofaSet: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.livingArea.sofaSet.required" />),
      armChair: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.livingArea.armChair.required" />),
      sofaBed: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.livingArea.sofaBed.required" />),
      doubleSofaBed: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.livingArea.doubleSofaBed.required" />),
      diningTable: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.livingArea.diningTable.required" />),
      tv: yup.boolean().required(<Trans i18nKey="validation.amenties.livingArea.tv.required" />),
      satelliteTV: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.livingArea.satelliteTV.required" />),
      coffeeTable: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.livingArea.coffeeTable.required" />),
      airConditioning: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.livingArea.airConditioning.required" />)
    }),
    kitchen: yup.object({
      crockery: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.crockery.required" />),
      cutlery: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.cutlery.required" />),
      glassware: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.glassware.required" />),
      pots: yup.boolean().required(<Trans i18nKey="validation.amenties.kitchen.pots.required" />),
      pans: yup.boolean().required(<Trans i18nKey="validation.amenties.kitchen.pans.required" />),
      diningTable: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.diningTable.required" />),
      oven: yup.boolean().required(<Trans i18nKey="validation.amenties.kitchen.oven.required" />),
      microwave: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.microwave.required" />),
      fridge: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.fridge.required" />),
      freezer: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.freezer.required" />),
      dishWasher: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.dishWasher.required" />),
      toaster: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.toaster.required" />),
      kettle: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.kettle.required" />),
      kitchenware: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.kitchenware.required" />),
      diningArea: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.kitchen.diningArea.required" />)
    }),
    generalFacilities: yup.object({
      jacuzzi: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.jacuzzi.required" />),
      cot: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.cot.required" />),
      highChair: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.highChair.required" />),
      safe: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.safe.required" />),
      tumbleDryer: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.tumbleDryer.required" />),
      washingMachine: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.generalFacilities.washingMachine.required" />
        ),
      towel: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.towel.required" />),
      internet: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.internet.required" />),
      iron: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.iron.required" />),
      WIFI: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.WIFI.required" />),
      hairDyrer: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.hairDyrer.required" />),
      wardrobe: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.wardrobe.required" />),
      makeupTable: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.makeupTable.required" />),
      airConditioning: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.generalFacilities.airConditioning.required" />
        ),
      elevator: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.elevator.required" />),
      pool: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.pool.required" />),
      sauna: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.sauna.required" />),
      tableTennis: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.tableTennis.required" />),
      fireplace: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.fireplace.required" />),
      housekeeping: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.housekeeping.required" />),
      heating: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.generalFacilities.heating.required" />)
    }),
    outdoorFacilities: yup.object({
      communalSwimmingPool: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.outdoorFacilities.communalSwimmingPool.required" />
        ),
      privateSwimmingPool: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.outdoorFacilities.privateSwimmingPool.required" />
        ),
      gardenFurniture: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.outdoorFacilities.gardenFurniture.required" />
        ),
      communalGarden: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.outdoorFacilities.communalGarden.required" />
        ),
      privateGarden: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.privateGarden.required" />),
      parking: yup.boolean().required(<Trans i18nKey="validation.parking.required" />),
      outsideLighting: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.outdoorFacilities.outsideLighting.required" />
        ),
      barbecue: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.barbecue.required" />),
      balconyORterrace: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.outdoorFacilities.balconyORterrace.required" />
        ),
      childrenPool: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.childrenPool.required" />),
      security: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.security.required" />),
      seaView: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.seaView.required" />),
      sunsetView: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.sunsetView.required" />),
      poolView: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.poolView.required" />),
      cityView: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.cityView.required" />),
      mountainView: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.mountainView.required" />),
      seafront: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.seafront.required" />),
      complex: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.complex.required" />),
      parasols: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.parasols.required" />),
      sunbeds: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.sunbeds.required" />),
      publicBeach: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.publicBeach.required" />),
      privateBeach: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.privateBeach.required" />),
      paidParking: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.outdoorFacilities.paidParking.required" />),
      outdoorDiningTable: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.outdoorFacilities.outdoorDiningTable.required" />
        )
    }),
    siteAndAttractions: yup.object({
      supermarkets: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.supermarkets.required" />),
      historicSites: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.siteAndAttractions.historicSites.required" />
        ),
      museum: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.museum.required" />),
      bar: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.bar.required" />),
      restaurant: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.restaurant.required" />),
      cafeteria: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.cafeteria.required" />),
      nightClub: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.nightClub.required" />),
      shoppingCentre: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.siteAndAttractions.shoppingCentre.required" />
        ),
      localFoodMarket: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.siteAndAttractions.localFoodMarket.required" />
        ),
      fishing: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.fishing.required" />),
      boatTrip: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.boatTrip.required" />),
      cycling: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.cycling.required" />),
      horseRiding: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.horseRiding.required" />),
      golf: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.golf.required" />),
      fitnessCenterGYM: yup
        .boolean()
        .required(
          <Trans i18nKey="validation.amenties.siteAndAttractions.fitnessCenterGYM.required" />
        ),
      SPA: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.SPA.required" />),
      turkishBath: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.turkishBath.required" />),
      tennisCourt: yup
        .boolean()
        .required(<Trans i18nKey="validation.amenties.siteAndAttractions.tennisCourt.required" />),
      basketballCourt: yup
        .boolean()
        .required(<Trans i18nKey="validation.basketballCourt.required" />),
      volleyballCourt: yup
        .boolean()
        .required(<Trans i18nKey="validation.volleyballCourt.required" />)
    }),
    suitabilities: yup.object({
      petsAllowed: yup
        .string()
        .required(<Trans i18nKey="validation.amenties.suitabilities.petsAllowed.required" />),
      smoking: yup
        .string()
        .required(<Trans i18nKey="validation.amenties.suitabilities.smoking.required" />),
      restrictedMobility: yup
        .string()
        .required(
          <Trans i18nKey="validation.amenties.suitabilities.restrictedMobility.required" />
        ),
      children: yup
        .string()
        .required(<Trans i18nKey="validation.amenties.suitabilities.children.required" />),
      maleGroups: yup
        .string()
        .required(<Trans i18nKey="validation.amenties.suitabilities.maleGroups.required" />)
    }),
    carRental: yup.string().required(<Trans i18nKey="validation.amenties.carRental.required" />),
    checkInTime: yup
      .date()
      .nullable()
      .required(<Trans i18nKey="validation.amenties.checkInTime.required" />),
    checkOutTime: yup
      .date()
      .nullable()
      .required(<Trans i18nKey="validation.amenties.checkOutTime.required" />)
  }),
  transportation: yup.array(),
  entertainment: yup.array(),
  foodDrink: yup.array(),
  places: yup.array()
});

export const filterSchema = yup.object().shape({
  category: yup.string(),
  keyword: yup.string(),
  minPrice: yup.number(),
  maxPrice: yup.number(),
  street: yup.string(),
  district: yup.string(),
  city: yup.string(),
  country: yup.string(),
  maxTraveler: yup.number(),
  bedroom: yup.number(),
  bed: yup.string(),
  bathroom: yup.number(),
  isBathShared: yup.boolean(),
  livingArea: yup.object({
    sofaSet: yup.boolean(),
    armChair: yup.boolean(),
    sofaBed: yup.boolean(),
    doubleSofaBed: yup.boolean(),
    diningTable: yup.boolean(),
    tv: yup.boolean(),
    satelliteTV: yup.boolean(),
    coffeeTable: yup.boolean(),
    airConditioning: yup.boolean()
  }),
  kitchen: yup.object({
    crockery: yup.boolean(),
    cutlery: yup.boolean(),
    glassware: yup.boolean(),
    pots: yup.boolean(),
    pans: yup.boolean(),
    diningTable: yup.boolean(),
    oven: yup.boolean(),
    microwave: yup.boolean(),
    fridge: yup.boolean(),
    freezer: yup.boolean(),
    dishWasher: yup.boolean(),
    toaster: yup.boolean(),
    kettle: yup.boolean(),
    kitchenware: yup.boolean(),
    diningArea: yup.boolean()
  }),
  generalFacilities: yup.object({
    jacuzzi: yup.boolean(),
    cot: yup.boolean(),
    highChair: yup.boolean(),
    safe: yup.boolean(),
    tumbleDryer: yup.boolean(),
    washingMachine: yup.boolean(),
    towel: yup.boolean(),
    internet: yup.boolean(),
    iron: yup.boolean(),
    WIFI: yup.boolean(),
    hairDyrer: yup.boolean(),
    wardrobe: yup.boolean(),
    makeupTable: yup.boolean(),
    airConditioning: yup.boolean(),
    elevator: yup.boolean(),
    pool: yup.boolean(),
    sauna: yup.boolean(),
    tableTennis: yup.boolean(),
    fireplace: yup.boolean(),
    housekeeping: yup.boolean(),
    heating: yup.boolean()
  }),
  outdoorFacilities: yup.object({
    communalSwimmingPool: yup.boolean(),
    privateSwimmingPool: yup.boolean(),
    gardenFurniture: yup.boolean(),
    communalGarden: yup.boolean(),
    privateGarden: yup.boolean(),
    parking: yup.boolean(),
    outsideLighting: yup.boolean(),
    barbecue: yup.boolean(),
    balconyORterrace: yup.boolean(),
    childrenPool: yup.boolean(),
    security: yup.boolean(),
    seaView: yup.boolean(),
    sunsetView: yup.boolean(),
    poolView: yup.boolean(),
    cityView: yup.boolean(),
    mountainView: yup.boolean(),
    seafront: yup.boolean(),
    complex: yup.boolean(),
    parasols: yup.boolean(),
    sunbeds: yup.boolean(),
    publicBeach: yup.boolean(),
    privateBeach: yup.boolean(),
    paidParking: yup.boolean(),
    outdoorDiningTable: yup.boolean()
  }),
  siteAndAttractions: yup.object({
    supermarkets: yup.boolean(),
    historicSites: yup.boolean(),
    museum: yup.boolean(),
    bar: yup.boolean(),
    restaurant: yup.boolean(),
    cafeteria: yup.boolean(),
    nightClub: yup.boolean(),
    shoppingCentre: yup.boolean(),
    localFoodMarket: yup.boolean(),
    fishing: yup.boolean(),
    boatTrip: yup.boolean(),
    cycling: yup.boolean(),
    horseRiding: yup.boolean(),
    golf: yup.boolean(),
    fitnessCenterGYM: yup.boolean(),
    SPA: yup.boolean(),
    turkishBath: yup.boolean(),
    tennisCourt: yup.boolean(),
    basketballCourt: yup.boolean(),
    volleyballCourt: yup.boolean()
  }),
  suitabilities: yup.object({
    petsAllowed: yup.string(),
    smoking: yup.string(),
    restrictedMobility: yup.string(),
    children: yup.string(),
    maleGroups: yup.string()
  }),
  carRental: yup.string(),
  checkInTime: yup.date().nullable(),
  checkOutTime: yup.date().nullable()
});

export const calendarSchema = yup.object().shape({
  startDate: yup
    .date()
    .required(<Trans i18nKey="validation.startDate.required" />)
    .test('future-date', <Trans i18nKey="validation.futureDate.startDate" />, (value) => {
      return new Date(value) > new Date();
    }),

  endDate: yup
    .date()
    .required(<Trans i18nKey="validation.endDate.required" />)
    .test('future-date', <Trans i18nKey="validation.futureDate.endDate" />, (value) => {
      return new Date(value) > new Date();
    })
    .min(yup.ref('startDate'), <Trans i18nKey="validation.calendarEndDate.invalid" />)
});
