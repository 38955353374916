import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_USER_SERVICE_PATH
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('x-access-token');

    if (token) {
      config.headers['x-access-token'] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const login = async (body) => {
  const { data } = await instance.post('/authentication/login', body);
  return data;
};

export const register = async (body) => {
  const { data } = await instance.post('/authentication/signup', body);
  return data;
};

export const getMe = async () => {
  const { data } = await instance.get('/authentication/me');
  return data;
};

export const logout = async () => {
  const { data } = await instance.get('/authentication/logout');
  return data;
};

export default instance;
