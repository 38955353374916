import React from 'react';
import styled from '@emotion/styled';
import MUIButton from '@mui/material/Button';

const StyledButton = styled(MUIButton)(
  ({ color, variant }) => `
  background-color: ${color};
  border-color: ${color};
  font-weight: ${variant === 'contained' || variant === 'outlined' ? '600' : 'none'};
  text-decoration: ${variant === 'text' ? 'underline' : 'none'};
  text-transform: capitalize;
`
);

const Button = ({
  disabled = false,
  type,
  size,
  variant,
  color,
  onClick,
  children,
  sx,
  ...props
}) => {
  return (
    <StyledButton
      disabled={disabled}
      sx={sx}
      type={type}
      size={size}
      color={color}
      variant={variant}
      onClick={onClick}
      {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
