import * as yup from 'yup';
import { Trans } from 'react-i18next';

const today = new Date();
today.setHours(0, 0, 0, 0);

const searchSchema = yup.object().shape({
  location: yup
    .object({
      lat: yup.number().min(-90).max(90).required(),
      lng: yup.number().min(-180).max(180).required()
    })
    .nullable()
    .required(<Trans i18nKey="validation.location.required" />),

  checkInDate: yup
    .date()
    .required(<Trans i18nKey="validation.checkInDate.required" />)
    .test('future-date', <Trans i18nKey="validation.futureDate.checkInDate" />, (value) => {
      return new Date(value) >= today;
    }),

  checkOutDate: yup
    .date()
    .required(<Trans i18nKey="validation.checkOutDate.required" />)
    .test('future-date', <Trans i18nKey="validation.futureDate.checkOutDate" />, (value) => {
      return new Date(value) >= today;
    })
    .min(yup.ref('checkInDate'), <Trans i18nKey="validation.endDate.invalid" />),

  guest: yup.number().min(1, <Trans i18nKey="validation.minValue.guest" values={{ min: 1 }} />)
});

const reservationSchema = yup.object().shape({
  checkInDate: yup
    .date()
    .required(<Trans i18nKey="validation.checkInDate.required" />)
    .test('future-date', <Trans i18nKey="validation.futureDate.checkInDate" />, (value) => {
      return new Date(value) >= today;
    }),

  checkOutDate: yup
    .date()
    .required(<Trans i18nKey="validation.checkOutDate.required" />)
    .test('future-date', <Trans i18nKey="validation.futureDate.checkOutDate" />, (value) => {
      return new Date(value) >= today;
    })
    .min(yup.ref('checkInDate'), <Trans i18nKey="validation.endDate.invalid" />),

  guest: yup.number().min(1, <Trans i18nKey="validation.minValue.guest" values={{ min: 1 }} />)
});

export { searchSchema, reservationSchema };
