import React, { useState } from 'react';
import { Box, Stack, Tab, Typography } from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import ReservationCard from '../../Components/ReservationCard/ReservationCard';
import { COMPLETED_RESERVATION_STATUS, RESERVATION_STATUS } from '../../Constants/Reservation';
import { useLocation } from 'react-router-dom';

const ReservationDetails = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const [value, setValue] = useState(state?.tab || RESERVATION_STATUS.PENDING);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const reservation = {
    checkInDate: '24.07.2023',
    checkOutDate: '30.07.2023',
    total: 1000,
    status: COMPLETED_RESERVATION_STATUS.DONE,
    imageUrl:
      'https://images.unsplash.com/photo-1695982206826-970fd4e8e27e?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: 1
  };

  const reservation1 = {
    checkInDate: '24.07.2023',
    checkOutDate: '30.07.2023',
    total: 1000,
    status: COMPLETED_RESERVATION_STATUS.CANCELLED,
    imageUrl:
      'https://images.unsplash.com/photo-1613490493576-7fde63acd811?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: 2
  };

  return (
    <Stack gap={3} mb={3} direction="row">
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Typography
          sx={{
            color: 'header.main',
            fontWeight: 600,
            textAlign: 'start',
            fontSize: '20px',
            mb: 2
          }}
          variant="subtitle1">
          {t('propertyDetail.reservations.title')}
        </Typography>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label={t('propertyDetail.reservations.pending')}
                value={RESERVATION_STATUS.PENDING}
              />
              <Tab
                label={t('propertyDetail.reservations.upcoming')}
                value={RESERVATION_STATUS.UPCOMING}
              />
              <Tab
                label={t('propertyDetail.reservations.active')}
                value={RESERVATION_STATUS.ACTIVE}
              />
              <Tab
                label={t('propertyDetail.reservations.completed')}
                value={RESERVATION_STATUS.COMPLETED}
              />
            </TabList>
          </Box>
          <TabPanel value={RESERVATION_STATUS.PENDING}>
            <ReservationCard reservation={reservation} />
            <ReservationCard reservation={reservation1} />
          </TabPanel>
          <TabPanel value={RESERVATION_STATUS.UPCOMING}>
            <ReservationCard reservation={reservation} />
          </TabPanel>
          <TabPanel value={RESERVATION_STATUS.COMPLETED}>
            <ReservationCard reservation={reservation} status={RESERVATION_STATUS.COMPLETED} />
            <ReservationCard reservation={reservation1} status={RESERVATION_STATUS.COMPLETED} />
          </TabPanel>
        </TabContext>
      </Box>
    </Stack>
  );
};

export default ReservationDetails;
