import React from 'react';
import { Box, Stack } from '@mui/material';
import HostPropertyCard from '../../Components/HostPropertyCard/HostPropertyCard';
import MainButton from '../../Components/Button/MainButton';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useRequest from '../../Hooks/useRequest';
import ENDPOINTS from '../../Constants/Endpoints';
import { useAuth } from '../../Contexts/AuthContext';

const Properties = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useGetDataWithPagination } = useRequest();
  const { user } = useAuth();

  // hostIdye gore getir
  const { data: properties } = useGetDataWithPagination(
    'properties',
    `${ENDPOINTS.properties}?hostId=${user._id}`,
    {},
    {},
    0
  );

  return (
    <Stack gap={3} mb={3} p={2} direction="row">
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Stack direction="row" mb={2} justifyContent="flex-end" alignItems="center">
          <MainButton
            startIcon={<Add />}
            sx={{
              width: 'auto'
            }}
            onClick={() => navigate('/create-property')}
            color="success">
            {t('common.new')}
          </MainButton>
        </Stack>
        {properties?.length > 0
          ? properties?.map((p, index) => <HostPropertyCard key={index} property={p} />)
          : t('common.noProperty')}
      </Box>
    </Stack>
  );
};

export default Properties;
