import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CategoryCard = ({ category }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      // pathler belli oldugunda duzeltilecek
      onClick={() => navigate(`/properties?category=${category.name}`)}>
      <Stack spacing={1}>
        <Box>
          <img
            src={category.image}
            alt={category.title}
            style={{
              objectFit: 'cover',
              borderRadius: '10px',
              float: 'left',
              width: '100%',
              height: '200px'
            }}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              color: 'header.main',
              fontWeight: 600,
              textAlign: 'start',
              fontSize: '16px'
            }}
            gutterBottom>
            {category.title}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default CategoryCard;
