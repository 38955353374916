import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SecondaryButton from '../Button/SecondaryButton';

const WarningPopup = ({ open, setOpen, warningDesc }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          maxHeight: '70vh'
        }
      }}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t('errorPopup.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{warningDesc}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={handleClose}>{t('errorPopup.button.title')}</SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default WarningPopup;
