import React, { useState } from 'react';
import { Grid, List, Stack } from '@mui/material';
import MessageInfo from '../../Components/Chat/MessageInfo';
import ChatContainer from '../../Components/Chat/ChatContainer';
import SearchInput from '../../Components/SearchInput/SearchInput';

const chatList = [
  {
    property: 'Siesta Garden',
    reservation: {},
    host: 'Beyza Erkan',
    messages: [
      { text: 'hey', time: Date(), type: 'incoming' },
      { text: 'how are you?', time: Date(), type: 'incoming' },
      { text: 'whats up', time: Date(), type: 'outgoing' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  },
  {
    property: 'Apart',
    reservation: {},
    host: 'Alihan Saraç',
    messages: [
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' },
      { text: 'selam', time: Date(), type: 'outgoing' },
      { text: 'nasılsın', time: Date(), type: 'outgoing' },
      { text: 'thanks', time: Date(), type: 'incoming' }
    ]
  }
];

const Inbox = () => {
  const [selectedChat, setSelectedChat] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const filterData = (query, data) => {
    if (!query) {
      return data;
    } else {
      return data.filter(
        (d) => d.host.toLowerCase().includes(query) || d.property.toLowerCase().includes(query)
      );
    }
  };

  const dataFiltered = filterData(searchQuery, chatList);

  return (
    <Stack gap={3} py={2} pr={2} direction="row">
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item md={4} sm={12} xs={12}>
          <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          <Stack
            direction="column"
            sx={{
              overflowY: 'auto',
              position: 'relative',
              height: '450px',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {dataFiltered.map((message, index) => (
                <MessageInfo
                  key={index}
                  message={message}
                  onClick={() => setSelectedChat(message)}
                />
              ))}
            </List>
          </Stack>
        </Grid>
        {selectedChat && (
          <Grid item md={8} sm={12} xs={12}>
            <ChatContainer messageInfo={selectedChat} />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
export default Inbox;
