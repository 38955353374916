export const ROLES = {
  TRAVELER: 'Traveler',
  HOST: 'Host',
  ADMIN: 'Admin',
  GUEST: 'Guest'
};

export const PERMISSIONS = {
  CREATE_PROPERTY: 'can_create_property',
  UPDATE_PROPERTY: 'can_update_property',
  RESERVATIONS: 'can_view_reservations',
  RESERVATION_DETAIL: 'can_view_reservation_detail',
  BOOKING: 'can_booking',
  PROFILE: 'can_view_profile',
  PROPERTY_DETAIL_HOST: 'host_can_view_property_detail'
};

export const USER_PERMISSIONS = [
  { role: ROLES.ADMIN, permissions: Object.values(PERMISSIONS) },
  { role: ROLES.HOST, permissions: Object.values(PERMISSIONS) },
  {
    role: ROLES.TRAVELER,
    permissions: [
      PERMISSIONS.RESERVATIONS,
      PERMISSIONS.RESERVATION_DETAIL,
      PERMISSIONS.BOOKING,
      PERMISSIONS.PROFILE
    ]
  },
  {
    role: ROLES.GUEST,
    permissions: []
  }
];
