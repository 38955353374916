import React from 'react';
import {
  Box,
  Typography,
  Stack,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Accessible, CarRental, ChildCare, Male, Pets, SmokingRooms } from '@mui/icons-material';
import {
  carRental,
  children,
  maleGroups,
  petsAllowed,
  restrictedMobility,
  smoking
} from '../../Constants/SelectOptions';

const GoodToKnow = ({ property }) => {
  const { t } = useTranslation();

  return (
    <Box id="good-to-know" gap={3} mb={2}>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 600,
          textAlign: 'start',
          fontSize: '16px',
          mb: 2
        }}
        variant="subtitle1">
        {t('propertyDetail.goodToKnow.title')}
      </Typography>
      <Box>
        <Grid container>
          <Grid item md={4} xs={6}>
            <Stack direction="row" spacing={2}>
              <Pets />
              <Typography fontSize={14} fontWeight="bold">
                {t('filter.amenties.suitabilities.petsAllowed')}:
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={8} xs={6}>
            <Typography variant="subtitle2">
              {
                petsAllowed.find(
                  (option) => option.value === property?.amenties?.suitabilities?.petsAllowed
                )?.label
              }
            </Typography>
          </Grid>
          <Grid item md={4} xs={6}>
            <Stack direction="row" spacing={2}>
              <SmokingRooms />
              <Typography fontSize={14} fontWeight="bold">
                {t('filter.amenties.suitabilities.smoking')}:
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={8} xs={6}>
            <Typography variant="subtitle2">
              {
                smoking.find((option) => option.value === property?.amenties?.suitabilities.smoking)
                  ?.label
              }
            </Typography>
          </Grid>
          <Grid item md={4} xs={6}>
            <Stack direction="row" spacing={2}>
              <Accessible />
              <Typography fontSize={14} fontWeight="bold">
                {t('filter.amenties.suitabilities.restrictedMobility')}:
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={8} xs={6}>
            <Typography variant="subtitle2">
              {
                restrictedMobility.find(
                  (option) => option.value === property?.amenties?.suitabilities.restrictedMobility
                )?.label
              }
            </Typography>
          </Grid>
          <Grid item md={4} xs={6}>
            <Stack direction="row" spacing={2}>
              <ChildCare />
              <Typography fontSize={14} fontWeight="bold">
                {t('filter.amenties.suitabilities.children')}:
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={8} xs={6}>
            <Typography variant="subtitle2">
              {
                children.find(
                  (option) => option.value === property?.amenties?.suitabilities.children
                )?.label
              }
            </Typography>
          </Grid>
          <Grid item md={4} xs={6}>
            <Stack direction="row" spacing={2}>
              <Male />
              <Typography fontSize={14} fontWeight="bold">
                {t('filter.amenties.suitabilities.maleGroups')}:
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={8} xs={6}>
            <Typography variant="subtitle2">
              {
                maleGroups.find(
                  (option) => option.value === property?.amenties?.suitabilities.maleGroups
                )?.label
              }
            </Typography>
          </Grid>
          <Grid item md={4} xs={6}>
            <Stack direction="row" spacing={2}>
              <CarRental />
              <Typography fontSize={14} fontWeight="bold">
                {t('filter.amenties.carRental')}:
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={8} xs={6}>
            <Typography variant="subtitle2">
              {carRental.find((option) => option.value === property?.amenties?.carRental)?.label}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <TableContainer>
          <Table sx={{ minWidth: 650, m: 0, p: 0 }} aria-label="simple table">
            <TableBody>
              {property?.amenties &&
                Object.entries(property?.amenties)?.map(
                  ([key1, value], id) =>
                    typeof value === 'object' &&
                    key1 !== 'suitabilities' && (
                      <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                          <Typography color="primary">{t(`filter.amenties.${key1}`)}</Typography>
                        </TableCell>
                        {value &&
                          typeof value === 'object' &&
                          Object.entries(value)
                            .filter(([key2, value]) => value === true)
                            .map(([key2], id) => (
                              <TableCell key={id} align="right">
                                {t(`filter.amenties.${key1}.${key2}`)}
                              </TableCell>
                            ))}
                      </TableRow>
                    )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default GoodToKnow;
