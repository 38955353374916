import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText, ListSubheader } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { APIProvider, Map, Marker, useMarkerRef } from '@vis.gl/react-google-maps';

const Location = ({ property }) => {
  const { t } = useTranslation();
  const [latLng, setLatLng] = useState(null);
  const [markerRef] = useMarkerRef();

  useEffect(() => {
    if (property?.address?.location?.coordinates) {
      setLatLng({
        lat: parseFloat(property?.address?.location?.coordinates[1]),
        lng: parseFloat(property?.address?.location?.coordinates[0])
      });
    }
  }, [property]);

  return (
    <Box id="location" gap={3} mb={2}>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 600,
          textAlign: 'start',
          fontSize: '16px',
          mb: 2
        }}
        variant="subtitle1">
        {t('propertyDetail.location.title')}
      </Typography>
      <Typography>{property?.description?.neighborhood?.overview}</Typography>
      <Box position="relative" sx={{ width: '100%', height: '300px', border: '24px' }}>
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
          <Map center={latLng} defaultZoom={8} gestureHandling="greedy" disableDefaultUI={true} />
          {latLng && <Marker ref={markerRef} position={latLng} />}
        </APIProvider>
      </Box>
      <Box m={2}>
        <Typography>{property?.description?.neighborhood?.gettingAround}</Typography>
        <Typography variant="subtitle1" fontWeight="bold">
          {t('propertyDetail.location.nearby')}:
        </Typography>
        <Grid container>
          <Grid item xs={6} md={3}>
            <List
              dense={true}
              subheader={
                <ListSubheader>{t('propertyDetail.location.transportation')}</ListSubheader>
              }>
              {property?.transportation?.length > 0 ? (
                property?.transportation?.map((transportation, index) => (
                  <ListItem key={index} sx={{ display: 'list-item' }}>
                    <ListItemText primary={transportation} />
                  </ListItem>
                ))
              ) : (
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText primary="-" />
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={6} md={3}>
            <List
              dense={true}
              subheader={
                <ListSubheader>{t('propertyDetail.location.entertainment')}</ListSubheader>
              }>
              {property?.entertainment?.length > 0 ? (
                property?.entertainment?.map((entertainment, index) => (
                  <ListItem key={index} sx={{ display: 'list-item' }}>
                    <ListItemText primary={entertainment} />
                  </ListItem>
                ))
              ) : (
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText primary="-" />
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={6} md={3}>
            <List
              dense={true}
              subheader={<ListSubheader>{t('propertyDetail.location.foodDrink')}</ListSubheader>}>
              {property?.foodDrink?.length > 0 ? (
                property?.foodDrink?.map((foodDrink, index) => (
                  <ListItem key={index} sx={{ display: 'list-item' }}>
                    <ListItemText primary={foodDrink} />
                  </ListItem>
                ))
              ) : (
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText primary="-" />
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={6} md={3}>
            <List
              dense={true}
              subheader={<ListSubheader>{t('propertyDetail.location.places')}</ListSubheader>}>
              {property?.places?.length > 0 ? (
                property?.places?.map((place, index) => (
                  <ListItem key={index} sx={{ display: 'list-item' }}>
                    <ListItemText primary={place} />
                  </ListItem>
                ))
              ) : (
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText primary="-" />
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Location;
