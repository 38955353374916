const createComponents = ({ palette }) => {
  return {
    MuiButton: {
      styleOverrides: {
        contained: {
          width: '100%'
        },
        textPrimary: {
          padding: 0,
          minWidth: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: palette.inputPlaceholder.main // focused oldugunda cikan placeholder rengini degis
          },
          margin: '0'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.inputBG.main, // input arkaplan rengini degis
          borderRadius: 5
        },
        underline: {
          '&:before': {
            borderBottom: 'none' // underlineı sil
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none' // hover olunca underlineı sil
          },
          '&:after': {
            borderBottom: 'none' // focus olunca underlineı sil
          }
        },
        input: {
          '&::placeholder': {
            color: palette.inputPlaceholder.main // placeholder rengini degis
          }
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: palette.ocean.main
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '10px'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '0 0 0 16px'
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          flexWrap: 'wrap'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          flexDirection: 'row'
        }
      }
    }
  };
};

export default createComponents;
