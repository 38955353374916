import React from 'react';
import { Box, Pagination as PaginationComponent } from '@mui/material';

const Pagination = ({ items, currentPage, setCurrentPage }) => {
  const itemsPerPage = 10;

  const totalItems = items?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        mt: 2
      }}>
      <PaginationComponent
        color="primary"
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
      />
    </Box>
  );
};

export default Pagination;
