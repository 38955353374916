import React from 'react';
import { Box, Chip, Grid, Stack } from '@mui/material';
import PropertyCard from '../../Components/PropertyCard/PropertyCard';
import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header/Header';
import {
  AccountBalance,
  BeachAccess,
  FreeBreakfast,
  Houseboat,
  Landscape,
  Pool,
  Sailing,
  Waves
} from '@mui/icons-material';
import { KEYWORD } from '../../Constants/Property';

const Properties = ({ properties, selected, setSelected }) => {
  const { t } = useTranslation();
  const chips = [
    { key: KEYWORD.SEA, label: t('createProperty.filter.sea'), icon: <Waves /> },
    { key: KEYWORD.BEACH, label: t('createProperty.filter.beach'), icon: <BeachAccess /> },
    { key: KEYWORD.POOL, label: t('createProperty.filter.pool'), icon: <Pool /> },
    { key: KEYWORD.VIEW, label: t('createProperty.filter.view'), icon: <Landscape /> },
    { key: KEYWORD.LAKE, label: t('createProperty.filter.lake'), icon: <Houseboat /> },
    { key: KEYWORD.ISLAND, label: t('createProperty.filter.island'), icon: <Sailing /> },
    {
      key: KEYWORD.BREAKFAST,
      label: t('createProperty.filter.breakfast'),
      icon: <FreeBreakfast />
    },
    { key: KEYWORD.HISTORIC, label: t('createProperty.filter.historic'), icon: <AccountBalance /> }
  ];

  const handleClick = (key) => {
    setSelected(key);
  };

  return (
    <Box>
      <Header>{t('homepage.popularProperties.title')}</Header>
      <Stack
        sx={{
          overflowX: 'auto',
          m: 2
        }}
        justifyContent="space-between"
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}>
        {chips.map((data) => (
          <Chip
            key={data.key}
            icon={data.icon}
            label={data.label}
            onClick={() => handleClick(data.key)}
            color={data.key === selected ? 'primary' : 'default'}
            sx={{
              py: 2.3,
              px: 1
            }}
          />
        ))}
      </Stack>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
        {properties?.length > 0 &&
          properties?.map((property, index) => (
            <Grid item xs={4} sm={4} md={4} key={index}>
              <PropertyCard property={property} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Properties;
