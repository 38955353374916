import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { AccessTimeFilled } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const PropertyDetailSection = ({ property }) => {
  const { t } = useTranslation();

  return (
    <Box id="property-detail" gap={3} mb={2}>
      <Typography
        sx={{
          color: 'header.main',
          fontWeight: 600,
          textAlign: 'start',
          fontSize: '16px',
          mb: 2
        }}
        variant="subtitle1">
        {t('propertyDetail.detail.title')}
      </Typography>
      <Box>
        <Typography sx={{ fontSize: 15, mb: 2 }}>{property?.description?.description}</Typography>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          color="header.main">
          <AccessTimeFilled fontSize="large" />
          <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
            {t('propertyDetail.detail.checkIn')}{' '}
            {dayjs(property?.amenties?.checkInTime).format('HH:mm')}
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
            {t('propertyDetail.detail.checkOut')}{' '}
            {dayjs(property?.amenties?.checkOutTime).format('HH:mm')}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default PropertyDetailSection;
