import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Tooltip,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  AccountCircle,
  CalendarMonth,
  ChevronLeft,
  HistoryRounded,
  HomeRounded,
  InboxRounded,
  LogoutRounded,
  PaymentRounded,
  ReviewsRounded,
  SettingsRounded
} from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../Constants/Permissions';
import { useAuth } from '../../Contexts/AuthContext';

import Account from './Account';
import Reservations from './Reservations';
import Properties from './Properties';
import Inbox from './Inbox';
import Reviews from './Reviews';

const Profile = () => {
  const { t } = useTranslation();

  const list = [
    {
      id: 0,
      icon: <AccountCircle />,
      label: t('profile.sidebar.account'),
      body: <Account />
    },
    {
      id: 1,
      icon: <HomeRounded />,
      label: t('profile.sidebar.properties'),
      body: <Properties />,
      isHost: true
    },
    {
      id: 2,
      icon: <CalendarMonth />,
      label: t('profile.sidebar.reservations'),
      body: <Reservations />
    },
    {
      id: 3,
      icon: <InboxRounded />,
      label: t('profile.sidebar.messages'),
      body: <Inbox />
    },
    {
      id: 4,
      icon: <ReviewsRounded />,
      label: t('profile.sidebar.reviews'),
      body: <Reviews />
    },
    {
      id: 5,
      icon: <HistoryRounded />,
      label: t('profile.sidebar.history'),
      //body: <History />,
      isHost: true
    },
    {
      id: 6,
      icon: <PaymentRounded />,
      label: t('profile.sidebar.paymentMethod'),
      //body: <PaymentMethod />,
      isHost: true
    },
    {
      id: 7,
      icon: <SettingsRounded />,
      label: t('profile.sidebar.settings')
      //body: <Settings />
    },
    {
      id: 8,
      icon: <LogoutRounded />,
      label: t('profile.sidebar.logout')
    }
  ];

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [selected, setSelected] = useState(list[0]);

  const { logoutAccount, user } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.profile) {
      setSelected(list[state?.profile]);
    }
  }, [state]);

  const handleMenuItemClick = (event, item) => {
    setSelected(item);
  };

  const handleSidebarToggle = useCallback(() => {
    setSidebarCollapsed(!sidebarCollapsed);
  }, [sidebarCollapsed]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item>
          <Paper
            sx={{
              position: 'sticky',
              top: '1rem',
              zIndex: 10,
              m: 2,
              width: sidebarCollapsed ? '100%' : 'auto',
              borderRadius: '12px'
            }}
            elevation={2}>
            <Box sx={{ p: 1.5, display: 'flex', justifyContent: 'space-around' }}>
              <IconButton
                sx={{
                  '@media screen and (max-width: 600px)': {
                    display: 'none'
                  }
                }}
                color="white"
                onClick={handleSidebarToggle}>
                <ChevronLeft
                  sx={{
                    transition: 'all 0.4s 0.45s cubic-bezier(.47,1.64,.41,.8)',
                    ...(sidebarCollapsed && { transform: 'rotate(180deg)' })
                  }}
                />
              </IconButton>
            </Box>
            <MenuList sx={{ py: '1.5rem' }}>
              {list.map((item, index) => {
                if (
                  (user?.role === ROLES.TRAVELER && !item.isHost) ||
                  user?.role === ROLES.HOST ||
                  user?.role === ROLES.ADMIN
                ) {
                  return (
                    item?.label && (
                      <MenuItem
                        key={index}
                        disableRipple
                        selected={index === list.map((e) => e.label).indexOf(selected.label)}
                        onClick={(event) => {
                          handleMenuItemClick(event, item);
                          if (item.icon.type === LogoutRounded) {
                            logoutAccount(() => {
                              navigate('/');
                            });
                          }
                        }}
                        sx={[
                          { mx: sidebarCollapsed && '.375rem' },
                          {
                            '&.Mui-selected, &.Mui-selected .MuiListItemIcon-root': {
                              backgroundColor: 'primary.dark',
                              color: 'white',
                              borderRadius: '5px'
                            },
                            '&.Mui-selected:hover, &.Mui-selected:hover .MuiListItemIcon-root': {
                              backgroundColor: 'primary.dark',
                              color: 'white'
                            },
                            '&:hover, &:hover .MuiListItemIcon-root': {
                              backgroundColor: 'transparent',
                              color: 'primary.main'
                            },
                            ...(!sidebarCollapsed && {
                              '&.MuiMenuItem-root div, &.MuiMenuItem-root': {
                                width: 'auto',
                                minWidth: 'auto !important',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '34px'
                              }
                            })
                          }
                        ]}>
                        {!sidebarCollapsed ? (
                          <Tooltip
                            title={`${item?.label} ${
                              item.count >= 0 ? '(' + item?.count + ')' : ''
                            }`}
                            placement="right">
                            <ListItemIcon sx={{ minWidth: 'auto' }}>{item.icon} </ListItemIcon>
                          </Tooltip>
                        ) : (
                          <>
                            <ListItemIcon sx={{ minWidth: 'auto' }}>{item.icon} </ListItemIcon>
                            <ListItemText
                              primary={`${item?.label} ${
                                item.count >= 0 ? '(' + item?.count + ')' : ''
                              }`}
                            />
                          </>
                        )}
                      </MenuItem>
                    )
                  );
                }
                return null;
              })}
            </MenuList>
          </Paper>
        </Grid>
        {selected.body && (
          <Grid item xs>
            <Stack spacing={4}>{selected.body}</Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Profile;
