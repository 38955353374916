import { createTheme as createMuiTheme } from '@mui/material';
import createPalette from './Palette';
import createComponents from './Components';

const createTheme = () => {
  const palette = createPalette();
  const components = createComponents({ palette });

  return createMuiTheme({
    palette,
    components,
    typography: {
      fontFamily: `"Geneva"`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500
    }
  });
};

export default createTheme;
