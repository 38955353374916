import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import { Send } from '@mui/icons-material';
import Input from '../Input/Input';
import Message from './Message';
import Avatar from '../Avatar/Avatar';

const ChatContainer = ({ messageInfo }) => {
  const [text, setText] = useState('');
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const clickHandler = () => {
    if (text.trim() === '') return;
    // mesaj gonder

    setText('');
  };

  const pressHandler = (event) => {
    if (event.key === 'Enter') {
      return clickHandler();
    }
  };

  useEffect(() => {
    const container = messagesContainerRef.current;
    const endRef = messagesEndRef.current;

    if (container && endRef) {
      container.scrollTop = endRef.offsetTop;
    }
  }, [messageInfo?.messages]);

  return (
    <Paper
      elevation={1}
      sx={{
        position: 'relative',
        height: '500px',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Stack
        alignItems="center"
        sx={{
          pt: 2
        }}>
        <Avatar>{messageInfo?.host}</Avatar>
        <Typography
          sx={{ display: 'inline', color: 'primary.main', fontWeight: 600 }}
          component="span"
          variant="body1">
          {messageInfo.property}
        </Typography>
        <Typography sx={{ display: 'inline', fontWeight: 400 }} component="span" variant="body2">
          {messageInfo.host}
        </Typography>
      </Stack>
      <Stack sx={{ flex: 1, px: 2, py: 1, overflowY: 'auto' }} ref={messagesContainerRef}>
        {messageInfo?.messages?.map((message, index) => (
          <Message key={index} message={message} />
        ))}
        <div ref={messagesEndRef} />
      </Stack>
      <Stack
        sx={{
          pb: 2,
          px: 2,
          background: 'white',
          zIndex: 10
        }}
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center">
        <Input
          hiddenLabel
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={pressHandler}
        />
        <IconButton aria-label="delete" size="small" onClick={clickHandler}>
          <Send color="primary" />
        </IconButton>
      </Stack>
    </Paper>
  );
};

export default ChatContainer;
