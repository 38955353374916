import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { categories, bedTypes } from '../../Constants/SelectOptions';
import Input from '../../Components/Input/Input';
import Select from '../../Components/Select/Select';
import { center, KEYWORD } from '../../Constants/Property';
import { APIProvider, Map, Marker, useMarkerRef } from '@vis.gl/react-google-maps';
import DecimalInput from '../../Components/DecimalInput/DecimalInput';

const Basics = ({ formik, latLng, setLatLng }) => {
  const { t } = useTranslation();

  const [markerRef] = useMarkerRef();

  return (
    <Box gap={3} mb={2}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 1, md: 12 }}
        alignItems="center">
        <Grid item xs={12}>
          <Typography
            sx={{
              color: 'header.main',
              fontWeight: 'bold',
              fontSize: '32px'
            }}
            gutterBottom>
            {t('stepper.step.basics')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Input
            label={t('createProperty.input.title')}
            onChange={formik.handleChange('title')}
            value={formik.values.title}
            onBlur={formik.handleBlur('title')}
            error={formik.touched.title && Boolean(formik.errors.title)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label={t('createProperty.input.category')}
            value={formik.values.category}
            onChange={formik.handleChange('category')}
            error={formik.touched.category && Boolean(formik.errors.category)}
            options={categories}
            onBlur={formik.handleBlur('category')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl required>
            <FormLabel id="demo-radio-buttons-group-label">
              {t('createProperty.input.keyword')}
            </FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={formik.values.keyword}
              onChange={formik.handleChange('keyword')}>
              <FormControlLabel
                value={KEYWORD.SEA}
                control={<Radio />}
                label={t('createProperty.filter.sea')}
              />
              <FormControlLabel
                value={KEYWORD.BEACH}
                control={<Radio />}
                label={t('createProperty.filter.beach')}
              />
              <FormControlLabel
                value={KEYWORD.POOL}
                control={<Radio />}
                label={t('createProperty.filter.pool')}
              />
              <FormControlLabel
                value={KEYWORD.VIEW}
                control={<Radio />}
                label={t('createProperty.filter.view')}
              />
              <FormControlLabel
                value={KEYWORD.LAKE}
                control={<Radio />}
                label={t('createProperty.filter.lake')}
              />
              <FormControlLabel
                value={KEYWORD.ISLAND}
                control={<Radio />}
                label={t('createProperty.filter.island')}
              />
              <FormControlLabel
                value={KEYWORD.BREAKFAST}
                control={<Radio />}
                label={t('createProperty.filter.breakfast')}
              />
              <FormControlLabel
                value={KEYWORD.HISTORIC}
                control={<Radio />}
                label={t('createProperty.filter.historic')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: 'header.main',
              fontWeight: 600,
              textAlign: 'start',
              fontSize: '16px'
            }}
            variant="subtitle1">
            {t('createProperty.adress.title')}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'inputPlaceholder.main',
              fontSize: '14px'
            }}>
            {t('createProperty.adress.description')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box position="relative" sx={{ width: '100%', height: '450px', border: '24px' }}>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
              <Map
                defaultCenter={center}
                defaultZoom={6}
                gestureHandling="greedy"
                disableDefaultUI={true}
                onClick={(e) => {
                  setLatLng(e.detail.latLng);
                  formik.setFieldValue('address.location', {
                    coordinates: [e.detail.latLng.lng, e.detail.latLng.lat]
                  });
                }}>
                {latLng && <Marker ref={markerRef} position={latLng} />}
              </Map>
            </APIProvider>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="column" justifyContent="space-between" alignItems="center" spacing={2}>
            <Input
              label={t('createProperty.input.street')}
              onChange={formik.handleChange('address.street')}
              value={formik.values.address.street}
              onBlur={formik.handleBlur('address.street')}
              error={formik.touched.address?.street && Boolean(formik.errors.address?.street)}
              required
            />
            <DecimalInput
              label={t('createProperty.input.houseNumber')}
              onChange={formik.handleChange('address.houseNumber')}
              value={formik.values.address.houseNumber}
              onBlur={formik.handleBlur('address.houseNumber')}
              error={
                formik.touched.address?.houseNumber && Boolean(formik.errors.address?.houseNumber)
              }
              required
            />
            <DecimalInput
              label={t('createProperty.input.postalCode')}
              onChange={formik.handleChange('address.postalCode')}
              value={formik.values.address.postalCode}
              onBlur={formik.handleBlur('address.postalCode')}
              error={
                formik.touched.address?.postalCode && Boolean(formik.errors.address?.postalCode)
              }
              inputProps={{ maxLength: 5 }}
              required
            />
            <Input
              label={t('createProperty.input.district')}
              onChange={formik.handleChange('address.district')}
              value={formik.values.address.district}
              onBlur={formik.handleBlur('address.district')}
              error={formik.touched.address?.district && Boolean(formik.errors.address?.district)}
              required
            />
            <Input
              label={t('createProperty.input.city')}
              onChange={formik.handleChange('address.city')}
              value={formik.values.address.city}
              onBlur={formik.handleBlur('address.city')}
              error={formik.touched.address?.city && Boolean(formik.errors.address?.city)}
              required
            />
            <Input
              label={t('createProperty.input.country')}
              onChange={formik.handleChange('address.country')}
              value={formik.values.address.country}
              onBlur={formik.handleBlur('address.country')}
              error={formik.touched.address?.country && Boolean(formik.errors.address?.country)}
              required
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: 'header.main',
              fontWeight: 600,
              textAlign: 'start',
              fontSize: '16px'
            }}
            variant="subtitle1">
            {t('createProperty.basicFeatures.title')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <DecimalInput
            label={t('createProperty.input.maxTraveler')}
            value={formik.values.basicFeatures.maxTraveler}
            onChange={formik.handleChange('basicFeatures.maxTraveler')}
            error={
              formik.touched.basicFeatures?.maxTraveler &&
              Boolean(formik.errors.basicFeatures?.maxTraveler)
            }
            onBlur={formik.handleBlur('basicFeatures.maxTraveler')}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DecimalInput
            label={t('createProperty.input.bedroom')}
            value={formik.values.basicFeatures.bedroom}
            onChange={formik.handleChange('basicFeatures.bedroom')}
            error={
              formik.touched.basicFeatures?.bedroom && Boolean(formik.errors.basicFeatures?.bedroom)
            }
            onBlur={formik.handleBlur('basicFeatures.bedroom')}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label={t('createProperty.input.bed')}
            value={formik.values.basicFeatures.bed}
            onChange={formik.handleChange('basicFeatures.bed')}
            error={formik.touched.basicFeatures?.bed && Boolean(formik.errors.basicFeatures?.bed)}
            options={bedTypes}
            onBlur={formik.handleBlur('basicFeatures.bed')}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DecimalInput
            label={t('createProperty.input.bathroom')}
            value={formik.values.basicFeatures.bathroom}
            onChange={formik.handleChange('basicFeatures.bathroom')}
            error={
              formik.touched.basicFeatures?.bathroom &&
              Boolean(formik.errors.basicFeatures?.bathroom)
            }
            onBlur={formik.handleBlur('basicFeatures.bathroom')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label={t('createProperty.input.isBathShared')}
            control={
              <Checkbox
                checked={formik.values.basicFeatures.isBathShared}
                onChange={formik.handleChange('basicFeatures.isBathShared')}
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Basics;
