import React from 'react';
import { Box, Grid, Container, Typography, Stack, Divider } from '@mui/material';
import { AccessTimeFilled } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import HeaderWithLocation from '../../Components/HeaderWithLocation/HeaderWithLocation';
import Rating from '../../Components/Rating/Rating';
import TertiaryButton from '../../Components/Button/TertiaryButton';

const ReservationDetail = () => {
  const { t } = useTranslation();

  const property = {
    title: 'Siesta Garden',
    imageUrl:
      'https://images.unsplash.com/photo-1477314099024-9f209cdc507a?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    location: 'Bodrum Yalıkavak',
    price: '50$',
    host: 'Beyza Erkan',
    checkInDate: '24.07.2023',
    checkOutDate: '30.07.2023',
    rate: 2,
    checkIn: '14.00',
    checkOut: '12.00',
    locationDescription:
      'iciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis'
  };

  return (
    <Stack gap={3} mb={3} direction="row">
      <Container sx={{ minWidth: 'auto', mt: 4 }}>
        <Grid container spacing={{ xs: 3, md: 5 }}>
          <Grid item xs={12} md={6}>
            {/* daha sonra eklenecek */}
            {/* {property.photos.length === 0 ? (
              <img
                src={categories(t)?.find((category) => category.name === property?.category)?.image}
                alt={property?.title}
                style={{
                  objectFit: 'cover',
                  borderRadius: '5px',
                  float: 'left',
                  width: '100%',
                  height: '350px',
                  cursor: 'pointer'
                }}
              />
            ) : (
              <img
                src={property?.photos[0]?.src}
                alt={property?.title}
                style={{
                  objectFit: 'cover',
                  borderRadius: '5px',
                  float: 'left',
                  width: '100%',
                  height: '350px',
                  cursor: 'pointer'
                }}
              />
            )}
 */}
            <img
              src={property.imageUrl}
              alt={property.title}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                px: 3
              }}>
              <HeaderWithLocation
                location={property.location}
                header={property.title}
                host={t('reservationDetail.host', { host: property.host })}
                headerStyle={{
                  fontSize: 32
                }}
              />
              <Rating rateNumber={property.rate} size="small" readOnly />
              <Typography variant="h6" fontWeight={600} color="#787878" mt={1}>
                {property.checkInDate} - {property.checkOutDate}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600
                }}>
                7 {t('reservationDetail.days')} / 1 {t('reservationDetail.guest')}
              </Typography>
            </Box>
            <Box
              sx={{
                my: 2,
                px: 3,
                fontWeight: 600,
                fontSize: '14px',
                color: '#555555'
              }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography>{property.price} x 7 nights</Typography>
                <Typography>$406</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography>Service fee</Typography>
                <Typography>$20</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography>Cleaning fee</Typography>
                <Typography>$15</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography>Security fee</Typography>
                <Typography>$288</Typography>
              </Stack>
            </Box>
            <Divider />
            <Box
              sx={{
                mt: 2,
                mx: 4,
                fontWeight: 600,
                fontSize: '14px',
                color: '#555555'
              }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography>{t('reservationDetail.total')}</Typography>
                <Typography>$15</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography fontWeight={800}>{t('reservationDetail.amountPaying')}</Typography>
                <Typography fontWeight={800}>$288</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                mt={2}>
                <TertiaryButton>{t('reservationDetail.button.messages')}</TertiaryButton>
                <TertiaryButton color="inputPlaceholder">
                  {t('reservationDetail.button.cancel')}
                </TertiaryButton>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={2} color="header.main">
              <AccessTimeFilled fontSize="large" />
              <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
                {t('reservationDetail.detail.checkIn')} {property.checkIn}
              </Typography>
              <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
                {t('reservationDetail.detail.checkOut')} {property.checkOut}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box>
              <iframe
                width="100%"
                height="400"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                <a href="https://www.maps.ie/population/">Find Population on Map</a>
              </iframe>
            </Box>
            <Typography>{property.locationDescription}</Typography>
          </Grid>
          <Grid item xs={0} md={4} />
        </Grid>
      </Container>
    </Stack>
  );
};

export default ReservationDetail;
