import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Container,
  Typography,
  Box
} from '@mui/material';
import Avatar from '../Avatar/Avatar';
import SecondaryButton from '../Button/SecondaryButton';
import { Menu as MenuIcon } from '@mui/icons-material';
import Login from '../Login/Login';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useAuth } from '../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import Signup from '../Signup/Signup';

const settings = ['profile', 'logout'];

const Navbar = () => {
  const { loggedIn, logoutAccount, user, isLoginOpen, isModalOpen, setIsModalOpen } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar color="ice" position="static" elevation={1}>
        <Container
          sx={{
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}>
              HOLIDAY PLATFORM
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none', justifyContent: 'center', alignItems: 'center' }
              }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit">
                <MenuIcon />
              </IconButton>
              <LanguageSwitcher />
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}>
              HOLIDAY PLATFORM
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                m: 2,
                display: { xs: 'none', md: 'flex', justifyContent: 'flex-end' }
              }}>
              <LanguageSwitcher />
            </Box>
            {loggedIn === true ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar>{user?.firstName.concat(' ', user?.lastName)}</Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}>
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={async () => {
                        if (setting === 'logout') {
                          logoutAccount(() => {
                            navigate('/');
                          });
                        }
                        if (setting === 'profile') {
                          navigate('/profile');
                        }
                        handleCloseUserMenu();
                      }}>
                      <Typography textAlign="center">{t(`navbar.${setting}`)}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                <SecondaryButton onClick={() => setIsModalOpen(true)}>
                  {t('signup.button.title')}
                </SecondaryButton>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {isModalOpen && (isLoginOpen ? <Login /> : <Signup />)}
    </>
  );
};

export default Navbar;
