import { Trans } from 'react-i18next';
import {
  BED_TYPES,
  CAR_RENTAL,
  CATEGORY,
  CURRENCY,
  MALE_GROUPS,
  PETS,
  SMOKING,
  SUITABILITY
} from './Property';

const bedTypes = [
  { value: BED_TYPES.No, label: <Trans i18nKey="bedTypes.no" /> },
  { value: BED_TYPES.Single, label: <Trans i18nKey="bedTypes.single" /> },
  { value: BED_TYPES.Double, label: <Trans i18nKey="bedTypes.double" /> }
];

const categories = [
  { value: CATEGORY.APART, label: <Trans i18nKey="property.category.apart" /> },
  { value: CATEGORY.FLAT, label: <Trans i18nKey="property.category.flat" /> },
  { value: CATEGORY.HOTEL, label: <Trans i18nKey="property.category.hotel" /> },
  { value: CATEGORY.VILLA, label: <Trans i18nKey="property.category.villa" /> }
];

const petsAllowed = [
  { value: PETS.PETS_ALLOWED, label: <Trans i18nKey="pets.allowed" /> },
  { value: PETS.NO_PETS_ALLOWED, label: <Trans i18nKey="pets.notAllowed" /> }
];

const smoking = [
  { value: SMOKING.SMOKING, label: <Trans i18nKey="smoking.yes" /> },
  { value: SMOKING.NO_SMOKING, label: <Trans i18nKey="smoking.no" /> }
];

const restrictedMobility = [
  { value: SUITABILITY.SUITABLE, label: <Trans i18nKey="restrictedMobility.suitable" /> },
  { value: SUITABILITY.NOT_SUITABLE, label: <Trans i18nKey="restrictedMobility.notSuitable" /> }
];

const children = [
  { value: SUITABILITY.SUITABLE, label: <Trans i18nKey="children.suitable" /> },
  { value: SUITABILITY.NOT_SUITABLE, label: <Trans i18nKey="children.notSuitable" /> }
];

const maleGroups = [
  { value: MALE_GROUPS.ACCEPTED, label: <Trans i18nKey="maleGroups.accepted" /> },
  { value: MALE_GROUPS.NOT_ACCEPTED, label: <Trans i18nKey="maleGroups.notAccepted" /> }
];

const carRental = [
  { value: CAR_RENTAL.NOT_NEEDED, label: <Trans i18nKey="carRental.notNeeded" /> },
  { value: CAR_RENTAL.ESSENTIAL, label: <Trans i18nKey="carRental.essential" /> },
  { value: CAR_RENTAL.OPTIONAL, label: <Trans i18nKey="carRental.optional" /> }
];

const currencies = [
  { label: `(€) ${CURRENCY.EUR}`, value: CURRENCY.EUR, symbol: '€' },
  { label: `(₺) ${CURRENCY.TRY}`, value: CURRENCY.TRY, symbol: '₺' },
  { label: `($) ${CURRENCY.USD}`, value: CURRENCY.USD, symbol: '$' },
  { label: `(£) ${CURRENCY.GBP}`, value: CURRENCY.GBP, symbol: '£' }
];

export {
  bedTypes,
  categories,
  petsAllowed,
  smoking,
  restrictedMobility,
  children,
  maleGroups,
  carRental,
  currencies
};
